/* eslint-disable func-names */
import React, { memo, useReducer, useContext, useEffect } from 'react';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import './css/FabComponent.css';
import { NavLink, useHistory } from 'react-router-dom';
import { getOptimXBackgroundStyle } from '../../../../catalog/Website';
import { context } from '../../../../globalState/GlobalState';
import { isValidString } from '../../../../catalog/Validity';
import { checkProductsValidity, checkTicketsValidity } from '../../../../catalog/Store';

const initialState = {
    isStore: true,
    isTickets: true,
    isBottom: false,
};

function reducer(state, action) {
    switch (action.type) {
        case 'isStore':
            return { ...state, isStore: true };
        case 'isNotStore':
            return { ...state, isStore: false };
        case 'isTickets':
            return { ...state, isTickets: true };
        case 'isNotTickets':
            return { ...state, isTickets: false };
        case 'isBottom':
            return { ...state, isBottom: true };
        case 'isNotBottom':
            return { ...state, isBottom: false };
        default:
            return initialState;
    }
}

const FabComponent = () => {
    const [state, setState] = useReducer(reducer, initialState);
    const history = useHistory();
    const { globalState } = useContext(context);

    useEffect(() => {
        if (history.location.pathname.includes('/store')) {
            setState({ type: 'isStore' });
        } else {
            setState({ type: 'isNotStore' });
        }
        if (history.location.pathname.includes('/tickets') && !history.location.pathname.includes('/games')) {
            setState({ type: 'isTickets' });
        } else {
            setState({ type: 'isNotTickets' });
        }
        return history.listen(() => {
            if (history.location.pathname.includes('/store')) {
                setState({ type: 'isStore' });
            } else {
                setState({ type: 'isNotStore' });
            }
            if (history.location.pathname.includes('/tickets') && !history.location.pathname.includes('/games')) {
                setState({ type: 'isTickets' });
            } else {
                setState({ type: 'isNotTickets' });
            }
        });
    }, [history]);

    window.onscroll = function () {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 500) {
            setState({ type: 'isBottom' });
        } else {
            setState({ type: 'isNotBottom' });
        }
    };

    return (
        <>
            {checkProductsValidity(globalState) ? (
                <NavLink to="/store" className={`fab-component store box-shadow-black p-t-20px p-b-20px p-l-20px p-r-40px ${!isValidString(globalState.stripeAccountId) || state.isStore || state.isBottom ? 'hide' : ''}`} style={getOptimXBackgroundStyle(globalState)}>
                    <LocalMallIcon style={{ marginRight: '20px' }} />
                    Store
                </NavLink>
            ) : null}
            {checkTicketsValidity(globalState) ? (
                <NavLink to="/tickets" className={`fab-component ticket box-shadow-black p-t-20px p-b-20px p-l-20px p-r-40px ${!isValidString(globalState.stripeAccountId) || state.isTickets || state.isBottom ? 'hide' : ''}`} style={getOptimXBackgroundStyle(globalState)}>
                    <ConfirmationNumberIcon style={{ marginRight: '20px' }} />
                    Tickets
                </NavLink>
            ) : null}
        </>
    );
};

export default memo(FabComponent);
