import React, { memo, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { NavLink } from 'react-router-dom';
import { ArrowDropDown } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { isValidObject, isValidString } from '../../../../catalog/Validity';
import NavItemsDesktopWithDropDown from './NavItemsDesktopWithDropDown';
import { context } from '../../../../globalState/GlobalState';
import { getNavLinkTargetValue } from '../../../../catalog/Website';
import NavItemCart from './NavItemCart';
import { optimXStoreWebsite } from '../../../../catalog/TeamInfo';

const NavItemsDesktop = (props) => {
    const { navItems } = props;
    const { globalState } = useContext(context);

    const styles = StyleSheet.create({
        navitem: {
            color: isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor} !important` : '#ffffff !important',
        },
    });

    const state = navItems.map((item) => {
        if (isValidObject(item)) {
            return useState(false);
        }
        return null;
    });
    const anchorRefs = navItems.map((item) => {
        if (isValidObject(item)) {
            return useRef(null);
        }
        return null;
    });

    const getNavItem = (item, index) => {
        if (item.value === '/options') {
            return (
                <Button
                    className={`nav-item ${css(styles.navitem)}`}
                    onMouseOver={() => { state[index][1](() => { return true; }); }}
                    onMouseLeave={() => { state[index][1](() => { return false; }); }}
                    type="button"
                    ref={anchorRefs[index]}
                >
                    {item.title}
                    <ArrowDropDown style={{ fontSize: '20px' }} />
                    <NavItemsDesktopWithDropDown options={item.options} menuOpen={state[index][0]} setMenuOpen={state[index][1]} anchorRef={anchorRefs[index]} />
                </Button>
            );
        } if (item.value === '/custom') {
            return (
                <a className={`nav-item m-t-0 m-b-0 ${css(styles.navitem)}`} href={item.link} target={getNavLinkTargetValue(item.openNewTab, false)} rel="noreferrer noopener">
                    {item.title}
                </a>
            );
        } if (item.value === '/custompage' || item.value === '/custompages') {
            return (
                <NavLink className={`nav-item ${css(styles.navitem)}`} to={`/pages/${item.link}`} target={getNavLinkTargetValue(item.openNewTab, true)}>
                    <p className="m-t-0 m-b-0">
                        {item.title}
                    </p>
                </NavLink>
            );
        } if (item.value === '/forms') {
            return (
                <NavLink className={`nav-item ${css(styles.navitem)}`} to={`/forms/${item.link}`} target={getNavLinkTargetValue(item.openNewTab, true)}>
                    <p className="m-t-0 m-b-0">
                        {item.title}
                    </p>
                </NavLink>
            );
        }
        return (
            <NavLink className={`nav-item ${css(styles.navitem)}`} to={item.link} target={getNavLinkTargetValue(item.openNewTab, true)}>
                <p className="m-t-0 m-b-0">
                    {item.title}
                </p>
            </NavLink>
        );
    };

    return (
        <div className="nav-items-normal">
            {!optimXStoreWebsite ? (
                <>
                    {navItems.map((item, index) => {
                        return (
                            <React.Fragment key={item.title}>
                                {getNavItem(item, index)}
                            </React.Fragment>
                        );
                    })}
                </>
            ) : null}
            {isValidString(globalState.stripeAccountId) ? <NavItemCart desktop /> : null}
        </div>
    );
};

NavItemsDesktop.propTypes = {
    navItems: PropTypes.array.isRequired,
};

export default (memo(NavItemsDesktop));
