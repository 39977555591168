/* eslint-disable no-console */
import { sendErrorAnalytics } from '../../catalog/Analytics';
import { ERROR_FETCHING_ACCOUNT_SUBSCRIPTION_STATUS, ERROR_FETCHING_TEAM_SUBSCRIPTION_INFO } from '../../catalog/NotificationsComments';
import { getPriceId } from '../../catalog/Subscriptions';
import { optimXTesting } from '../../catalog/TeamInfo';
import { fetchSubscriptionInfoFirestoreAPI, fetchSubscriptionStatusFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsSubscription';

export const fetchSubscriptionInfoAPI = async (teamId: string, setState: Function, snackbar: Function) => {
    try {
        const teamSubscriptionInfoDoc = await fetchSubscriptionInfoFirestoreAPI(teamId);
        const teamSubscriptionInfo = teamSubscriptionInfoDoc.data();
        setState({ type: 'setSubscriptionInfo', info: teamSubscriptionInfo });
    } catch (error) {
        setState({ type: 'setError' });
        sendErrorAnalytics(ERROR_FETCHING_TEAM_SUBSCRIPTION_INFO);
        if (optimXTesting) {
            snackbar(ERROR_FETCHING_TEAM_SUBSCRIPTION_INFO, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchSubscriptionStatusAPI = async (teamId: string, setState: Function, snackbar: Function) => {
    const subscriptions: any = [];
    try {
        const docs = await fetchSubscriptionStatusFirestoreAPI(teamId);
        docs.forEach((doc) => {
            if (doc.exists) {
                subscriptions.push(doc.data());
            }
        });
        let websiteSubscriptionActive = false;
        let websiteSubscription = {};
        let domainSubscriptionActive = false;
        let domainSubscription = {};
        let emailSubscriptionActive = false;
        let emailSubscription = {};
        subscriptions.forEach((subscription: any) => {
            const prices = subscription.prices.map((price: any) => {
                return price.id;
            });
            if ((subscription.status === 'active' || subscription.status === 'trialing') && (prices.includes(getPriceId(true, false, false, true)) || prices.includes(getPriceId(true, false, false, false)))) {
                websiteSubscriptionActive = true;
                websiteSubscription = subscription;
            }
            if ((subscription.status === 'active' || subscription.status === 'trialing') && (prices.includes(getPriceId(false, true, false, true)) || prices.includes(getPriceId(false, true, false, false)))) {
                domainSubscriptionActive = true;
                domainSubscription = subscription;
            }
            if ((subscription.status === 'active' || subscription.status === 'trialing') && (prices.includes(getPriceId(false, false, true, true)) || prices.includes(getPriceId(false, false, true, false)))) {
                emailSubscriptionActive = true;
                emailSubscription = subscription;
            }
        });
        setState({ type: 'setSubscriptionActive',
            websiteSubscriptionActive,
            websiteSubscription,
            domainSubscriptionActive,
            domainSubscription,
            emailSubscriptionActive,
            emailSubscription,
        });
    } catch (error) {
        setState({ type: 'setError' });
        sendErrorAnalytics(ERROR_FETCHING_ACCOUNT_SUBSCRIPTION_STATUS);
        if (optimXTesting) {
            snackbar(ERROR_FETCHING_ACCOUNT_SUBSCRIPTION_STATUS, { variant: 'error' });
            console.log(error);
        }
    }
};
