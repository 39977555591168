/* eslint-disable prefer-destructuring */
import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { context } from '../../../../globalState/GlobalState';
import { getOptimXBackgroundStyle } from '../../../../catalog/Website';
import './css/Footer1.css';
import SeasonsComponent1 from '../../../uiElements/seasonsComponents/seasonsComponent1/SeasonsComponent1';
import ArticlesComponent1 from '../../../uiElements/articlesComponents/articlesComponent1/ArticlesComponent1';
import SponsorsComponent4 from '../../../uiElements/sponsorsComponents/sponsorsComponent4/SponsorsComponent4';

const Footer1 = (props) => {
    const { children, showLinks } = props;
    const [showSponsors, setShowSponsors] = useState(false);
    const [showArticles, setShowArticles] = useState(false);
    const [showSeasons, setShowSeasons] = useState(false);
    const history = useHistory();
    const { globalState } = useContext(context);

    const NOT_SHOW_SPONSORS_URL_SEGMENTS = ['', 'sponsors'];
    const SHOW_ARTICLES_URL_SEGMENTS = [];
    const SHOW_SEASONS_URL_SEGMENTS = [];

    // NOTE: Both of the following code blocks is needed. The first one is needed in case the website is refreshed and second one is needed when going from one web page to another
    useEffect(() => {
        let firstSegment = history.location.pathname.split('/')[1];
        if (showLinks) {
            if (NOT_SHOW_SPONSORS_URL_SEGMENTS.includes(firstSegment)) {
                setShowSponsors(false);
            } else {
                setShowSponsors(true);
            }

            if (SHOW_ARTICLES_URL_SEGMENTS.includes(firstSegment)) {
                setShowArticles(true);
            } else {
                setShowArticles(false);
            }

            if (SHOW_SEASONS_URL_SEGMENTS.includes(firstSegment)) {
                setShowSeasons(true);
            } else {
                setShowSeasons(false);
            }
        }
        return history.listen(() => {
            if (showLinks) {
                firstSegment = history.location.pathname.split('/')[1];
                if (NOT_SHOW_SPONSORS_URL_SEGMENTS.includes(firstSegment)) {
                    setShowSponsors(false);
                } else {
                    setShowSponsors(true);
                }

                if (SHOW_ARTICLES_URL_SEGMENTS.includes(firstSegment)) {
                    setShowArticles(true);
                } else {
                    setShowArticles(false);
                }

                if (SHOW_SEASONS_URL_SEGMENTS.includes(firstSegment)) {
                    setShowSeasons(true);
                } else {
                    setShowSeasons(false);
                }
            }
        });
    }, [history]);

    return (
        <footer className="m-t-50px">
            <div className="footer-seasons-container full-width">
                {showSeasons ? (<SeasonsComponent1 />) : null}
            </div>
            <div className="footer-articles-container full-width">
                {showArticles ? (<ArticlesComponent1 />) : null}
            </div>
            <div className="footer-sponsors-container full-width">
                {showSponsors ? (<SponsorsComponent4 />) : null}
            </div>
            <div className="footer-container full-width" style={getOptimXBackgroundStyle(globalState)}>
                {children}
            </div>
        </footer>
    );
};

Footer1.propTypes = {
    children: PropTypes.node.isRequired,
    showLinks: PropTypes.bool,
};

Footer1.defaultProps = {
    showLinks: true,
};

export default memo(Footer1);
