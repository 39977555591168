import { createContext } from 'react';
import { AccountType } from '../catalog/enums/AccountType';
import { SportsType } from '../catalog/enums/SportsType';
import { optimXTesting, websiteAccountType, websiteImages, websiteLogo, websiteOrganizationId, websiteOrganizationName, websiteSEOInfo, websiteSilhouetteLogo, websiteSportsType, websiteStyles, websiteTeamId, websiteTeamName } from '../catalog/TeamInfo';
import { isValidBoolean, isValidObject, isValidString } from '../catalog/Validity';

const initialState = {
    stylesDownloaded: false,
    seasons: [],
    logo: optimXTesting ? null : websiteLogo,
    silhouetteLogo: optimXTesting ? null : websiteSilhouetteLogo,
    websiteImages: optimXTesting ? [] : websiteImages,
    websiteStyles: optimXTesting ? null : websiteStyles,
    seoInfo: optimXTesting ? null : websiteSEOInfo,
    socials: null,
    form: null,
    licensingAgreement: null,
    policies: {
        privacy: '',
        terms: '',
        return: '',
        refund: '',
        shipping: '',
        privacyLink: 'https://optimxtechnologies.com/teamprivacypolicy',
        termsLink: 'https://optimxtechnologies.com/termsofservice',
        returnLink: '',
        refundLink: '',
        shippingLink: '',
    },
    teamId: optimXTesting ? '' : websiteTeamId,
    teamName: optimXTesting ? '' : websiteTeamName,
    teamAlias: optimXTesting ? '' : websiteTeamName,
    teamEmail: '',
    teamInfo: null,
    organizationId: optimXTesting ? '' : websiteOrganizationId,
    organizationName: optimXTesting ? '' : websiteOrganizationName,
    accountId: optimXTesting ? null : websiteTeamId,
    accountType: optimXTesting ? AccountType.NONE : websiteAccountType,
    accountObject: null,
    sportsType: optimXTesting ? SportsType.NONE : websiteSportsType,

    storeInfo: null,
    stripeAccountId: null,
    storeValidity: {
        storeActivate: false,
        productsActivate: false,
        ticketsActivate: false,
        formsActivate: false,
    },
    storeAdminValidity: {
        client: {
            storeActive: false,
            productsActive: false,
            ticketsActive: false,
            formsActive: false,
        },
    },

    cartQuantity: 0,
    cart: [],

    linkedProductsExist: false,

    showAds: true,
};

/**
 * This is the reducer for global state. It uses account rather than team or organization because account can either be organization or team
 *
 * @param state
 * @param action
 */
function reducerLocal(state: any, action: any) {
    switch (action.type) {
        case 'setStyles':
            return { ...state,
                stylesDownloaded: true,
                logo: action.logo,
                silhouetteLogo: action.silhouetteLogo,
                teamInfo: action.teamInfo,
                websiteImages: action.websiteImages,
                seoInfo: action.seoInfo,
                websiteStyles: action.websiteStyles,
                socials: action.socials,
                form: action.form,
                licensingAgreement: action.licensingAgreement,
                policies: isValidObject(action.policies) ? action.policies : { privacy: '', terms: '', return: '', refund: '', shipping: '', privacyLink: 'https://optimxtechnologies.com/teamprivacypolicy', termsLink: 'https://optimxtechnologies.com/termsofservice', returnLink: '', refundLink: '', shippingLink: '' },
                seasons: action.seasons,
                teamEmail: action.teamEmail,
                teamName: action.teamName,
                teamAlias: isValidObject(action.teamInfo) && isValidString(action.teamInfo.alias) ? action.teamInfo.alias : action.teamName,
                teamId: action.accountId,
                organizationName: action.organizationName,
                organizationId: action.accountObject.organizationId,
                accountId: action.accountId,
                accountType: action.accountType,
                accountObject: action.accountObject,
                sportsType: action.sportsType,
                storeInfo: action.storeInfo,
                stripeAccountId: isValidObject(action.stripeAccountId) ? action.stripeAccountId : null,
                storeValidity: isValidObject(action.storeInfo) ? {
                    storeActivate: action.storeInfo.storeActivate,
                    productsActivate: action.storeInfo.productsActivate && action.storeInfo.storeActivate,
                    ticketsActivate: action.storeInfo.ticketsActivate && action.storeInfo.storeActivate,
                    formsActivate: action.storeInfo.formsActivate && action.storeInfo.storeActivate,
                }
                    : {
                        storeActivate: false,
                        productsActivate: false,
                        ticketsActivate: false,
                        formsActivate: false,
                    },
            };
        case 'setStoreAdminInfo':
            return { ...state, storeAdminValidity: { client: action.info.client } };
        case 'setCartQuantity':
            return { ...state, cartQuantity: action.quantity };
        case 'setCart':
            return { ...state, cart: action.cart };
        case 'setLinkedProductsExist':
            return { ...state, linkedProductsExist: action.exist };
        case 'setShowAds':
            return { ...state, showAds: isValidBoolean(action.showAds) ? action.showAds : true };
        default:
            return initialState;
    }
}

const StateContext = createContext({
    state: initialState,
    dispatch: () => { return 0; },
});

export const { Provider } = StateContext; // To pass down state and dispatcher

export const reducer = reducerLocal; // We can use it in the main file with useReducer

export const context = StateContext; // useContext

export const defaultState = initialState; // useContext
