import React, { memo, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { getSportsTypeName } from '../../../../catalog/enums/SportsType';
import { isValidObject, isValidString } from '../../../../catalog/Validity';
import { context } from '../../../../globalState/GlobalState';
import { createHomePageDescriptionTag, createHomePageKeywordsTag, createHomePageTitleTag } from './MetaTags';

const FullMetaTags = () => {
    const { globalState } = useContext(context);

    const getTeamName = () => {
        return `${getSportsTypeName(globalState.sportsType)} ${globalState.teamName}`;
    };

    const getManifest = () => {
        if (!isValidString(globalState.organizationName)) {
            return null;
        }
        const manifest = {
            name: `${getTeamName()} ${globalState.organizationName}`,
            short_name: `${globalState.teamAlias}`,
            description: `${getTeamName()} ${globalState.organizationName}`,
            start_url: '.',
            background_color: '#ffffff',
            theme_color: `${globalState.websiteStyles.colors.primaryColor}`,
            icons: [
                {
                    src: `${globalState.logo.logo1}`,
                    sizes: '36x36',
                    type: 'image/png',
                    density: '0.75',
                },
                {
                    src: `${globalState.logo.logo2}`,
                    sizes: '48x48',
                    type: 'image/png',
                    density: '1.0',
                },
                {
                    src: `${globalState.logo.logo2}`,
                    sizes: '72x72',
                    type: 'image/png',
                    density: '1.5',
                },
                {
                    src: `${globalState.logo.logo2}`,
                    sizes: '96x96',
                    type: 'image/png',
                    density: '2.0',
                },
                {
                    src: `${globalState.logo.logo2}`,
                    sizes: '144x144',
                    type: 'image/png',
                    density: '3.0',
                },
                {
                    src: `${globalState.logo.logo2}`,
                    sizes: '192x192',
                    type: 'image/png',
                    density: '4.0',
                },
                {
                    src: `${globalState.logo.logo3}`,
                    sizes: '512x512',
                    type: 'image/png',
                },
            ],
        };
        const stringManifest = JSON.stringify(manifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        return URL.createObjectURL(blob);
    };

    return (
        <Helmet>
            {isValidString(createHomePageTitleTag(globalState)) ? (<title>{createHomePageTitleTag(globalState)}</title>) : (null)}
            {isValidString(createHomePageDescriptionTag(globalState)) ? (<meta name="description" content={createHomePageDescriptionTag(globalState)} />) : (null)}
            {isValidObject(globalState) && isValidObject(globalState.websiteStyles) && isValidObject(globalState.websiteStyles.colors) && isValidString(globalState.websiteStyles.colors.primaryColor) ? (<meta name="theme-color" content={globalState.websiteStyles.colors.primaryColor} />) : null}
            {isValidObject(globalState) && isValidString(globalState.organizationName) && isValidString(getTeamName()) ? (<link rel="author" content={`${getTeamName()} ${globalState.organizationName}`} />) : null}
            {isValidObject(globalState) && isValidString(globalState.organizationName) && isValidString(getTeamName()) ? (<link rel="keywords" content={createHomePageKeywordsTag(globalState)} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) ? (<link rel="icon" href={globalState.logo.logo2} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo2) ? (<link rel="shortcut icon" href={globalState.logo.logo2} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo2) ? (<link rel="apple-touch-icon" sizes="57x57" href={globalState.logo.logo2} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo2) ? (<link rel="apple-touch-icon" sizes="60x60" href={globalState.logo.logo2} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo2) ? (<link rel="apple-touch-icon" sizes="72x72" href={globalState.logo.logo2} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo2) ? (<link rel="apple-touch-icon" sizes="76x76" href={globalState.logo.logo2} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo2) ? (<link rel="apple-touch-icon" sizes="114x114" href={globalState.logo.logo2} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo2) ? (<link rel="apple-touch-icon" sizes="120x120" href={globalState.logo.logo2} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo2) ? (<link rel="apple-touch-icon" sizes="144x144" href={globalState.logo.logo2} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo2) ? (<link rel="apple-touch-icon" sizes="152x152" href={globalState.logo.logo2} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo2) ? (<link rel="apple-touch-icon" sizes="180x180" href={globalState.logo.logo2} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo2) ? (<link rel="icon" type="image/png" sizes="192x192" href={globalState.logo.logo2} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo1) ? (<link rel="icon" type="image/png" sizes="32x32" href={globalState.logo.logo1} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo2) ? (<link rel="icon" type="image/png" sizes="96x96" href={globalState.logo.logo2} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo1) ? (<link rel="icon" type="image/png" sizes="16x16" href={globalState.logo.logo1} />) : null}
            {isValidObject(globalState) && isValidObject(globalState.websiteStyles) && isValidObject(globalState.websiteStyles.colors) && isValidString(globalState.websiteStyles.colors.primaryColor) ? (<meta name="msapplication-TileColor" content={globalState.websiteStyles.colors.primaryColor} />) : null}
            {isValidObject(globalState) && isValidString(globalState.logo) && isValidString(globalState.logo.logo2) ? (<meta name="msapplication-TileImage" content={globalState.logo.logo2} />) : null}
            {isValidObject(getManifest()) ? (<link rel="manifest" href={getManifest()} />) : null}
        </Helmet>
    );
};

export default memo(FullMetaTags);
