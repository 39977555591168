import { db } from '../FirebaseLibrary';

export const createOpponentsReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/opponents`);
};

export const createOpponentReference = (teamId: string, opponentId: string) => {
    return createOpponentsReference(teamId).doc(opponentId);
};

export const fetchOpponentFirestoreAPI = (teamId: string, opponentId: string) => {
    return createOpponentReference(teamId, opponentId).get();
};

export const fetchOpponentsFirestoreAPI = (teamId: string) => {
    return createOpponentsReference(teamId).where('deleted', '==', false).orderBy('timeCreated', 'desc').get();
};
