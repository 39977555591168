/* eslint-disable no-console */
import { sendErrorAnalytics } from './Analytics';
import { getCurrentDateTime, getLocalTimeFromDatabaseTime } from './DateTime';
import { optimXTesting } from './TeamInfo';
import { isValidBoolean, isValidObject } from './Validity';

export const checkWebsiteActivation = (
    adminInfo: any,
    subscriptionInfo: any,
    websiteSubscriptionActive: boolean,
    domainSubscriptionActive: boolean,
    emailSubscriptionActive: boolean,
    dataDownloadrror: boolean,
    loadingComplete: boolean,
    licensingAgreement: any,
    snackbar: Function,
) => {
    try {
        // While the activation and subscription data is not loaded, show that website is active
        if (!loadingComplete) {
            return true;
        }

        // If there was some error while loading activation or subscription data, show that website is not active
        if (dataDownloadrror) {
            return false;
        }

        // If the admin info or active data does not exist then website should be inactive
        if (isValidObject(adminInfo) && isValidBoolean(adminInfo.active)) {
        // If the admin says website is inactive then it is inactive
            if (!adminInfo.active) {
                return false;
            }
        } else {
            return false;
        }

        // Check if the licensing agreement object exists
        if (isValidObject(licensingAgreement)) {
            // Check if siganture is needed from the team
            if (licensingAgreement.licensingSignatureNeeded) {
                return false;
            }
        }

        // If the subscription info does not exist then website should be inactive
        if (isValidObject(subscriptionInfo)) {
        // Check if optimx is charging for the website
            if (subscriptionInfo.websiteCharge) {
            // Check if website subscription is active or not
                if (!websiteSubscriptionActive) {
                // Check if there is a trial validity date
                    if (!isValidObject(subscriptionInfo.websiteTrialValidity)) {
                        return false;
                        // If there is a trial validity, check the validity date. If the validity date is before the current date then website is not active
                    } if (getCurrentDateTime() > getLocalTimeFromDatabaseTime(subscriptionInfo.websiteTrialValidity)) {
                        return false;
                    }
                }
            }

            // Check if optimx is charging for the domain
            if (subscriptionInfo.domain && subscriptionInfo.domainCharge) {
            // Check if domain subscription is active or not
                if (!domainSubscriptionActive) {
                    return false;
                }
            }

            // Check if optimx is charging for the emails
            if (subscriptionInfo.emailCharge && subscriptionInfo.domainEmails.length > 0) {
            // Check if domain subscription is active or not
                if (!emailSubscriptionActive) {
                    return false;
                }
            }
        } else {
            return false;
        }

        return true;
    } catch (error: any) {
        sendErrorAnalytics(error.message);
        if (optimXTesting) {
            snackbar(error.message, { variant: 'error' });
            console.log(error);
        }
        return false;
    }
};
