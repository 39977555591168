import React, { memo, useContext } from 'react';
import { isValidObject, isValidString } from '../../../../catalog/Validity';
import { context } from '../../../../globalState/GlobalState';
import OptimXGreenIcon from '../../../../assets/greenIcon.png';
import { getCurrentYearInString } from '../../../../catalog/DateTime';

const OptimXFooter = () => {
    const { globalState } = useContext(context);

    const getPrivacyLink = () => {
        if (isValidObject(globalState.policies) && isValidString(globalState.policies.privacyLink)) {
            return globalState.policies.privacyLink;
        }

        return 'https://optimxtechnologies.com/teamprivacypolicy';
    };

    const getTermsLink = () => {
        if (isValidObject(globalState.policies) && isValidString(globalState.policies.termsLink)) {
            return globalState.policies.termsLink;
        }

        return 'https://optimxtechnologies.com/teamprivacypolicy';
    };

    return (
        <div className="optimx-footer full-width">
            <div className="optimx-footer-copyright font-size-l m-l-100px">
                <p className="m-t-0 m-b-0">
                    {`© ${getCurrentYearInString()} ${globalState.teamAlias}`}
                </p>
                <div className="optimx-footer-agreements">
                    <a className="optimx-footer-agreement normal-font-family font-weight-200 font-size-s text-white m-t-0 m-b-0" href={getPrivacyLink()} target="_blank" rel="noreferrer">Privacy Policy</a>
                    <p className="optimx-footer-agreement-slash normal-font-family font-weight-200 font-size-s text-white m-t-0 m-b-0 m-l-10px m-r-10px">/</p>
                    <a className="optimx-footer-agreement normal-font-family font-weight-200 font-size-s text-white m-t-0 m-b-0" href={getTermsLink()} target="_blank" rel="noreferrer">Terms of Service</a>
                </div>
            </div>
            <a className="icon-container text-no-underline font-weight-800 m-r-100px" href="https://optimxsports.com" target="_blank" rel="noreferrer">
                <img src={OptimXGreenIcon} className="icon m-r-30px" alt="OptimX Sports" />
                <p className="name font-size-l">
                    Powered By OptimX Sports
                </p>
            </a>
        </div>
    );
};

export default memo(OptimXFooter);
