import { db } from '../FirebaseLibrary';

export const createArticlesReference = () => {
    return db.collection('articles');
};

export const createArticleReference = (articleId: string) => {
    return createArticlesReference().doc(articleId);
};

export const fetchArticleFirestoreAPI = (articleId: string) => {
    return createArticleReference(articleId).get();
};

export const fetchArticlesFirestoreAPI = (teamId: string) => {
    return createArticlesReference().where('teamId', '==', teamId).where('published', '==', true).orderBy('timeOfArticle', 'desc')
        .get();
};

export const fetchLimitedArticlesFirestoreAPI = (teamId: string, count: number) => {
    return createArticlesReference().where('teamId', '==', teamId).where('published', '==', true).orderBy('timeOfArticle', 'desc')
        .limit(count)
        .get();
};

export const fetchLimitedArticlesByTagFirestoreAPI = (teamId: string, tagId: string, count: number) => {
    return createArticlesReference().where('tagIds', 'array-contains', tagId)
        .where('teamId', '==', teamId)
        .where('published', '==', true)
        .orderBy('timeOfArticle', 'desc')
        .limit(count)
        .get();
};

export const fetchArticlesByTagFirestoreAPI = (teamId: string, tagId: string) => {
    return createArticlesReference().where('tagIds', 'array-contains', tagId)
        .where('teamId', '==', teamId)
        .where('published', '==', true)
        .orderBy('timeOfArticle', 'desc')
        .get();
};
