import React, { memo, useContext } from 'react';
import './css/Title2.css';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { context } from '../../../../globalState/GlobalState';
import { getOptimXBackgroundStyle } from '../../../../catalog/Website';

const Title2 = (props) => {
    const { link, title } = props;
    const { globalState } = useContext(context);

    return (
        <div className="title2-container p-t-20px p-r-20px p-b-20px p-l-20px full-width" style={getOptimXBackgroundStyle(globalState)}>
            <NavLink to={link} className="alegreya-font-family font-weight-800 title2-text font-size-4xl text-white m-l-50px m-t-0 m-b-0 text-uppercase text-no-underline">{title}</NavLink>
        </div>
    );
};

Title2.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default memo(Title2);
