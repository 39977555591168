import { db } from '../FirebaseLibrary';

export const createSeasonsReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/seasons`);
};

export const createSeasonReference = (teamId: string, seasonId: string) => {
    return createSeasonsReference(teamId).doc(seasonId);
};

export const fetchSeasonFirestoreAPI = (teamId: string, seasonId: string) => {
    return createSeasonReference(teamId, seasonId).get();
};

export const fetchSeasonsFirestoreAPI = (teamId: string) => {
    return createSeasonsReference(teamId).orderBy('timeCreated', 'desc').get();
};

export const fetchSeasonsOfPlayerFirestoreAPI = (teamId: string, playerId: string) => {
    return createSeasonsReference(teamId).where('tagIds', 'array-contains', playerId).orderBy('timeCreated', 'desc').get();
};

export const fetchSeasonsOfCoachFirestoreAPI = (teamId: string, coachId: string) => {
    return createSeasonsReference(teamId).where('tagIds', 'array-contains', coachId).orderBy('timeCreated', 'desc').get();
};
