/* eslint-disable no-console */
import { functions } from '../../firebase/FirebaseLibrary';
import { sendErrorAnalytics, sendFormAnalytics } from '../../catalog/Analytics';
import { FormType } from '../../catalog/enums/FormType';
import { SportsType } from '../../catalog/enums/SportsType';
import { INSERT_FORM_SUCCESS, INSERT_FORM_ERROR } from '../../catalog/NotificationsComments';
import { randomNumber } from '../../catalog/Others';
import { optimXTesting } from '../../catalog/TeamInfo';
import { isValidBoolean } from '../../catalog/Validity';

export const insertFormAPI = async (
    organizationId: string,
    teamId: string,
    sportsType: SportsType,
    formId: string,
    formType: FormType,
    formTitle: string,
    emails: string[],
    adminEmails: string[],
    otherOptions: any[],
    setState: Function,
    snackbar: Function,
    history: any,
    stripeAccountId: string,
    stripePrices: any[],
    successUrl: string,
    cancelUrl: string,
) => {
    try {
        const formSubmittedId = randomNumber();

        const formObject: any = {
            id: formSubmittedId,
            formId,
            formType,
            formTitle,
            teamId,
            organizationId,
            emails,
            adminEmails,
            otherOptions: JSON.stringify(otherOptions),
            read: false,
            replied: false,
            deleted: false,
            version: 'v2',
            sportsType,
        };

        const functionRef = functions.httpsCallable('onFormCheckout');
        const { data } = await functionRef({ stripeAccountId, stripePrices, formObject, successUrl, cancelUrl });
        if (isValidBoolean(data.optimXSuccess)) {
            if (data.optimXSuccess) {
                snackbar(INSERT_FORM_SUCCESS, { variant: 'success' });
                setState({ type: 'setDataChanged', dataChanged: false });
                sendFormAnalytics(formType, adminEmails);
                history.replace('/');
            } else {
                snackbar(INSERT_FORM_ERROR, { variant: 'error' });
            }
        } else {
            window.location.assign(data.url);
        }
    } catch (error) {
        sendErrorAnalytics(INSERT_FORM_ERROR);
        if (optimXTesting) {
            snackbar(INSERT_FORM_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const insertFormForInactivePageAPI = async (
    organizationId: string,
    teamId: string,
    sportsType: SportsType,
    formId: string,
    formType: FormType,
    formTitle: string,
    adminEmails: string[],
    otherOptions: any[],
    setState: Function,
    snackbar: Function,
) => {
    try {
        const formSubmittedId = randomNumber();

        const formObject: any = {
            id: formSubmittedId,
            formId,
            formType,
            formTitle,
            teamId,
            organizationId,
            emails: [],
            adminEmails,
            otherOptions: JSON.stringify(otherOptions),
            read: false,
            replied: false,
            deleted: false,
            version: 'v2',
            sportsType,
        };

        const functionRef = functions.httpsCallable('onFormCheckout');
        const { data } = await functionRef({ stripeAccountId: '', stripePrices: [], formObject, successUrl: '', cancelUrl: '' });
        if (isValidBoolean(data.optimXSuccess)) {
            if (data.optimXSuccess) {
                snackbar(INSERT_FORM_SUCCESS, { variant: 'success' });
                setState({ type: 'setDataChanged', dataChanged: false });
                sendFormAnalytics(formType, adminEmails);
            }
        }
    } catch (error) {
        sendErrorAnalytics(INSERT_FORM_ERROR);
        if (optimXTesting) {
            snackbar(INSERT_FORM_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

// export const insertFormAPI = async (
//     organizationId: string,
//     organizationName: string,
//     teamId: string,
//     teamName: string,
//     teamAlias: string,
//     teamPrimaryColor: string,
//     teamFeatureImage: string,
//     teamLogo: string,
//     teamSilhouetteLogo: string,
//     teamURL: string,
//     teamSocials: any,
//     sportsType: SportsType,
//     formId: string,
//     formType: FormType,
//     formNameForTeam: string,
//     formNameForFan: string,
//     emails: string[],
//     adminEmails: string[],
//     otherOptions: any[],
//     setState: Function,
//     snackbar: Function,
//     history: any,
// ) => {
//     try {
//         const formSubmittedId = randomNumber();

//         const form: FormSubmittedObject = {
//             id: formSubmittedId,
//             reference: createFormSubmittedReference(teamId, formSubmittedId),
//             formId,
//             formReference: formType === FormType.CUSTOM_FORM ? createCustomFormReference(teamId, formId) : createPreDevelopedFormReference(formId),
//             teamId,
//             teamReference: createTeamReference(teamId),
//             organizationId,
//             organizationReference: createOrganizationReference(organizationId),
//             formType,
//             emails,
//             otherOptions: JSON.stringify(otherOptions),
//             read: false,
//             replied: false,
//             deleted: false,
//             sportsType,
//             timeModified: getCurrentDateTime(),
//             timeCreated: getCurrentDateTime(),
//         };

//         await insertFormSubmittedFirestoreAPI(teamId, teamName, teamAlias, teamPrimaryColor, teamFeatureImage, teamLogo, teamSilhouetteLogo, teamURL, teamSocials, form, adminEmails, emails, formNameForTeam, formNameForFan);
//         snackbar(INSERT_FORM_SUCCESS, { variant: 'success' });
//         setState({ type: 'setDataChanged', dataChanged: false });
//         sendFormAnalytics(formType, adminEmails);
//         history.replace('/');
//     } catch (error) {
//         sendErrorAnalytics(INSERT_FORM_ERROR);
//         if (optimXTesting) {
//             snackbar(INSERT_FORM_ERROR, { variant: 'error' });
//             console.log(error);
//         }
//     }
// };

// const saveHandler = async () => {
//     setState({ type: 'setSavePressed', savePressed: true });
//     setState({ type: 'setLoadingSpinner', loadingSpinner: true });
//     if (checkFields()) {
//         await insertFormAPI(
//             globalState.organizationId,
//             globalState.organizationName,
//             globalState.teamId,
//             globalState.teamName,
//             globalState.teamAlias,
//             globalState.websiteStyles.colors.primaryColor,
//             getWebsiteImage(globalState, 'featureImage1'),
//             globalState.logo.logo2,
//             globalState.silhouetteLogo.logo2,
//             globalState.accountObject.url,
//             globalState.socials,
//             globalState.sportsType,
//             params.formid,
//             formType,
//             getFormName(formType, title, false),
//             getFormName(formType, title, false),
//             getEmails(),
//             getEmailsForForm(globalState.form, globalState.teamEmail, params.formid),
//             getOtherOptions(),
//             setState,
//             enqueueSnackbar,
//             history,
//         );
//     }
//     setState({ type: 'setLoadingSpinner', loadingSpinner: false });
// };
