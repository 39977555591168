/* eslint-disable no-console */
import { sendErrorAnalytics } from '../../catalog/Analytics';
import { ERROR_FETCHING_TEAM_STORE_INFO } from '../../catalog/NotificationsComments';
import { optimXTesting } from '../../catalog/TeamInfo';
import { isValidObject } from '../../catalog/Validity';
import { fetchStoreInfoFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsStoreAdmin';

export const fetchStoreAdminInfoAPI = async (teamId: string, setGlobalState: Function, snackbar: Function) => {
    try {
        const teamStoreInfoDoc = await fetchStoreInfoFirestoreAPI(teamId);
        const teamStoreInfo = teamStoreInfoDoc.data();
        if (isValidObject(teamStoreInfo) && teamStoreInfo !== undefined && isValidObject(teamStoreInfo.client)) {
            setGlobalState({ type: 'setStoreAdminInfo', info: teamStoreInfo });
        }
    } catch (error) {
        sendErrorAnalytics(ERROR_FETCHING_TEAM_STORE_INFO);
        if (optimXTesting) {
            snackbar(ERROR_FETCHING_TEAM_STORE_INFO, { variant: 'error' });
            console.log(error);
        }
    }
};
