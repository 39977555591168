import { SportsType } from '../../catalog/enums/SportsType';
import { db } from '../FirebaseLibrary';

export const createPreDevelopedSeasonsReference = () => {
    return db.collection('pre-developed-seasons');
};

export const createPreDevelopedSeasonReference = (seasonId: string) => {
    return createPreDevelopedSeasonsReference().doc(seasonId);
};

export const fetchPreDevelopedSeasonFirestoreAPI = (seasonId: string) => {
    return createPreDevelopedSeasonReference(seasonId).get();
};

export const fetchPreDevelopedSeasonsFirestoreAPI = (sportsType: SportsType) => {
    return createPreDevelopedSeasonsReference().where('sportsType', '==', sportsType).orderBy('name').get();
};
