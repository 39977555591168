export const getProductId = (website: boolean, domain: boolean, email: boolean): string => {
    if (website) {
        return 'prod_KtW1YTss1rDMvU';
    }
    if (domain) {
        return 'prod_KtW16FPUPQagy7';
    }
    if (email) {
        return 'prod_KtW126Kuikem60';
    }
    return '';
};

export const getPriceId = (website: boolean, domain: boolean, email: boolean, monthly: boolean): string => {
    if (website) {
        if (monthly) {
            return 'price_1KDiz8Ho1LnNEW1wyNghYfIg';
        }
        return 'price_1KDiz8Ho1LnNEW1wBBcxMExO';
    }
    if (domain) {
        if (monthly) {
            return 'price_1KDizaHo1LnNEW1wxFS9DXgJ';
        }
        return 'price_1KDizaHo1LnNEW1wMd31R4wt';
    }
    if (email) {
        if (monthly) {
            return 'price_1KDizhHo1LnNEW1wk2xnGnnC';
        }
        return 'price_1KDizhHo1LnNEW1wYEfQXDKl';
    }
    return '';
};
