/* eslint-disable no-console */
import { analytics } from '../firebase/FirebaseLibrary';
import { getSportsTypeName } from './enums/SportsType';
import { isValidString } from './Validity';
import { FormType, getFormTypeName } from './enums/FormType';
import { optimXTesting, websiteOrganizationId, websiteOrganizationName, websiteSportsType, websiteTeamId, websiteTeamName } from './TeamInfo';
import { updateAnalyticsArticleFirestoreAPI, updateAnalyticsCoachFirestoreAPI, updateAnalyticsEventFirestoreAPI, updateAnalyticsFormFirestoreAPI, updateAnalyticsGalleryFirestoreAPI, updateAnalyticsGameFirestoreAPI, updateAnalyticsHomePageFirestoreAPI, updateAnalyticsPlayerFirestoreAPI, updateAnalyticsProductFirestoreAPI, updateAnalyticsWebPageFirestoreAPI } from '../firebase/firebaseAPICalls/FirebaseAPICallsAnalytics';
import { getFullDateForAnalyticsStringFromDate } from './DateTime';
import { websiteVersion } from './Version';

let isSendingWebsiteAnalytics = !optimXTesting;

export const createAnalyticsId = (teamId: string) => {
    return `${teamId}-${getFullDateForAnalyticsStringFromDate(new Date())}`;
};

export const sendWebsiteAnalytics = () => {
    if (!optimXTesting) {
        analytics.setUserProperties({
            type: 'team_client',
            organizationName: websiteOrganizationName,
            organizationId: websiteOrganizationId,
            teamName: websiteTeamName,
            teamId: websiteTeamId,
            sportsType: websiteSportsType,
            sportsTypeName: getSportsTypeName(websiteSportsType),
            version: websiteVersion,
        });

        analytics.logEvent(
            'sports_client_website_team',
            {
                title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                organizationName: websiteOrganizationName,
                organizationId: websiteOrganizationId,
                teamName: websiteTeamName,
                teamId: websiteTeamId,
                sportsType: websiteSportsType,
                sportsTypeName: getSportsTypeName(websiteSportsType),
                version: websiteVersion,
            },
        );
        console.log('Google Analytics sent for sports_client_website_team');
    }
};

export const sendHomePageAnalytics = (globalState: any) => {
    if (!optimXTesting && globalState.stylesDownloaded) {
        try {
            updateAnalyticsHomePageFirestoreAPI(websiteOrganizationId, websiteTeamId, websiteSportsType, isSendingWebsiteAnalytics);
            analytics.logEvent(
                'sports_client_website_team_homepage',
                {
                    title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                    titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                    organizationName: websiteOrganizationName,
                    organizationId: websiteOrganizationId,
                    teamName: websiteTeamName,
                    teamId: websiteTeamId,
                    sportsType: websiteSportsType,
                    sportsTypeName: getSportsTypeName(websiteSportsType),
                    version: websiteVersion,
                },
            );
        } catch (e: any) {
            console.log(e);
        }
        console.log('Google Analytics sent for sports_client_website_team_homepage');
        if (isSendingWebsiteAnalytics) {
            isSendingWebsiteAnalytics = false;
            console.log('Website Analytics has been sent for OptimX Analytics and the variable is now changed to false');
        }
    }
};

export const sendWebPageAnalytics = (globalState: any, webPageTitle: string) => {
    if (!optimXTesting && isValidString(webPageTitle) && globalState.stylesDownloaded) {
        updateAnalyticsWebPageFirestoreAPI(websiteOrganizationId, websiteTeamId, webPageTitle, websiteSportsType, isSendingWebsiteAnalytics);
        analytics.logEvent(
            'sports_client_website_team_webpage',
            {
                title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithWebPage: `${webPageTitle} - ${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                organizationName: websiteOrganizationName,
                organizationId: websiteOrganizationId,
                teamName: websiteTeamName,
                teamId: websiteTeamId,
                sportsType: websiteSportsType,
                sportsTypeName: getSportsTypeName(websiteSportsType),
                webPageTitle,
                version: websiteVersion,
            },
        );
        console.log(`Google Analytics sent for sports_client_website_team_webpage with title: ${webPageTitle}`);
        if (isSendingWebsiteAnalytics) {
            isSendingWebsiteAnalytics = false;
            console.log('Website Analytics has been sent for OptimX Analytics and the variable is now changed to false');
        }
    }
};

export const sendPlayerAnalytics = (globalState: any, playerName: string, playerId: string) => {
    if (!optimXTesting && isValidString(playerName) && globalState.stylesDownloaded) {
        updateAnalyticsPlayerFirestoreAPI(websiteOrganizationId, websiteTeamId, playerId, websiteSportsType, isSendingWebsiteAnalytics);
        analytics.logEvent(
            'sports_client_website_team_player',
            {
                title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithPlayer: `${playerName} - ${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithPlayerWithId: `${playerName} - ${playerId} - ${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                organizationName: websiteOrganizationName,
                organizationId: websiteOrganizationId,
                teamName: websiteTeamName,
                teamId: websiteTeamId,
                sportsType: websiteSportsType,
                sportsTypeName: getSportsTypeName(websiteSportsType),
                playerName,
                playerId,
                version: websiteVersion,
            },
        );
        console.log('Google Analytics sent for sports_client_website_team_player');
        if (isSendingWebsiteAnalytics) {
            isSendingWebsiteAnalytics = false;
            console.log('Website Analytics has been sent for OptimX Analytics and the variable is now changed to false');
        }
    }
};

export const sendCoachAnalytics = (globalState: any, coachName: string, coachId: string) => {
    if (!optimXTesting && isValidString(coachName) && globalState.stylesDownloaded) {
        updateAnalyticsCoachFirestoreAPI(websiteOrganizationId, websiteTeamId, coachId, websiteSportsType, isSendingWebsiteAnalytics);
        analytics.logEvent(
            'sports_client_website_team_coach',
            {
                title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithCoach: `${coachName} - ${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithCoachWithId: `${coachName} - ${coachId} - ${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                organizationName: websiteOrganizationName,
                organizationId: websiteOrganizationId,
                teamName: websiteTeamName,
                teamId: websiteTeamId,
                sportsType: websiteSportsType,
                sportsTypeName: getSportsTypeName(websiteSportsType),
                coachName,
                coachId,
                version: websiteVersion,
            },
        );
        console.log('Google Analytics sent for sports_client_website_team_coach');
        if (isSendingWebsiteAnalytics) {
            isSendingWebsiteAnalytics = false;
            console.log('Website Analytics has been sent for OptimX Analytics and the variable is now changed to false');
        }
    }
};

export const sendGameAnalytics = (globalState: any, gameTitle: string, gameId: string) => {
    if (!optimXTesting && isValidString(gameTitle) && globalState.stylesDownloaded) {
        updateAnalyticsGameFirestoreAPI(websiteOrganizationId, websiteTeamId, gameId, websiteSportsType, isSendingWebsiteAnalytics);
        analytics.logEvent(
            'sports_client_website_team_game',
            {
                title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithGame: `${gameTitle} - ${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithGameWithId: `${gameTitle} - ${gameId} - ${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                organizationName: websiteOrganizationName,
                organizationId: websiteOrganizationId,
                teamName: websiteTeamName,
                teamId: websiteTeamId,
                sportsType: websiteSportsType,
                sportsTypeName: getSportsTypeName(websiteSportsType),
                gameTitle,
                gameId,
                version: websiteVersion,
            },
        );
        console.log('Google Analytics sent for sports_client_website_team_game');
        if (isSendingWebsiteAnalytics) {
            isSendingWebsiteAnalytics = false;
            console.log('Website Analytics has been sent for OptimX Analytics and the variable is now changed to false');
        }
    }
};

export const sendEventAnalytics = (globalState: any, eventTitle: string, eventId: string) => {
    if (!optimXTesting && isValidString(eventTitle) && globalState.stylesDownloaded) {
        updateAnalyticsEventFirestoreAPI(websiteOrganizationId, websiteTeamId, eventId, websiteSportsType, isSendingWebsiteAnalytics);
        analytics.logEvent(
            'sports_client_website_team_event',
            {
                title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithEvent: `${eventTitle} - ${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithEventWithId: `${eventTitle} - ${eventId} - ${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                organizationName: websiteOrganizationName,
                organizationId: websiteOrganizationId,
                teamName: websiteTeamName,
                teamId: websiteTeamId,
                sportsType: websiteSportsType,
                sportsTypeName: getSportsTypeName(websiteSportsType),
                eventTitle,
                eventId,
                version: websiteVersion,
            },
        );
        console.log('Google Analytics sent for sports_client_website_team_event');
        if (isSendingWebsiteAnalytics) {
            isSendingWebsiteAnalytics = false;
            console.log('Website Analytics has been sent for OptimX Analytics and the variable is now changed to false');
        }
    }
};

export const sendPracticeAnalytics = (globalState: any, practiceTitle: string, practiceId: string) => {
    if (!optimXTesting && isValidString(practiceTitle) && globalState.stylesDownloaded) {
        analytics.logEvent(
            'sports_client_website_team_practice',
            {
                title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithPractice: `${practiceTitle} - ${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithPracticeWithId: `${practiceTitle} - ${practiceId} - ${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                organizationName: websiteOrganizationName,
                organizationId: websiteOrganizationId,
                teamName: websiteTeamName,
                teamId: websiteTeamId,
                sportsType: websiteSportsType,
                sportsTypeName: getSportsTypeName(websiteSportsType),
                practiceTitle,
                practiceId,
                version: websiteVersion,
            },
        );
        console.log('Google Analytics sent for sports_client_website_team_practice');
        sendWebPageAnalytics(globalState, 'Practice Description');
    }
};

export const sendArticleAnalytics = (globalState: any, articleTitle: string, articleAuthor: string, articleId: string) => {
    if (!optimXTesting && isValidString(articleTitle) && globalState.stylesDownloaded) {
        updateAnalyticsArticleFirestoreAPI(websiteOrganizationId, websiteTeamId, articleId, websiteSportsType, isSendingWebsiteAnalytics);
        analytics.logEvent(
            'sports_client_website_team_article',
            {
                title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithArticle: `${articleTitle} - ${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithArticleWithId: `${articleTitle} - ${articleId} - ${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                organizationName: websiteOrganizationName,
                organizationId: websiteOrganizationId,
                teamName: websiteTeamName,
                teamId: websiteTeamId,
                sportsType: websiteSportsType,
                sportsTypeName: getSportsTypeName(websiteSportsType),
                articleTitle,
                articleAuthor,
                articleId,
                version: websiteVersion,
            },
        );
        console.log('Google Analytics sent for sports_client_website_team_article');
        if (isSendingWebsiteAnalytics) {
            isSendingWebsiteAnalytics = false;
            console.log('Website Analytics has been sent for OptimX Analytics and the variable is now changed to false');
        }
    }
};

export const sendGalleryAnalytics = (globalState: any, galleryTitle: string, galleryId: string) => {
    if (!optimXTesting && isValidString(galleryTitle) && globalState.stylesDownloaded) {
        updateAnalyticsGalleryFirestoreAPI(websiteOrganizationId, websiteTeamId, galleryId, websiteSportsType, isSendingWebsiteAnalytics);
        analytics.logEvent(
            'sports_client_website_team_gallery',
            {
                title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithGallery: `${galleryTitle} - ${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithGalleryWithId: `${galleryTitle} - ${galleryId} - ${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                organizationName: websiteOrganizationName,
                organizationId: websiteOrganizationId,
                teamName: websiteTeamName,
                teamId: websiteTeamId,
                sportsType: websiteSportsType,
                sportsTypeName: getSportsTypeName(websiteSportsType),
                galleryTitle,
                galleryId,
                version: websiteVersion,
            },
        );
        console.log('Google Analytics sent for sports_client_website_team_gallery');
        if (isSendingWebsiteAnalytics) {
            isSendingWebsiteAnalytics = false;
            console.log('Website Analytics has been sent for OptimX Analytics and the variable is now changed to false');
        }
    }
};

export const sendFormAnalytics = (formType: FormType, to: string[]) => {
    if (!optimXTesting) {
        updateAnalyticsFormFirestoreAPI(websiteOrganizationId, websiteTeamId, formType, websiteSportsType);

        analytics.logEvent(
            'sports_client_website_team_form',
            {
                title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                organizationName: websiteOrganizationName,
                organizationId: websiteOrganizationId,
                teamName: websiteTeamName,
                teamId: websiteTeamId,
                sportsType: websiteSportsType,
                sportsTypeName: getSportsTypeName(websiteSportsType),
                formType: getFormTypeName(formType),
                to,
                version: websiteVersion,
            },
        );
        console.log('Google Analytics sent for sports_client_website_team_form');
    }
};

export const sendProductAnalytics = (globalState: any, productTitle: string, productId: string) => {
    if (!optimXTesting && isValidString(productTitle) && globalState.stylesDownloaded) {
        updateAnalyticsProductFirestoreAPI(websiteOrganizationId, websiteTeamId, productId, websiteSportsType, isSendingWebsiteAnalytics);
        analytics.logEvent(
            'sports_client_website_team_article',
            {
                title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithProduct: `${productTitle} - ${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithProductWithId: `${productTitle} - ${productId} - ${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                organizationName: websiteOrganizationName,
                organizationId: websiteOrganizationId,
                teamName: websiteTeamName,
                teamId: websiteTeamId,
                sportsType: websiteSportsType,
                sportsTypeName: getSportsTypeName(websiteSportsType),
                productTitle,
                productId,
                version: websiteVersion,
            },
        );
        console.log('Google Analytics sent for sports_client_website_team_product');
        if (isSendingWebsiteAnalytics) {
            isSendingWebsiteAnalytics = false;
            console.log('Website Analytics has been sent for OptimX Analytics and the variable is now changed to false');
        }
    }
};

export const sendCustomAnalytics = (text: string, parameters: object = {}) => {
    if (!optimXTesting) {
        analytics.logEvent(
            `sports_client_website_team_${text}`,
            {
                ...parameters,
                title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                organizationName: websiteOrganizationName,
                organizationId: websiteOrganizationId,
                teamName: websiteTeamName,
                teamId: websiteTeamId,
                sportsType: websiteSportsType,
                sportsTypeName: getSportsTypeName(websiteSportsType),
                version: websiteVersion,
            },
        );
        console.log(`Google Analytics sent for sports_client_website_team_${text}`);
    }
};

export const sendErrorAnalytics = (message: string) => {
    if (!optimXTesting) {
        analytics.logEvent(
            'sports_client_website_team_error',
            {
                message,
                title: `${websiteOrganizationName} - ${websiteTeamName} - ${getSportsTypeName(websiteSportsType)}`,
                titleWithId: `${websiteOrganizationName} - ${websiteOrganizationId} - ${websiteTeamName} - ${websiteTeamId} - ${getSportsTypeName(websiteSportsType)}`,
                organizationName: websiteOrganizationName,
                organizationId: websiteOrganizationId,
                teamName: websiteTeamName,
                teamId: websiteTeamId,
                sportsType: websiteSportsType,
                sportsTypeName: getSportsTypeName(websiteSportsType),
                version: websiteVersion,
            },
        );
        console.log('Google Analytics sent for sports_client_website_team_error');
    }
};
