import { SportsType } from '../../catalog/enums/SportsType';
import { db } from '../FirebaseLibrary';

export const createPreDevelopedLeaguesReference = () => {
    return db.collection('pre-developed-leagues');
};

export const createPreDevelopedConferencesReference = (leagueId: string) => {
    return db.collection(`pre-developed-leagues/${leagueId}/conferences`);
};

export const createPreDevelopedLeagueReference = (leagueId: string) => {
    return createPreDevelopedLeaguesReference().doc(leagueId);
};

export const createPreDevelopedConferenceReference = (leagueId: string, conferenceId: string) => {
    return createPreDevelopedConferencesReference(leagueId).doc(conferenceId);
};

export const fetchPreDevelopedLeagueFirestoreAPI = (leagueId: string) => {
    return createPreDevelopedLeagueReference(leagueId).get();
};

export const fetchPreDevelopedConferenceFirestoreAPI = (leagueId: string, conferenceId: string) => {
    return createPreDevelopedConferenceReference(leagueId, conferenceId).get();
};

export const fetchPreDevelopedLeaguesFirestoreAPI = (sportsType: SportsType) => {
    return createPreDevelopedLeaguesReference().where('sportsType', '==', sportsType).orderBy('name').orderBy('division')
        .get();
};

export const fetchPreDevelopedConferencesFirestoreAPI = (leagueId: string, sportsType: SportsType) => {
    return createPreDevelopedConferencesReference(leagueId).where('sportsType', '==', sportsType).orderBy('name').get();
};
