import { db } from '../FirebaseLibrary';

export const createCoachesReference = () => {
    return db.collection('coaches');
};

export const createCoachReference = (coachId: string) => {
    return createCoachesReference().doc(coachId);
};

export const fetchCoachFirestoreAPI = (coachId: string) => {
    return createCoachReference(coachId).get();
};

export const fetchCoachesFirestoreAPI = (teamId: string) => {
    return createCoachesReference().where('teamId', '==', teamId).where('alumni', '==', false).where('deleted', '==', false)
        .orderBy('order', 'desc')
        .orderBy('timeCreated')
        .get();
};

export const fetchCoachesWithoutOrderFirestoreAPI = (teamId: string) => {
    return createCoachesReference().where('teamId', '==', teamId).where('alumni', '==', false).where('deleted', '==', false)
        .orderBy('timeCreated')
        .get();
};
