/**
 * This class is used for configuration of the firebase. For google maps api, the script is in index.html in public folder
 * This class also returns different firebase components like authentication, storage etc.
 */
import firebase from 'firebase/compat/app';

import 'firebase/compat/firestore'; // for cloud firestore
import 'firebase/compat/storage'; // for storage
import 'firebase/compat/analytics'; // for analytics
import 'firebase/compat/functions'; // for functions

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyCkPmW47ASmRYjifguX7mIRKX5gNmChYto',
    authDomain: 'optimx-sports.firebaseapp.com',
    projectId: 'optimx-sports',
    storageBucket: 'optimx-sports.appspot.com',
    messagingSenderId: '671798631941',
    appId: '1:671798631941:web:e865a9da16107dc020466c',
    measurementId: 'G-1MQ7GWY924',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const storageRef = firebase.storage().ref();
export const analytics = firebase.analytics();
export const functions = firebase.functions();
