import React, { memo, useReducer, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge, Drawer, IconButton, styled } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { StyleSheet, css } from 'aphrodite';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { context } from '../../../../globalState/GlobalState';
import { checkEmailValidity, isValidString } from '../../../../catalog/Validity';
import { checkProductsValidity, checkTicketsValidity, emptyCart, getCart, getCartQuantity, getSuccessURL } from '../../../../catalog/Store';
import Button1 from '../../../uiElements/buttons/Button1';
import TicketCustomerInfoPopup from '../../../uiElements/ticketsComponents/ticketComponent1/TicketsCustomerInfoPopup';
import NavItemCartTicketItem from './NavItemCartTicketItem';
import { showFeesFromCents, showPriceFromCents, showProfitFromCents, showPlatformFees, showShippingFees } from '../../../../catalog/Price';
import { checkoutFromCartAPI } from '../../../apiCalls/StoreAPICalls';
import { getOptimXBackgroundStyle } from '../../../../catalog/Website';
import NavItemCartProductItem from './NavItemCartProductItem';

const initialState = {
    drawer: false,
    openDrawerWhenChangeInPrice: false,
    customerInfoPopup: false,
    customerName: '',
    customerEmail: '',
    prices: {},
    shippingPrices: {},
    checkoutLoadingSpinner: false,
};

function reducer(state, action) {
    switch (action.type) {
        case 'reset':
            return { ...initialState };
        case 'resetWithDrawerOpen':
            return { ...initialState, drawer: true };
        case 'openDrawer':
            return { ...state, drawer: true };
        case 'closeDrawer':
            return { ...state, drawer: false };
        case 'openDrawerWhenChangeInPrice':
            return { ...state, openDrawerWhenChangeInPrice: true };
        case 'toggleDrawer':
            return { ...state, drawer: !state.drawer };
        case 'openCustomerInfoPopup':
            return { ...state, customerInfoPopup: true };
        case 'closeCustomerInfoPopup':
            return { ...state, customerInfoPopup: false };
        case 'setCustomerName':
            return { ...state, customerName: action.name };
        case 'setCustomerEmail':
            return { ...state, customerEmail: action.email };
        case 'setPrice':
            return { ...state, prices: { ...state.prices, [action.id]: action.price } };
        case 'setShippingPrice':
            return { ...state, shippingPrices: { ...state.shippingPrices, [action.id]: action.price } };
        case 'setCheckoutLoadingSpinner':
            return { ...state, checkoutLoadingSpinner: action.loadingSpinner };
        default:
            return initialState;
    }
}

const NavItemCart = (props) => {
    const { desktop } = props;
    const [state, setState] = useReducer(reducer, initialState);
    const history = useHistory();
    const { globalState, setGlobalState } = useContext(context);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const checkoutSuccess = params.get('checkoutsuccess');
        if (isValidString(checkoutSuccess)) {
            emptyCart(setGlobalState);
            setState({ type: 'reset' });
            history.replace(window.location.pathname);
        } else {
            setGlobalState({ type: 'setCart', cart: getCart() });
            setGlobalState({ type: 'setCartQuantity', quantity: getCartQuantity() });
            setState({ type: 'closeDrawer' });
        }
        setTimeout(() => {
            setState({ type: 'openDrawerWhenChangeInPrice' });
        }, 5000);
    }, []);

    useEffect(() => {
        if (!state.drawer && Object.keys(state.prices).length > 0 && desktop && state.openDrawerWhenChangeInPrice) {
            setState({ type: 'openDrawer' });
        }
    }, [state.prices]);

    const styles = StyleSheet.create({
        navitem: {
            color: isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor} !important` : '#ffffff !important',
            '@media (max-width: 1200px)': {
                // color: 'white',
                marginRight: '80px',
            },
        },
    });

    const StyledBadge = styled(Badge)({
        '& .MuiBadge-badge': {
            color: globalState.websiteStyles.colors.primaryColor,
            backgroundColor: isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white',
        },
    });

    const getTotalPrice = () => {
        let total = 0;
        Object.values(state.prices).forEach((price) => {
            if (price) {
                total += price;
            }
        });
        return total;
    };

    const getShippingPrice = () => {
        let total = 0;
        Object.values(state.shippingPrices).forEach((price) => {
            if (price) {
                total += price;
            }
        });
        return total;
    };

    const getCartItem = (cartItem) => {
        if (cartItem.cartItemType === 'TICKET') {
            return (
                <NavItemCartTicketItem
                    cartItemId={cartItem.cartItemId}
                    ticketId={cartItem.ticketId}
                    quantity={cartItem.quantity}
                    setPrice={(price) => { setState({ type: 'setPrice', id: cartItem.cartItemId, price }); }}
                />
            );
        } if (cartItem.cartItemType === 'PRODUCT') {
            return (
                <NavItemCartProductItem
                    cartItemId={cartItem.cartItemId}
                    productId={cartItem.productId}
                    productOptions={cartItem.productOptions}
                    quantity={cartItem.quantity}
                    setPrice={(price) => { setState({ type: 'setPrice', id: cartItem.cartItemId, price }); }}
                    setShippingPrice={(price) => { setState({ type: 'setShippingPrice', id: cartItem.cartItemId, price }); }}
                />
            );
        }
        return null;
    };

    const checkout = async () => {
        setState({ type: 'setCheckoutLoadingSpinner', loadingSpinner: true });

        let shippable = false;
        let shippingPrice = 0;
        let shippingWeight = 0;

        const { cart } = globalState;
        const finalStripePrices = [];
        const finalProductOptions = {};

        let productsExist = false;
        let ticketsExist = false;
        cart.forEach((cartItem) => {
            if (cartItem.cartItemType === 'PRODUCT') {
                productsExist = true;
            }
            if (cartItem.cartItemType === 'TICKET') {
                ticketsExist = true;
            }
        });

        if (productsExist) {
            if (!checkProductsValidity(globalState)) {
                enqueueSnackbar('Few items in your cart are not available for purchase at the moment', { variant: 'error' });
                emptyCart(setGlobalState);
                setState({ type: 'resetWithDrawerOpen' });
                return;
            }
        }

        if (ticketsExist) {
            if (!checkTicketsValidity(globalState)) {
                enqueueSnackbar('Few items in your cart are not available for purchase at the moment', { variant: 'error' });
                emptyCart(setGlobalState);
                setState({ type: 'resetWithDrawerOpen' });
                return;
            }
        }

        cart.forEach((cartItem) => {
            finalStripePrices.push({ id: cartItem.stripePriceId, quantity: cartItem.quantity, cartItemId: cartItem.cartItemId, shippable: cartItem.shippable, shippingPrice: cartItem.shippingPrice * cartItem.quantity, shippingWeight: cartItem.shippingWeight * cartItem.quantity });
            finalProductOptions[cartItem.cartItemId] = JSON.stringify(cartItem.productOptions);
            shippable = shippable || cartItem.shippable;
            shippingPrice += cartItem.shippingPrice * cartItem.quantity;
            shippingWeight += cartItem.shippingWeight * cartItem.quantity;
        });

        if (getTotalPrice() === 0) {
            if (state.customerInfoPopup) {
                if (!isValidString(state.customerName)) {
                    enqueueSnackbar('Please enter valid customer name', { variant: 'error' });
                    return;
                }
                if (!checkEmailValidity(state.customerEmail, () => {}, enqueueSnackbar)) {
                    enqueueSnackbar('Please enter valid customer email', { variant: 'error' });
                    return;
                }
            } else {
                setState({ type: 'openCustomerInfoPopup' });
                return;
            }
        }

        let redirectUrl = window.location.href;
        if (window.location.href.includes('?checkoutsuccess=true')) {
            redirectUrl = redirectUrl.replaceAll('?checkoutsuccess=true', '');
        }

        await checkoutFromCartAPI(globalState.stripeAccountId, finalStripePrices, state.customerName, state.customerEmail, finalProductOptions, shippable, shippingPrice, shippingWeight, `${getSuccessURL(globalState)}?checkoutsuccess=true`, redirectUrl, enqueueSnackbar, setState, setGlobalState);

        setState({ type: 'setCheckoutLoadingSpinner', loadingSpinner: false });
    };

    return (
        <>
            <div className={`nav-item nav-item-cart ${css(styles.navitem)}`} onClick={() => { setState({ type: 'openDrawer' }); }}>
                <StyledBadge badgeContent={globalState.cartQuantity}>
                    <ShoppingCartIcon />
                </StyledBadge>
            </div>
            <Drawer
                className="nav-item-cart-drawer"
                anchor="right"
                open={state.drawer}
                onClose={() => { setState({ type: 'closeDrawer' }); }}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <>
                    {isValidString(globalState.stripeAccountId) ? (
                        <>
                            <div className="nav-item-cart-topic" style={getOptimXBackgroundStyle(globalState)}>
                                <p className="alegreya-font-family font-size-2xl m-t-0 m-b-0 p-l-20px">Shopping Cart</p>
                                <IconButton
                                    aria-label="delete"
                                    size="medium"
                                    onClick={() => { setState({ type: 'closeDrawer' }); }}
                                >
                                    <CloseIcon style={{ color: isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white' }} />
                                </IconButton>
                            </div>
                            {showPlatformFees ? (
                                <>
                                    <div className="nav-item-cart-price m-t-5px">
                                        <p className="alegreya-font-family font-weight-800 text-uppercase m-t-0 m-b-0">Subtotal:</p>
                                        <p className="text-right-align m-t-0 m-b-0">{showProfitFromCents(getTotalPrice())}</p>
                                    </div>
                                    {showShippingFees && getShippingPrice() > 0 ? (
                                        <>
                                            <div className="nav-item-cart-price m-t-5px">
                                                <p className=" alegreya-font-family font-weight-800 text-uppercase m-t-0 m-b-0">Platform Fee:</p>
                                                <p className="text-right-align m-t-0 m-b-0">{showFeesFromCents(getTotalPrice())}</p>
                                            </div>
                                            <div className="nav-item-cart-price m-t-5px m-b-10px">
                                                <p className=" alegreya-font-family font-weight-800 text-uppercase m-t-0 m-b-0">Shipping and Handling Fee:</p>
                                                <p className="text-right-align m-t-0 m-b-0">{showPriceFromCents(getShippingPrice())}</p>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="nav-item-cart-price m-t-5px m-b-10px">
                                            <p className=" alegreya-font-family font-weight-800 text-uppercase m-t-0 m-b-0">Platform Fee:</p>
                                            <p className="text-right-align m-t-0 m-b-0">{showFeesFromCents(getTotalPrice())}</p>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {showShippingFees && getShippingPrice() > 0 ? (
                                        <>
                                            <div className="nav-item-cart-price m-t-5px">
                                                <p className="alegreya-font-family font-weight-800 text-uppercase m-t-0 m-b-0">Subtotal:</p>
                                                <p className="text-right-align m-t-0 m-b-0">{showPriceFromCents(getTotalPrice())}</p>
                                            </div>
                                            <div className="nav-item-cart-price m-t-5px m-b-10px">
                                                <p className=" alegreya-font-family font-weight-800 text-uppercase m-t-0 m-b-0">Shipping and Handling Fee:</p>
                                                <p className="text-right-align m-t-0 m-b-0">{showPriceFromCents(getShippingPrice())}</p>
                                            </div>
                                        </>
                                    ) : null}
                                </>
                            )}
                            <Button1
                                onClick={checkout}
                                title={`Checkout ${showPriceFromCents(getTotalPrice() + getShippingPrice())}`}
                                disabled={globalState.cartQuantity === 0}
                                backgroundColor={globalState.websiteStyles.colors.primaryColor}
                                fontColor={isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white'}
                                fontSize="1rem"
                                fontFamily="HelveticaNeue-CondensedBold, Roboto Condensed, Helvetica Neue, Helvetica,Arial, sans-serif"
                                borderRadius="5px"
                                height="50px"
                                width="280px"
                                loadingSpinner={state.checkoutLoadingSpinner}
                            />
                            {globalState.cart.map((cartItem) => {
                                return (
                                    <React.Fragment key={cartItem.cartItemId}>
                                        {getCartItem(cartItem)}
                                    </React.Fragment>
                                );
                            })}
                        </>
                    ) : null}
                </>
            </Drawer>
            <TicketCustomerInfoPopup
                open={state.customerInfoPopup}
                onCancel={() => {
                    setState({ type: 'closeCustomerInfoPopup' });
                    setState({ type: 'setCheckoutLoadingSpinner', loadingSpinner: false });
                }}
                name={state.customerName}
                email={state.customerEmail}
                onNameUpdate={(name) => { setState({ type: 'setCustomerName', name }); }}
                onEmailUpdate={(email) => { setState({ type: 'setCustomerEmail', email }); }}
                checkout={() => {
                    checkout();
                    setState({ type: 'closeCustomerInfoPopup' });
                    emptyCart(setGlobalState);
                    setState({ type: 'reset' });
                }}
            />
        </>
    );
};

NavItemCart.propTypes = {
    desktop: PropTypes.bool.isRequired,
};

export default (memo(NavItemCart));
