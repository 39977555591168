import { FormItemType } from './enums/FormItemType';
import { FormType, getFormTypeName } from './enums/FormType';
import { SportsType } from './enums/SportsType';
import { isValidObject } from './Validity';

export const getFormItemsForTeam = (organizationName: string, teamName: string, teamAlias: string, formItems: any[]) => {
    return formItems.map((formItem) => {
        if (formItem.type === FormItemType.TEXT) {
            return { ...formItem, text: formItem.text.replaceAll('$$##{organizationName}', teamAlias).replaceAll('$$##{teamName}', teamAlias).replaceAll('$$##{newLine}', '\n') };
        } if (formItem.type === FormItemType.TEXTFIELD_TEXT || FormItemType.TEXTFIELD_NUMBER || FormItemType.TEXTFIELD_DATE || FormItemType.TEXTFIELD_MULTILINE_TEXT || FormItemType.SELECT_OPTIONS) {
            return { ...formItem, title: formItem.title.replaceAll('$$##{organizationName}', teamAlias).replaceAll('$$##{teamName}', teamAlias), subtitle: formItem.subtitle.replace('$$##{organizationName}', teamAlias).replace('$$##{teamName}', teamAlias), helperText: formItem.helperText.replace('$$##{organizationName}', teamAlias).replace('$$##{teamName}', teamAlias) };
        }
        return formItem;
    });
};

export const getEmailsForForm = (formSettings: any, adminEmail: string, formId: string) => {
    if (!isValidObject(formSettings) || !isValidObject(formSettings.emails) || formSettings.emails.length === 0) {
        return [adminEmail];
    }
    const newEmails = formSettings.emails.filter((email: any) => {
        if (!isValidObject(formSettings.emailsNotAllowed[formId])) {
            return true;
        }
        if (formSettings.emailsNotAllowed[formId].length === 0) {
            return true;
        }
        return !formSettings.emailsNotAllowed[formId].includes(email.id);
    }).map((email: any) => {
        return email.email;
    });
    return newEmails;
};

export const getFormName = (formType: FormType, formName: string, showFormTypeName: boolean) => {
    if (formType !== FormType.NONE && formType !== FormType.OTHER && formType !== FormType.CUSTOM_FORM && formType !== FormType.CUSTOM_PLAYER_DUES_FORM && formType !== FormType.CUSTOM_SPONSORSHIP_FORM && formType !== FormType.CUSTOM_DONATION_FORM) {
        return getFormTypeName(formType);
    } if (formType === FormType.NONE || formType === FormType.OTHER) {
        return 'Unkown Form';
    } if (formName.toLowerCase().includes('form')) {
        if (showFormTypeName) {
            return `${formName}(${getFormTypeName(formType)})`;
        }
        return formName;
    }
    if (showFormTypeName) {
        return `${formName} Form(${getFormTypeName(formType)})`;
    }
    return `${formName} Form`;
};

export const getContactUsFormId = (sportsType: SportsType) => {
    if (sportsType === SportsType.WOMENSICEHOCKEY) {
        return 'contact-us-form-womens-hockey';
    }
    if (sportsType === SportsType.MENSICEHOCKEY) {
        return 'contact-us-form-mens-hockey';
    }
    if (sportsType === SportsType.WOMENSRUGBY) {
        return 'contact-us-form-womens-rugby';
    }
    if (sportsType === SportsType.MENSRUGBY) {
        return 'contact-us-form-mens-rugby';
    }
    if (sportsType === SportsType.WOMENSLACROSSE) {
        return 'contact-us-form-womens-lacrosse';
    }
    if (sportsType === SportsType.MENSLACROSSE) {
        return 'contact-us-form-mens-lacrosse';
    }

    return '';
};
