import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import './css/TextField1.css';

const TextField1 = (props) => {
    const { title, type, required, error, halfWidth, rows, helperText, defaultValue, value, onChange, handleKeypress } = props;

    return (
        <div className={halfWidth ? 'half-width textfield-primary-color' : 'full-width textfield-primary-color'}>
            <p className="textfield-title text-left-align m-t-0 m-b-0">{`${title}${required ? '*' : ''}`}</p>
            <TextField
                type={type}
                value={value}
                fullWidth
                required={required}
                error={error}
                variant="outlined"
                multiline={rows !== 1}
                rows={rows}
                helperText={helperText}
                defaultValue={defaultValue}
                onChange={onChange}
                onKeyPress={handleKeypress}
            />
        </div>
    );
};

TextField1.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    halfWidth: PropTypes.bool,
    rows: PropTypes.number,
    helperText: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    handleKeypress: PropTypes.func,
};

TextField1.defaultProps = {
    type: 'text',
    required: false,
    error: false,
    halfWidth: false,
    rows: 1,
    helperText: '',
    defaultValue: '',
    handleKeypress: () => {},
};

export default memo(TextField1);
