/* eslint-disable no-nested-ternary */
import React, { memo, useContext, useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import './css/SeasonsComponent1.css';
import { context } from '../../../../globalState/GlobalState';
import Spinner1 from '../../spinners/spinner1/Spinner1';
import SeasonThumbnail1 from '../../seasonThumbnails/seasonThumbnail1/SeasonThumbnail1';
import { fetchSeasonsAPI } from '../../../apiCalls/SeasonAPICalls';
import Title2 from '../../title/title2/Title2';

const initialState = {
    seasons: [],
    loadingSpinner: true,
};

function reducer(state, action) {
    switch (action.type) {
        case 'fetchSeasons':
            return { ...state, seasons: action.seasons };
        case 'setLoadingSpinner':
            return { ...state, loadingSpinner: action.loadingSpinner };
        default:
            return initialState;
    }
}

const SeasonsComponent1 = () => {
    const [state, setState] = useReducer(reducer, initialState);
    const { globalState } = useContext(context);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        async function fetchSeasons() {
            setState({ type: 'setLoadingSpinner', loadingSpinner: true });
            await fetchSeasonsAPI(globalState.accountId, setState, enqueueSnackbar);
            setState({ type: 'setLoadingSpinner', loadingSpinner: false });
        }
        fetchSeasons();
    }, []);

    return (
        <div className="seasons-component1-container full-width">
            <Title2 link="/schedule" title="Seasons" />
            <div className="seasons-component1 full-width m-t-20px m-b-70px">
                {state.loadingSpinner ? (<Spinner1 color={globalState.websiteStyles.colors.primaryColor} />) : (
                    <>
                        {state.seasons.sort((seasonA, seasonB) => {
                            return ((seasonA.name > seasonB.name) ? -1 : ((seasonB.name > seasonA.name) ? 1 : 0));
                        }).map((season) => {
                            return (
                                <div key={season.id} className="seasons-component1-season m-l-10px m-r-10px">
                                    <SeasonThumbnail1 season={season} />
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
};

export default memo(SeasonsComponent1);
