/* eslint-disable no-console */
import { functions } from '../../firebase/FirebaseLibrary';
import { isValidBoolean } from '../../catalog/Validity';
import { optimXTesting } from '../../catalog/TeamInfo';
import { sendErrorAnalytics } from '../../catalog/Analytics';
import { emptyCart } from '../../catalog/Store';

export const checkoutAPI = async (
    stripeAccountId: string,
    stripePrices: any[],
    customerName: string,
    customerEmail: string,
    productOptions: any,
    shippable: boolean,
    shippingPrice: number,
    shippingWeight: number,
    successUrl: string,
    cancelUrl: string,
    snackbar: Function,
) => {
    try {
        const functionRef = functions.httpsCallable('onCheckout');
        const { data } = await functionRef({ stripeAccountId, stripePrices, customerName, customerEmail, productOptions, shippable, shippingPrice, shippingWeight, successUrl, cancelUrl });
        console.log(data.optimXSuccess);
        if (isValidBoolean(data.optimXSuccess)) {
            if (data.optimXSuccess) {
                snackbar('Purchase successful', { variant: 'success' });
            } else {
                snackbar('Please provide name and email', { variant: 'error' });
            }
        } else {
            window.location.assign(data.url);
        }
    } catch (error: any) {
        snackbar('Error occurred while checking out', { variant: 'error' });
        sendErrorAnalytics(error.message.replaceAll('FirebaseError: ', ''));
        if (optimXTesting) {
            console.log(error);
        }
    }
};

export const checkoutFromCartAPI = async (
    stripeAccountId: string,
    stripePrices: any[],
    customerName: string,
    customerEmail: string,
    productOptions: any,
    shippable: boolean,
    shippingPrice: number,
    shippingWeight: number,
    successUrl: string,
    cancelUrl: string,
    snackbar: Function,
    setState: Function,
    setGlobalState: Function,
) => {
    try {
        const functionRef = functions.httpsCallable('onCheckout');
        const { data } = await functionRef({ stripeAccountId, stripePrices, customerName, customerEmail, productOptions, shippable, shippingPrice, shippingWeight, successUrl, cancelUrl });
        console.log(data.optimXSuccess);
        if (isValidBoolean(data.optimXSuccess)) {
            if (data.optimXSuccess) {
                snackbar('Purchase successful', { variant: 'success' });
            } else {
                snackbar('Please provide name and email', { variant: 'error' });
            }
        } else {
            window.location.assign(data.url);
        }
    } catch (error: any) {
        snackbar(error.message.replaceAll('FirebaseError: ', ''), { variant: 'error' });
        sendErrorAnalytics(error.message.replaceAll('FirebaseError: ', ''));
        emptyCart(setGlobalState);
        setState({ type: 'resetWithDrawerOpen' });
        if (optimXTesting) {
            console.log(error);
        }
    }
};
