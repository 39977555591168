/* eslint-disable no-console */
import { sendErrorAnalytics } from '../../catalog/Analytics';
import { FETCH_TICKETS_ERROR, TICKET_DOES_NOT_EXIST } from '../../catalog/NotificationsComments';
import { removeTicketFromCartUsingTicketId } from '../../catalog/Store';
import { optimXTesting } from '../../catalog/TeamInfo';
import { fetchTicketFirestoreAPI, fetchTicketsByEventIdFirestoreAPI, fetchTicketsFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTicket';

export const fetchTicketsAPI = async (teamId: string, setState: Function, snackbar: Function) => {
    const tickets: any = [];
    try {
        const docs = await fetchTicketsFirestoreAPI(teamId);
        docs.forEach((doc) => {
            if (doc.exists) {
                tickets.push({ ...doc.data() });
            }
        });
        setState({ type: 'fetchTickets', tickets });
    } catch (error) {
        console.log(error);
        sendErrorAnalytics(FETCH_TICKETS_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_TICKETS_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchTicketsByEventAPI = async (teamId: string, eventId: string, setState: Function, snackbar: Function) => {
    const tickets: any = [];
    try {
        const docs = await fetchTicketsByEventIdFirestoreAPI(teamId, eventId);
        docs.forEach((doc) => {
            if (doc.exists) {
                tickets.push({ ...doc.data() });
            }
        });
        setState({ type: 'fetchTickets', tickets });
    } catch (error) {
        sendErrorAnalytics(FETCH_TICKETS_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_TICKETS_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchTicketAPI = async (teamId: string, ticketId: string, setState: Function, setGlobalState: Function, snackbar: Function) => {
    try {
        const doc = await fetchTicketFirestoreAPI(ticketId);
        if (doc.exists && doc.data()?.teamId === teamId) {
            setState({ type: 'fetchTicket',
                id: doc.data()?.id,
                title: doc.data()?.title,
                shortDescription: doc.data()?.shortDescription,
                featureImage: doc.data()?.featureImage,
                prices: doc.data()?.prices,
                ticketsTotal: doc.data()?.ticketsTotal,
                ticketsSold: doc.data()?.ticketsSold,
                ticketsHold: doc.data()?.ticketsHold,
                eventId: doc.data()?.eventId,
                eventTitle: doc.data()?.eventTitle,
                eventType: doc.data()?.eventType,
                eventFeatureImage: doc.data()?.eventFeatureImage,
                eventVenue: doc.data()?.eventVenue,
                timeOfEvent: doc.data()?.timeOfEvent,
                endTimeOfEvent: doc.data()?.endTimeOfEvent,
                stripeProductId: doc.data()?.stripeProductId,
                published: doc.data()?.published,
                inStock: doc.data()?.inStock,
                deleted: doc.data()?.deleted,
            });
        } else {
            sendErrorAnalytics(TICKET_DOES_NOT_EXIST);
            setState({ type: 'setError' });
            if (optimXTesting) {
                snackbar(TICKET_DOES_NOT_EXIST, { variant: 'error' });
            }
            removeTicketFromCartUsingTicketId(ticketId, setGlobalState);
        }
    } catch (error) {
        sendErrorAnalytics(FETCH_TICKETS_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_TICKETS_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchTicketForCartAPI = async (teamId: string, ticketId: string, setState: Function, setGlobalState: Function, snackbar: Function) => {
    try {
        const doc = await fetchTicketFirestoreAPI(ticketId);
        if (doc.exists && doc.data()?.teamId === teamId) {
            if (!doc.data()?.deleted && doc.data()?.published && doc.data()?.inStock) {
                setState({ type: 'fetchTicket',
                    id: doc.data()?.id,
                    title: doc.data()?.title,
                    shortDescription: doc.data()?.shortDescription,
                    featureImage: doc.data()?.featureImage,
                    prices: doc.data()?.prices,
                    ticketsTotal: doc.data()?.ticketsTotal,
                    ticketsSold: doc.data()?.ticketsSold,
                    ticketsHold: doc.data()?.ticketsHold,
                    eventTitle: doc.data()?.eventTitle,
                    eventType: doc.data()?.eventType,
                    eventFeatureImage: doc.data()?.eventFeatureImage,
                    eventVenue: doc.data()?.eventVenue,
                    timeOfEvent: doc.data()?.timeOfEvent,
                    endTimeOfEvent: doc.data()?.endTimeOfEvent,
                    stripeProductId: doc.data()?.stripeProductId,
                    published: doc.data()?.published,
                    inStock: doc.data()?.inStock,
                    deleted: doc.data()?.deleted,
                });
            } else {
                sendErrorAnalytics(TICKET_DOES_NOT_EXIST);
                setState({ type: 'setError' });
                if (optimXTesting) {
                    snackbar(TICKET_DOES_NOT_EXIST, { variant: 'error' });
                }
                removeTicketFromCartUsingTicketId(ticketId, setGlobalState);
            }
        } else {
            sendErrorAnalytics(TICKET_DOES_NOT_EXIST);
            setState({ type: 'setError' });
            if (optimXTesting) {
                snackbar(TICKET_DOES_NOT_EXIST, { variant: 'error' });
            }
            removeTicketFromCartUsingTicketId(ticketId, setGlobalState);
        }
    } catch (error) {
        sendErrorAnalytics(FETCH_TICKETS_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_TICKETS_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};
