import { storeInfoId } from '../../catalog/Others';
import { db } from '../FirebaseLibrary';

/**
 * Following is for Private Store Info that cannot be updated by Client
 */

export const createStoreInfoReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/read-only`).doc(storeInfoId);
};

export const fetchStoreInfoFirestoreAPI = (teamId: string) => {
    return createStoreInfoReference(teamId).get();
};
