import { INVALID_EMAIL } from './NotificationsComments';

/**
 * This function checks email
 *
 * @param {*} email this is the passwword that needs to be checked
 * @param {*} setState this is used for emptying the text field
 * @param {*} snackbar this is used for notifications
 */
export const checkEmailValidity = (email: string, setState: Function, snackbar: Function) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return (true);
    }
    snackbar(INVALID_EMAIL, { variant: 'error' });
    setState({ type: 'error' });
    return (false);
};

export const isValidBoolean = (object: any) => {
    if (object !== null && object !== undefined) {
        return true;
    }
    return false;
};

export const isValidObject = (object: any) => {
    if (object && object !== null && object !== undefined) {
        return true;
    }
    return false;
};

export const isValidStat = (object: any) => {
    if (object && object !== null && object !== undefined && !Number.isNaN(object) && object > 0) {
        return true;
    }
    return false;
};

export const isValidString = (object: string) => {
    if (isValidObject(object) && object.length !== 0) {
        return true;
    }
    return false;
};
