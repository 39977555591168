/* eslint-disable no-console */
import { sendErrorAnalytics, sendWebsiteAnalytics } from '../../catalog/Analytics';
import { AccountType } from '../../catalog/enums/AccountType';
import { WEBSITE_DOES_NOT_EXIST } from '../../catalog/NotificationsComments';
import { optimXTesting } from '../../catalog/TeamInfo';
import { isValidObject, isValidString } from '../../catalog/Validity';
import { fetchPreDevelopedSeasonFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsPreDevelopedSeason';
import { fetchSeasonsFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsSeason';
import { fetchTeamFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';

export const accountAPICalls = async (accountId: string, history: any, setGlobalState: Function, snackbar: Function) => {
    try {
        if (!isValidString(accountId)) {
            history.push('/notfound');
            return;
        }
        const teamDoc = await fetchTeamFirestoreAPI(accountId);
        const seasonDocs = await fetchSeasonsFirestoreAPI(accountId);

        const seasonsTemp: any = [];
        seasonDocs.forEach((season) => {
            if (season.exists) {
                seasonsTemp.push({ ...season.data() });
            }
        });

        const seasons = new Array<any>(seasonsTemp.length);
        await Promise.all(seasonsTemp.map(async (season: any, index: number) => {
            const preDevelopedSeasonDoc = await fetchPreDevelopedSeasonFirestoreAPI(season.preDevelopedSeasonId);
            if (preDevelopedSeasonDoc.exists && preDevelopedSeasonDoc.exists) {
                seasons[index] = { ...season,
                    name: preDevelopedSeasonDoc?.data()?.name,
                    logo: preDevelopedSeasonDoc?.data()?.logo };
            }
        }));

        if (isValidObject(teamDoc) && teamDoc.exists && isValidObject(teamDoc.data())) {
            setGlobalState({
                type: 'setStyles',
                logo: teamDoc.data()?.logo,
                silhouetteLogo: teamDoc.data()?.silhouetteLogo,
                teamInfo: teamDoc.data()?.info,
                storeInfo: teamDoc.data()?.storeInfo,
                websiteImages: teamDoc.data()?.website.images,
                seoInfo: teamDoc.data()?.website.seoInfo,
                websiteStyles: teamDoc.data()?.website.styles,
                socials: teamDoc.data()?.socials,
                form: teamDoc.data()?.form,
                licensingAgreement: teamDoc.data()?.licensingAgreement,
                policies: teamDoc.data()?.policies,
                teamName: teamDoc.data()?.name,
                teamEmail: teamDoc.data()?.email,
                organizationName: teamDoc.data()?.organizationName,
                accountType: AccountType.TEAM,
                accountObject: teamDoc.data(),
                accountId,
                stripeAccountId: teamDoc.data()?.stripeAccountId,
                sportsType: teamDoc.data()?.sportsType,
                seasons,
            });
            sendWebsiteAnalytics();
        } else if (optimXTesting) {
            snackbar(WEBSITE_DOES_NOT_EXIST, { variant: 'error' });
        } else {
            sendErrorAnalytics(WEBSITE_DOES_NOT_EXIST);
        }
    } catch (error: any) {
        sendErrorAnalytics(error.message);
        if (optimXTesting) {
            snackbar(error.message, { variant: 'error' });
            console.log(error);
        }
    }
};
