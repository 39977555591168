import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import './css/ArticleThumbnail3.css';
import { NavLink } from 'react-router-dom';
import Button1 from '../../buttons/Button1';
import { getWebsiteImage } from '../../../../catalog/Website';
import { isValidObject, isValidString } from '../../../../catalog/Validity';
import { context } from '../../../../globalState/GlobalState';

const ArticleThumbnail3 = (props) => {
    const { article } = props;
    const { globalState } = useContext(context);

    const getImage = () => {
        if (isValidString(article.featureImage)) {
            return article.featureImage;
        } if (isValidObject(getWebsiteImage(globalState, 'articleFeatureImage'))) {
            return getWebsiteImage(globalState, 'articleFeatureImage');
        }
        return '';
    };

    const getTitle = (title) => {
        if (title.length > 40) {
            return `${title.substr(0, 40)}...`;
        }
        return title;
    };

    return (
        <NavLink to={`/articles/${article.id}`}>
            <div className="article-thumbnail3-container text-left-align full-width">
                <img className="article-thumbnail3-image" src={getImage()} alt={article.title ? article.title : 'Article Title'} />
                <div className="article-thumbnail3-text-container">
                    <div className="article-thumbnail3-button">
                        <Button1 title="Read More" transparent borderColor="white" fontColor="white" darkBackground fontSize="0.6rem" width="100px" />
                    </div>
                    <p className="alegreya-font-family font-weight-500 article-thumbnail3-text m-t-0 m-b-0 p-t-10px p-b-10px p-l-20px p-r-20px font-size-s font-weight-800 text-white normal-font-family">{getTitle(article.title)}</p>
                </div>
            </div>
        </NavLink>
    );
};

ArticleThumbnail3.propTypes = {
    article: PropTypes.object.isRequired,
};

export default memo(ArticleThumbnail3);
