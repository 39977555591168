import { db } from '../FirebaseLibrary';

export const createLeaguesReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/leagues`);
};

export const createLeagueReference = (teamId: string, leagueId: string) => {
    return createLeaguesReference(teamId).doc(leagueId);
};

export const fetchLeagueFirestoreAPI = (teamId: string, leagueId: string) => {
    return createLeagueReference(teamId, leagueId).get();
};

export const fetchLeaguesFirestoreAPI = (teamId: string) => {
    return createLeaguesReference(teamId).where('deleted', '==', false).orderBy('timeCreated', 'desc').get();
};
