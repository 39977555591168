import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { context } from '../../../../globalState/GlobalState';
import './css/NavBar2.css';
import { getOptimXBackgroundStyle } from '../../../../catalog/Website';

const NavBar2 = (props) => {
    const { children, scrolled } = props;
    const { globalState } = useContext(context);

    return (
        <>
            <header className={`navbar-container2 full-width ${scrolled ? 'navbar-container2-scrolled' : ''}`} style={getOptimXBackgroundStyle(globalState)}>
                {children}
            </header>
        </>
    );
};

NavBar2.propTypes = {
    children: PropTypes.node.isRequired,
    scrolled: PropTypes.bool.isRequired,
};

export default memo(NavBar2);
