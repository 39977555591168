import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { isValidObject, isValidString } from '../../../../catalog/Validity';
import { context } from '../../../../globalState/GlobalState';
import './css/Title3.css';

const Title3 = (props) => {
    const { title } = props;
    const { globalState } = useContext(context);

    return (
        <div className="title3 full-width">
            {isValidObject(globalState.logo) && isValidString(globalState.logo.logo3) ? (<img className="title3-logo m-l-100px" src={globalState.logo.logo3} alt={isValidString(globalState.teamName) ? globalState.teamName : 'Team Logo'} />) : null}
            <p className="title3-title alegreya-font-family font-weight-800 font-size-5xl text-uppercase m-t-0 m-b-0 m-l-100px font-weight-800">{title}</p>
        </div>
    );
};

Title3.propTypes = {
    title: PropTypes.string.isRequired,
};

export default memo(Title3);
