import { optimXAdminInfoId } from '../../catalog/Others';
import { db } from '../FirebaseLibrary';

export const createOptimXAdminInfoReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/read-only`).doc(optimXAdminInfoId);
};

export const fetchOptimXAdminInfoFirestoreAPI = (teamId: string) => {
    return createOptimXAdminInfoReference(teamId).get();
};
