import { db } from '../FirebaseLibrary';

export const createPlayersReference = () => {
    return db.collection('players');
};

export const createPlayerReference = (playerId: string) => {
    return createPlayersReference().doc(playerId);
};

export const fetchPlayerFirestoreAPI = (playerId: string) => {
    return createPlayerReference(playerId).get();
};

export const fetchAllPlayersFirestoreAPI = (teamId: string) => {
    return createPlayersReference().where('teamId', '==', teamId).where('deleted', '==', false).get();
};

export const fetchPlayersFirestoreAPI = (teamId: string) => {
    return createPlayersReference().where('teamId', '==', teamId).where('alumni', '==', false).where('deleted', '==', false)
        .orderBy('name')
        .get();
};

export const fetchLimitedPlayersFirestoreAPI = (teamId: string, count: number) => {
    return createPlayersReference().where('teamId', '==', teamId).where('alumni', '==', false).where('deleted', '==', false)
        .orderBy('name')
        .limit(count)
        .get();
};

export const fetchAlumniFirestoreAPI = (teamId: string) => {
    return createPlayersReference().where('teamId', '==', teamId).where('alumni', '==', true).where('deleted', '==', false)
        .orderBy('name')
        .get();
};

export const fetchLimitedAlumniFirestoreAPI = (teamId: string, count: number) => {
    return createPlayersReference().where('teamId', '==', teamId).where('alumni', '==', true).where('deleted', '==', false)
        .orderBy('name')
        .limit(count)
        .get();
};
