/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import React, { memo, useContext, useReducer, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import { WebsiteDesignType } from '../../../../catalog/enums/WebsiteDesignType';
import { isValidObject, isValidString } from '../../../../catalog/Validity';
import { context } from '../../../../globalState/GlobalState';
import NavBar2Design from '../../../designs/uiElements/navBar2/NavBar2';
import NavItemsMobile from './NavItemsMobile';
import NavItemsDesktop from './NavItemsDesktop';
import InstagramIcon from '../../../../assets/socialIcons/coloredInstagramIcon.png';
import YoutubeIcon from '../../../../assets/socialIcons/redYoutubeIcon.png';
import TwitterIcon from '../../../../assets/socialIcons/blueTwitterIcon.png';
import FacebookIcon from '../../../../assets/socialIcons/blueFacebookIcon.png';
import TiktokIcon from '../../../../assets/socialIcons/coloredTiktokIcon.png';
import { checkProductsValidity, checkTicketsValidity } from '../../../../catalog/Store';
import { getSportsTypeName2 } from '../../../../catalog/enums/SportsType';
import { optimXStoreWebsite } from '../../../../catalog/TeamInfo';

const initialState = {
    scrolled: false,
};

function reducer(state, action) {
    switch (action.type) {
        case 'isScrolled':
            return { ...state, scrolled: true };
        case 'isNotScrolled':
            return { ...state, scrolled: false };
        default:
            return initialState;
    }
}

const NavBar2 = (props) => {
    const { showLinks } = props;
    const { globalState } = useContext(context);
    const [state, setState] = useReducer(reducer, initialState);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const position = window.pageYOffset;
        if ((position) >= 100) {
            setState({ type: 'isScrolled' });
        } else {
            setState({ type: 'isNotScrolled' });
        }
    };

    const getPrimaryColor = (backupColor) => {
        if (isValidObject(globalState.websiteStyles) && isValidObject(globalState.websiteStyles.colors) && isValidString(globalState.websiteStyles.colors.primaryColor)) {
            return globalState.websiteStyles.colors.primaryColor;
        }

        return backupColor;
    };

    const getTeamName = () => {
        if (!globalState.stylesDownloaded) {
            return '';
        }
        if (isValidString(globalState.websiteStyles.bannerText) && globalState.websiteStyles.bannerText !== 'We will welcome you soon to our stadium') {
            return globalState.websiteStyles.bannerText;
        }
        if (isValidString(globalState.teamAlias)) {
            if (globalState.teamAlias.toLowerCase().includes(getSportsTypeName2(globalState.sportsType).toLowerCase())) {
                if (globalState.teamAlias.length < 20) {
                    return `${globalState.teamAlias} Official Website`;
                }
                return `${globalState.teamAlias}`;
            }
            if (`${globalState.teamAlias} ${getSportsTypeName2(globalState.sportsType)} Team's`.length < 20) {
                return `${globalState.teamAlias} ${getSportsTypeName2(globalState.sportsType)} Team's Official Website`;
            }
            return `${globalState.teamAlias} ${getSportsTypeName2(globalState.sportsType)} Team`;
        }

        if (isValidString(globalState.teamName)) {
            if (globalState.teamName.toLowerCase().includes(getSportsTypeName2(globalState.sportsType).toLowerCase())) {
                if (globalState.teamName.length < 20) {
                    return `${globalState.teamName} Official Website`;
                }
                return `${globalState.teamName}`;
            }
            if (`${globalState.teamName} ${getSportsTypeName2(globalState.sportsType)} Team's`.length < 20) {
                return `${globalState.teamName} ${getSportsTypeName2(globalState.sportsType)} Team's Official Website`;
            }
            return `${globalState.teamName} ${getSportsTypeName2(globalState.sportsType)} Team`;
        }

        return globalState.teamAlias;
    };

    const getStoreStatus = () => {
        if (showLinks && checkProductsValidity(globalState)) {
            return (
                <NavLink className="navbar-whitebar-store text-no-underline text-black" to="/store" exact>
                    <LocalMallOutlinedIcon style={{ marginRight: '10px', color: '#101010' }} />
                    <p>Official Online Store Now Open</p>
                </NavLink>
            );
        }

        if (showLinks && checkTicketsValidity(globalState)) {
            return (
                <NavLink className="navbar-whitebar-store text-no-underline text-black" to="/tickets" exact>
                    <ConfirmationNumberOutlinedIcon style={{ marginRight: '10px', color: '#101010' }} />
                    <p>Tickets Now Available</p>
                </NavLink>
            );
        }

        return null;
    };

    const styles = StyleSheet.create({
        navbarWhiteBar: {
            '::before': {
                'background-color': getPrimaryColor('#FFFFFF'),
            },
            '::after': {
                'border-top-color': getPrimaryColor('#FFFFFF'),
                '@media (max-width: 1200px)': {
                    'border-top-color': getPrimaryColor('#FFFFFF'),
                },
            },
        },
    });

    const getNavLinks = () => {
        if (isValidObject(globalState) && isValidObject(globalState.websiteStyles) && isValidObject(globalState.websiteStyles.navLinks)) {
            const finalNavLinks = [];
            globalState.websiteStyles.navLinks.forEach((navLink) => {
                if (navLink.value === '/season/:seasonid') {
                    finalNavLinks.push({ title: navLink.title,
                        link: '/options',
                        value: '/options',
                        options: [...globalState.seasons.map((season) => {
                            return { title: `Roster ${season.name}`, link: `/season/${season.id}`, value: `/season/${season.id}`, required: false };
                        })] });
                } else if (navLink.value === '/seasonschedule/:seasonid') {
                    finalNavLinks.push({ title: navLink.title,
                        link: '/options',
                        value: '/options',
                        options: [...globalState.seasons.map((season) => {
                            return { title: `Season ${season.name}`, link: `/seasonschedule/${season.id}`, value: `/seasonschedule/${season.id}`, required: false };
                        })] });
                } else if (navLink.value === '/options') {
                    const finalNavLinkOptions = [];
                    navLink.options.forEach((option) => {
                        if (option.value === '/season/:seasonid') {
                            finalNavLinkOptions.push({ title: option.title,
                                link: '/options',
                                value: '/options',
                                options: [...globalState.seasons.map((season) => {
                                    return { title: `Roster ${season.name}`, link: `/season/${season.id}`, value: `/season/${season.id}`, required: false };
                                })] });
                        } else if (option.value === '/seasonschedule/:seasonid') {
                            finalNavLinkOptions.push({ title: option.title,
                                link: '/options',
                                value: '/options',
                                options: [...globalState.seasons.map((season) => {
                                    return { title: `Season ${season.name}`, link: `/seasonschedule/${season.id}`, value: `/seasonschedule/${season.id}`, required: false };
                                })] });
                        } else {
                            finalNavLinkOptions.push(option);
                        }
                    });
                    const newNavLink = { ...navLink, options: finalNavLinkOptions };
                    finalNavLinks.push(newNavLink);
                } else {
                    finalNavLinks.push(navLink);
                }
            });
            return finalNavLinks;
        }
        return [];
    };

    const getHeaderDesign = (children) => {
        if (!isValidObject(globalState) || !isValidObject(globalState.websiteStyles)) {
            return null;
        }

        if (globalState.websiteStyles.design.homepage === WebsiteDesignType.DESIGN_1) {
            return (
                <NavBar2Design scrolled={state.scrolled}>
                    {children}
                </NavBar2Design>
            );
        }

        return (
            <NavBar2Design scrolled={state.scrolled}>
                {children}
            </NavBar2Design>
        );
    };

    return (
        <>
            {getHeaderDesign(
                <>
                    <div className={`navbar-navbar ${state.scrolled ? 'navbar-navbar-scrolled' : ''}`}>
                        {isValidObject(globalState.silhouetteLogo) && isValidString(globalState.silhouetteLogo.logo2) ? (
                            <NavLink className="icon-container" to="/" exact>
                                <img src={globalState.silhouetteLogo ? globalState.silhouetteLogo.logo2 : ''} alt={isValidString(globalState.teamName) ? globalState.teamName : 'Team Logo'} className="icon" />
                            </NavLink>
                        ) : (
                            <p> </p>
                        )}
                        <div className="navbar-horizontal-bars">
                            <div className={`navbar-whitebar ${css(styles.navbarWhiteBar)}`}>
                                <div className="navbar-whitebar-content">
                                    <NavLink className="navbar-whitebar-teamname text-no-underline text-black p-l-10px p-r-10px" to="/" exact>{getTeamName()}</NavLink>
                                    {getStoreStatus()}
                                </div>
                            </div>
                            <div className="navbar-navitems">
                                {isValidObject(globalState.silhouetteLogo) && isValidString(globalState.silhouetteLogo.logo2) ? (
                                    <NavLink className="mobile-icon-container" to="/" exact>
                                        <img src={globalState.silhouetteLogo ? globalState.silhouetteLogo.logo2 : ''} alt={isValidString(globalState.teamName) ? globalState.teamName : 'Team Logo'} className="mobile-icon" />
                                    </NavLink>
                                ) : (
                                    <p> </p>
                                )}
                                <div>
                                    <NavItemsDesktop navItems={showLinks ? getNavLinks() : []} />
                                    <NavItemsMobile navItems={showLinks ? getNavLinks() : []} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`navbar-socials-container ${state.scrolled ? 'navbar-socials-container-scrolled' : ''}`}>
                        <div className="navbar-socials">
                            {showLinks && isValidObject(globalState) && isValidObject(globalState.socials) && isValidString(globalState.socials.twitterURL) && !optimXStoreWebsite ? (
                                <a className="navbar-socials-buttons" target="_blank" rel="noreferrer" href={globalState.socials.twitterURL}>
                                    <img className="navbar-socials-icon" src={TwitterIcon} alt="twitterLogo" />
                                </a>
                            ) : null}
                            {showLinks && isValidObject(globalState) && isValidObject(globalState.socials) && isValidString(globalState.socials.facebookURL) && !optimXStoreWebsite ? (
                                <a className="navbar-socials-buttons" target="_blank" rel="noreferrer" href={globalState.socials.facebookURL}>
                                    <img className="navbar-socials-icon" src={FacebookIcon} alt="facebookLogo" />
                                </a>
                            ) : null}
                            {showLinks && isValidObject(globalState) && isValidObject(globalState.socials) && isValidString(globalState.socials.instagramURL) && !optimXStoreWebsite ? (
                                <a className="navbar-socials-buttons" target="_blank" rel="noreferrer" href={globalState.socials.instagramURL}>
                                    <img className="navbar-socials-icon" src={InstagramIcon} alt="instagramLogo" />
                                </a>
                            ) : null}
                            {showLinks && isValidObject(globalState) && isValidObject(globalState.socials) && isValidString(globalState.socials.youtubeURL) && !optimXStoreWebsite ? (
                                <a className="navbar-socials-buttons" target="_blank" rel="noreferrer" href={`${globalState.socials.youtubeURL}?sub_confirmation=1&feature=subscribe-embed-click`}>
                                    <img className="navbar-socials-icon" src={YoutubeIcon} alt="youtubeLogo" />
                                </a>
                            ) : null}
                            {showLinks && isValidObject(globalState) && isValidObject(globalState.socials) && isValidString(globalState.socials.tiktokURL) && !optimXStoreWebsite ? (
                                <a className="navbar-socials-buttons" target="_blank" rel="noreferrer" href={globalState.socials.tiktokURL}>
                                    <img className="navbar-socials-icon" src={TiktokIcon} alt="tiktokLogo" />
                                </a>
                            ) : null}
                        </div>
                        <div className="navbar-shop-container">
                            {showLinks && checkTicketsValidity(globalState) ? (
                                <NavLink className="navbar-shop text-no-underline" to="/tickets">
                                    <ConfirmationNumberOutlinedIcon style={{ marginRight: '10px', color: '#101010' }} />
                                    <p style={{ color: '#101010' }}>Tickets</p>
                                </NavLink>
                            ) : null}
                            {showLinks && checkProductsValidity(globalState) ? (
                                <NavLink className="navbar-shop text-no-underline" to="/store">
                                    <LocalMallOutlinedIcon style={{ marginRight: '10px', color: '#101010' }} />
                                    <p style={{ color: '#101010' }}>Store</p>
                                </NavLink>
                            ) : null}
                        </div>
                    </div>
                </>,
            )}
        </>
    );
};

NavBar2.propTypes = {
    showLinks: PropTypes.bool,
};

NavBar2.defaultProps = {
    showLinks: true,
};

export default memo(NavBar2);
