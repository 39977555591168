import { db } from '../FirebaseLibrary';

export const createTeamsReference = () => {
    return db.collection('teams');
};

export const createTeamReference = (teamId: string) => {
    return createTeamsReference().doc(teamId);
};

export const fetchTeamFirestoreAPI = (teamId: string) => {
    return createTeamReference(teamId).get();
};
