import { db } from '../FirebaseLibrary';

export const createSponsorsReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/sponsors`);
};

export const createSponsorReference = (teamId: string, sponsorId: string) => {
    return createSponsorsReference(teamId).doc(sponsorId);
};

export const fetchSponsorFirestoreAPI = (teamId: string, sponsorId: string) => {
    return createSponsorReference(teamId, sponsorId).get();
};

export const fetchSponsorsFirestoreAPI = (teamId: string) => {
    return createSponsorsReference(teamId)
        .orderBy('order', 'desc')
        .orderBy('timeCreated')
        .get();
};

export const fetchSponsorsWithoutOrderFirestoreAPI = (teamId: string) => {
    return createSponsorsReference(teamId)
        .orderBy('timeCreated')
        .get();
};
