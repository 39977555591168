/* eslint-disable no-unused-vars */
export enum FormItemType {
    NONE,
    TEXT,
    TEXTFIELD_TEXT,
    TEXTFIELD_MULTILINE_TEXT,
    TEXTFIELD_NUMBER,
    TEXTFIELD_DATE,
    SELECT_OPTIONS,
}

export const getFormItemTypeName = (formSubmittedType: FormItemType): string => {
    if (formSubmittedType === FormItemType.TEXT) {
        return 'Text';
    } if (formSubmittedType === FormItemType.TEXTFIELD_TEXT) {
        return 'Text Input';
    } if (formSubmittedType === FormItemType.TEXTFIELD_MULTILINE_TEXT) {
        return 'Multiline Text Input';
    } if (formSubmittedType === FormItemType.TEXTFIELD_NUMBER) {
        return 'Number Input';
    } if (formSubmittedType === FormItemType.TEXTFIELD_DATE) {
        return 'Date Input';
    } if (formSubmittedType === FormItemType.SELECT_OPTIONS) {
        return 'Select Options';
    }
    return '';
};
