/* eslint-disable no-unused-vars */
export enum FormType {
    NONE,
    PLAYER_RECRUITING_FORM,
    ALUMNI_FORM,
    CONTACT_US_FORM,
    COACH_RECRUITMENT_FORM,
    GENERAL_RECRUITMENT_FORM,
    MEDIA_ENQUIRY_FORM,
    TRYOUTS_FORM,
    SPONSORSHIP_ENQUIRY_FORM,
    CUSTOM_FORM,
    OTHER,
    CUSTOM_PLAYER_DUES_FORM,
    CUSTOM_SPONSORSHIP_FORM,
    CUSTOM_DONATION_FORM,
}

export const getFormTypeName = (formSubmittedType: FormType): string => {
    if (formSubmittedType === FormType.PLAYER_RECRUITING_FORM) {
        return 'Player Recruiting Form';
    } if (formSubmittedType === FormType.ALUMNI_FORM) {
        return 'Alumni Form';
    } if (formSubmittedType === FormType.CONTACT_US_FORM) {
        return 'Contact Us Form';
    } if (formSubmittedType === FormType.COACH_RECRUITMENT_FORM) {
        return 'Coach Recruitment Form';
    } if (formSubmittedType === FormType.GENERAL_RECRUITMENT_FORM) {
        return 'General Recruitment Form';
    } if (formSubmittedType === FormType.MEDIA_ENQUIRY_FORM) {
        return 'Media Recruitment Form';
    } if (formSubmittedType === FormType.TRYOUTS_FORM) {
        return 'Tryouts Form';
    } if (formSubmittedType === FormType.SPONSORSHIP_ENQUIRY_FORM) {
        return 'Sponsorship Enquiry Form';
    } if (formSubmittedType === FormType.CUSTOM_FORM) {
        return 'Custom Form';
    } if (formSubmittedType === FormType.OTHER) {
        return 'Other';
    } if (formSubmittedType === FormType.CUSTOM_PLAYER_DUES_FORM) {
        return 'Player Dues Form';
    } if (formSubmittedType === FormType.CUSTOM_SPONSORSHIP_FORM) {
        return 'Sponsorship Form';
    } if (formSubmittedType === FormType.CUSTOM_DONATION_FORM) {
        return 'Donation Form';
    }
    return '';
};
