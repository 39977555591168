import { getFullDateStringFromDate, getLocalTimeFromDatabaseTime } from './DateTime';
import { isValidObject, isValidString } from './Validity';

export const getTwitterHandle = (url: string) => {
    if (!url) return '';
    const match = url.match(/^https?:\/\/(www\.)?twitter.com\/@?(?<handle>\w+)/);
    return match?.groups?.handle ? `${match.groups.handle}` : '';
};

export const getInstagramHandle = (url: string) => {
    if (!url) return '';
    const match = url.match(/^https?:\/\/(www\.)?instagram.com\/@?(?<handle>\w+)/);
    return match?.groups?.handle ? `@${match.groups.handle}` : '';
};

export const getYoutubeHandle = (url: string) => {
    const pattern = /(https?:\/\/)?(www\.)?youtu((\.be)|(be\..{2,5}))\/((user)|(channel))\/?([a-zA-Z0-9\-_]{1,})/;
    const match = url.match(pattern);
    if (isValidObject(match) && match !== null && match.length >= 9) {
        return match[9];
    }
    return '';
};

export const getXURLFomTwitterURL = (url: string) => {
    if (isValidString(url)) {
        if (url.includes('twitter.com')) {
            return url.replace('twitter.com', 'x.com');
        }
    }
    return '';
};

export const getArticleShareTitle = (title: string, teamName: string, author: string) => {
    let str = `Check out ${teamName}'s article, "${title}"`;

    if (isValidString(author)) {
        str += ` by ${author}`;
    }

    return str;
};

export const getGalleryShareTitle = (title: string, teamName: string) => {
    return `Check out ${teamName}'s gallery, "${title}"`;
};

export const getEventShareTitle = (title: string, teamName: string, venue: string, timeOfEvent: Date) => {
    let str = `Check out ${teamName}'s event, "${title}"`;

    if (isValidString(venue)) {
        str += ` at ${venue}`;
    }

    str += ` on ${getFullDateStringFromDate(getLocalTimeFromDatabaseTime(timeOfEvent))}`;

    return str;
};

export const getPracticeShareTitle = (title: string, teamName: string, venue: string, timeOfEvent: Date) => {
    let str = `Check out ${teamName}'s practice session, "${title}"`;

    if (isValidString(venue)) {
        str += ` at ${venue}`;
    }

    str += ` on ${getFullDateStringFromDate(getLocalTimeFromDatabaseTime(timeOfEvent))}`;

    return str;
};

export const getGameShareTitle = (title: string, teamName: string, status: string, venue: string, timeOfGame: Date, opponentName: string) => {
    if (isValidString(venue) || status !== 'tba' || isValidString(opponentName)) {
        let str = `Check out ${teamName}'s game`;

        if (isValidString(opponentName)) {
            str += ` against ${opponentName}`;
        }

        if (isValidString(venue)) {
            str += ` at ${venue}`;
        }

        if (status === 'tba') {
            str += '';
        } else {
            str += ` on ${getFullDateStringFromDate(getLocalTimeFromDatabaseTime(timeOfGame))}`;
        }

        return str;
    }
    return title;
};

export const getFormShareTitle = (title: string, teamName: string) => {
    if (title.toLowerCase().includes('form')) {
        return `Fill out ${teamName}'s ${title}`;
    }
    return `Fill out ${teamName}'s ${title} Form`;
};

export const getGameURL = (globalState: any, gameId: string) => {
    if (globalState !== null && globalState.accountObject !== null && globalState.accountObject.url !== null && globalState.accountObject.url.length !== 0 && gameId !== null && gameId.length !== 0) {
        if (globalState.accountObject.url.includes('?team=')) {
            const urlSegments = globalState.accountObject.url.split('/');
            let finalURL = '';
            urlSegments.forEach((url: string, index: number) => {
                if (index !== 3) {
                    finalURL += `${url}/`;
                }
            });
            finalURL += `games/${gameId}/${urlSegments[3]}`;
            return finalURL;
        }
        return `${globalState.accountObject.url}/games/${gameId}`;
    }
    return '';
};

export const getEventURL = (globalState: any, eventId: string) => {
    if (globalState !== null && globalState.accountObject !== null && globalState.accountObject.url !== null && globalState.accountObject.url.length !== 0 && eventId !== null && eventId.length !== 0) {
        if (globalState.accountObject.url.includes('?team=')) {
            const urlSegments = globalState.accountObject.url.split('/');
            let finalURL = '';
            urlSegments.forEach((url: string, index: number) => {
                if (index !== 3) {
                    finalURL += `${url}/`;
                }
            });
            finalURL += `events/${eventId}/${urlSegments[3]}`;
            return finalURL;
        }
        return `${globalState.accountObject.url}/events/${eventId}`;
    }
    return '';
};

export const getPracticeURL = (globalState: any, practiceId: string) => {
    if (globalState !== null && globalState.accountObject !== null && globalState.accountObject.url !== null && globalState.accountObject.url.length !== 0 && practiceId !== null && practiceId.length !== 0) {
        if (globalState.accountObject.url.includes('?team=')) {
            const urlSegments = globalState.accountObject.url.split('/');
            let finalURL = '';
            urlSegments.forEach((url: string, index: number) => {
                if (index !== 3) {
                    finalURL += `${url}/`;
                }
            });
            finalURL += `practice/${practiceId}/${urlSegments[3]}`;
            return finalURL;
        }
        return `${globalState.accountObject.url}/practice/${practiceId}`;
    }
    return '';
};

export const getArticleURL = (globalState: any, articleId: string) => {
    if (globalState !== null && globalState.accountObject !== null && globalState.accountObject.url !== null && globalState.accountObject.url.length !== 0 && articleId !== null && articleId.length !== 0) {
        if (globalState.accountObject.url.includes('?team=')) {
            const urlSegments = globalState.accountObject.url.split('/');
            let finalURL = '';
            urlSegments.forEach((url: string, index: number) => {
                if (index !== 3) {
                    finalURL += `${url}/`;
                }
            });
            finalURL += `articles/${articleId}/${urlSegments[3]}`;
            return finalURL;
        }
        return `${globalState.accountObject.url}/articles/${articleId}`;
    }
    return '';
};

export const getGalleryURL = (globalState: any, galleryId: string) => {
    if (globalState !== null && globalState.accountObject !== null && globalState.accountObject.url !== null && globalState.accountObject.url.length !== 0 && galleryId !== null && galleryId.length !== 0) {
        if (globalState.accountObject.url.includes('?team=')) {
            const urlSegments = globalState.accountObject.url.split('/');
            let finalURL = '';
            urlSegments.forEach((url: string, index: number) => {
                if (index !== 3) {
                    finalURL += `${url}/`;
                }
            });
            finalURL += `galleries/${galleryId}/${urlSegments[3]}`;
            return finalURL;
        }
        return `${globalState.accountObject.url}/galleries/${galleryId}`;
    }
    return '';
};

export const getCustomPageURL = (globalState: any, customPageId: string) => {
    if (globalState !== null && globalState.accountObject !== null && globalState.accountObject.url !== null && globalState.accountObject.url.length !== 0 && customPageId !== null && customPageId.length !== 0) {
        if (globalState.accountObject.url.includes('?team=')) {
            const urlSegments = globalState.accountObject.url.split('/');
            let finalURL = '';
            urlSegments.forEach((url: string, index: number) => {
                if (index !== 3) {
                    finalURL += `${url}/`;
                }
            });
            finalURL += `pages/${customPageId}/${urlSegments[3]}`;
            return finalURL;
        }
        return `${globalState.accountObject.url}/pages/${customPageId}`;
    }
    return '';
};

export const getFormURL = (globalState: any, customFormId: string) => {
    if (globalState !== null && globalState.accountObject !== null && globalState.accountObject.url !== null && globalState.accountObject.url.length !== 0 && customFormId !== null && customFormId.length !== 0) {
        if (globalState.accountObject.url.includes('?team=')) {
            const urlSegments = globalState.accountObject.url.split('/');
            let finalURL = '';
            urlSegments.forEach((url: string, index: number) => {
                if (index !== 3) {
                    finalURL += `${url}/`;
                }
            });
            finalURL += `forms/${customFormId}/${urlSegments[3]}`;
            return finalURL;
        }
        return `${globalState.accountObject.url}/forms/${customFormId}`;
    }
    return '';
};

export const getLinkedProductURL = (teamURL: any, productId: string) => {
    if (isValidString(teamURL) && isValidString(productId)) {
        if (teamURL.includes('?team=')) {
            const initialUrl = teamURL;

            const urlSegments = initialUrl.split('/');
            let finalURL = '';
            urlSegments.forEach((url: string, index: number) => {
                if (index !== 3) {
                    finalURL += `${url}/`;
                }
            });
            finalURL += `products/${productId}/${urlSegments[3]}`;
            return finalURL;
        }
        return `${teamURL}/products/${productId}`;
    }
    return '';
};

export const getProductURL = (globalState: any, productId: string) => {
    if (globalState !== null && globalState.accountObject !== null && globalState.accountObject.url !== null && globalState.accountObject.url.length !== 0 && productId !== null && productId.length !== 0) {
        if (globalState.accountObject.url.includes('?team=')) {
            const urlSegments = globalState.accountObject.url.split('/');
            let finalURL = '';
            urlSegments.forEach((url: string, index: number) => {
                if (index !== 3) {
                    finalURL += `${url}/`;
                }
            });
            finalURL += `products/${productId}/${urlSegments[3]}`;
            return finalURL;
        }
        return `${globalState.accountObject.url}/products/${productId}`;
    }
    return '';
};

export const getTicketURL = (globalState: any, ticketId: string) => {
    if (globalState !== null && globalState.accountObject !== null && globalState.accountObject.url !== null && globalState.accountObject.url.length !== 0 && ticketId !== null && ticketId.length !== 0) {
        if (globalState.accountObject.url.includes('?team=')) {
            const urlSegments = globalState.accountObject.url.split('/');
            let finalURL = '';
            urlSegments.forEach((url: string, index: number) => {
                if (index !== 3) {
                    finalURL += `${url}/`;
                }
            });
            finalURL += `tickets/${ticketId}/${urlSegments[3]}`;
            return finalURL;
        }
        return `${globalState.accountObject.url}/tickets/${ticketId}`;
    }
    return '';
};
