import React, { memo } from 'react';
import AdComponent1 from '../../../uiElements/adComponents/adComponent1/AdComponent1';

const AdFooter = () => {
    return (
        <div className="optimx-ad full-width">
            <AdComponent1
                showDesktop
                showMobile
                desktopSizes={['leaderBoard', 'billboard', 'popUnder']}
                mobileSizes={['smartphone1', 'smartphone2', 'halfBanner', 'medRec', 'square', 'largeRec']}
                desktopContainerId="ads-container-footer"
                mobileContainerId="ads-container-footer"
                placementId="footer"
            />
        </div>
    );
};

export default memo(AdFooter);
