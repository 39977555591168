/* eslint-disable no-unused-vars */
import React, { memo, useContext, useReducer } from 'react';
import { Dialog, Slide, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import OptimXIcon from '../../../assets/greenIcon.png';
import './css/Inactive1.css';
import Footer from '../../website/uiElements/footer/Footer';
import NavBar2 from '../../website/uiElements/navBar/NavBar2';
import { context } from '../../../globalState/GlobalState';
import { getOptimXBackgroundStyle } from '../../../catalog/Website';
import { isValidObject, isValidString } from '../../../catalog/Validity';
import { sendErrorAnalytics } from '../../../catalog/Analytics';
import { RECAPTCHA_NOT_VERIFIED, FORM_FIELD_MISSING } from '../../../catalog/NotificationsComments';
import Button1 from '../../uiElements/buttons/Button1';
import { insertFormForInactivePageAPI } from '../../apiCalls/FormsSubmittedAPICalls';
import { FormType } from '../../../catalog/enums/FormType';
import { getContactUsFormId, getEmailsForForm, getFormName } from '../../../catalog/Form';
import { reCaptchaSiteKey } from '../../../catalog/TeamInfo';

const Recaptcha = require('react-recaptcha');

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {
    verified: false,
    text: '',
};

function reducer(state, action) {
    switch (action.type) {
        case 'reset':
            return { ...initialState };
        case 'setVerified':
            return { ...state, verified: action.verified };
        case 'setText':
            return { ...state, text: action.text };
        default:
            return initialState;
    }
}

const Inactive1 = (props) => {
    const { open } = props;
    const [state, setState] = useReducer(reducer, initialState);
    const { globalState } = useContext(context);
    const { enqueueSnackbar } = useSnackbar();

    const verifyCallback = () => {
        setState({ type: 'setVerified', verified: true });
    };

    const checkFields = () => {
        if (state.verified === null || state.verified === undefined || state.verified === false) {
            sendErrorAnalytics('recaptcha');
            enqueueSnackbar(RECAPTCHA_NOT_VERIFIED, { variant: 'error' });
            return false;
        }

        if (!isValidString(state.text)) {
            enqueueSnackbar(FORM_FIELD_MISSING('Contact Us message'), { variant: 'error' });
            return false;
        }

        return true;
    };

    const saveHandlerLocal = async () => {
        if (checkFields()) {
            await insertFormForInactivePageAPI(
                globalState.organizationId,
                globalState.teamId,
                globalState.sportsType,
                getContactUsFormId(globalState.sportsType),
                FormType.CONTACT_US_FORM,
                getFormName(FormType.CONTACT_US_FORM, 'Contact Us', false),
                getEmailsForForm(globalState.form, globalState.teamEmail, getContactUsFormId(globalState.sportsType)),
                [
                    { id: 'firstName', title: 'First Name', value: '' },
                    { id: 'lastName', title: 'Last Name', value: '' },
                    { id: 'email', title: 'Email', value: '' },
                    { id: 'message', title: 'Message', value: `Your fans are trying to reach you using your website. Please reactivate your subscription to interact with your fans. To reativate your webite, please visit https://optimxsports.com/signin. Latest message from your fan is shown below :\n\n${state.text}` },
                ],
                setState,
                enqueueSnackbar,
            );
        }
    };

    return (
        <Dialog fullScreen open={open} TransitionComponent={Transition}>
            <NavBar2 showLinks={false} />
            <main>
                <div className="inactive full-width">
                    <div className="inactive-left" style={getOptimXBackgroundStyle(globalState)}>
                        {isValidObject(globalState.silhouetteLogo) && isValidString(globalState.silhouetteLogo.logo4) ? (<img className="icon" src={globalState.silhouetteLogo.logo4} alt={isValidString(globalState.teamName) ? globalState.teamName : 'Team Logo'} />) : null}
                        <img className="icon" src={OptimXIcon} alt="OptimX Sports" />
                    </div>
                    <div className="inactive-right">
                        <p className="inactive-right-title text-uppercase">SITE INACTIVE</p>
                        <span className="inactive-right-subtitle text-uppercase">
                            You can still contact us by sending a message below
                        </span>
                        <TextField
                            className="inactive-right-textfield m-t-10px"
                            // helperText={formItem.helperText}
                            rows={4}
                            variant="outlined"
                            multiline
                            fullWidth
                            value={state.text}
                            onChange={(event) => { setState({ type: 'setText', text: event.target.value }); }}
                        />
                        <div className="inactive-submit-buttons">
                            <Recaptcha
                                sitekey={reCaptchaSiteKey}
                                render="explicit"
                                verifyCallback={verifyCallback}
                            />
                            <Button1
                                title="Submit"
                                backgroundColor={globalState.websiteStyles.colors.primaryColor}
                                borderColor={globalState.websiteStyles.colors.primaryColor}
                                fontColor={isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white'}
                                fontSize="1rem"
                                fontFamily="HelveticaNeue-CondensedBold, Roboto Condensed, Helvetica Neue, Helvetica,Arial, sans-serif"
                                borderRadius="5px"
                                height="44px"
                                width="300px"
                                onClick={saveHandlerLocal}
                            />
                        </div>
                    </div>
                </div>
            </main>
            <Footer showLinks={false} />
        </Dialog>
    );
};

Inactive1.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default memo(Inactive1);
