/* eslint-disable no-console */
import { sendErrorAnalytics } from '../../catalog/Analytics';
import { ERROR_FETCHING_OPTIMX_ADMIN_INFO } from '../../catalog/NotificationsComments';
import { optimXTesting } from '../../catalog/TeamInfo';
import { isValidBoolean } from '../../catalog/Validity';
import { fetchOptimXAdminInfoFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOptimXAdmin';

export const fetchOptimXAdminInfoAPI = async (teamId: string, setState: Function, snackbar: Function) => {
    try {
        const adminInfoDoc = await fetchOptimXAdminInfoFirestoreAPI(teamId);
        const adminInfo = adminInfoDoc.data();
        setState({ type: 'setAdminInfo', info: adminInfo });
    } catch (error) {
        setState({ type: 'setError' });
        sendErrorAnalytics(ERROR_FETCHING_OPTIMX_ADMIN_INFO);
        if (optimXTesting) {
            snackbar(ERROR_FETCHING_OPTIMX_ADMIN_INFO, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchShowAdsAPI = async (teamId: string, setGlobalState: Function, snackbar: Function) => {
    try {
        const adminInfoDoc = await fetchOptimXAdminInfoFirestoreAPI(teamId);
        const adminInfo = adminInfoDoc.data();
        if (adminInfo !== undefined && isValidBoolean(adminInfo.showAds)) {
            console.log(adminInfo.showAds);
            setGlobalState({ type: 'setShowAds', showAds: adminInfo.showAds });
        }
    } catch (error) {
        sendErrorAnalytics(ERROR_FETCHING_OPTIMX_ADMIN_INFO);
        if (optimXTesting) {
            snackbar(ERROR_FETCHING_OPTIMX_ADMIN_INFO, { variant: 'error' });
            console.log(error);
        }
    }
};
