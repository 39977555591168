/* eslint-disable no-unused-vars */
export enum AccountType {
    NONE,
    ORG,
    TEAM,
    PLAYER,
    COACH,
}

export const getAccountTypeName = (accountType: AccountType): string => {
    if (accountType === AccountType.TEAM) {
        return 'Team';
    } if (accountType === AccountType.ORG) {
        return 'Organization';
    } if (accountType === AccountType.PLAYER) {
        return 'Player';
    } if (accountType === AccountType.COACH) {
        return 'Coach';
    }
    return '';
};
