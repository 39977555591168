import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const ScrollToTop = (props) => {
    const { children } = props;
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant',
            });
        });
        return () => {
            unlisten();
        };
    }, [history]);

    return <>{children}</>;
};

ScrollToTop.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(ScrollToTop);
