/* eslint-disable no-console */
import { sendErrorAnalytics } from '../../catalog/Analytics';
import { FETCH_SPONSORS_ERROR, FETCH_SPONSOR_ERROR, SPONSOR_DOES_NOT_EXIST } from '../../catalog/NotificationsComments';
import { optimXTesting } from '../../catalog/TeamInfo';
import { fetchSponsorFirestoreAPI, fetchSponsorsFirestoreAPI, fetchSponsorsWithoutOrderFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsSponsor';

export const fetchSponsorsAPI = async (teamId: string, setState: Function, snackbar: Function) => {
    const sponsors: any = [];
    try {
        const docs = await fetchSponsorsFirestoreAPI(teamId);
        const withoutOrderDocs = await fetchSponsorsWithoutOrderFirestoreAPI(teamId);
        const sponsorIdSet = new Set();
        docs.forEach((doc) => {
            if (doc.exists) {
                sponsors.push({ ...doc.data() });
                sponsorIdSet.add(doc.data().id);
            }
        });
        withoutOrderDocs.forEach((doc) => {
            if (doc.exists && !sponsorIdSet.has(doc.data().id)) {
                sponsors.push({ ...doc.data() });
            }
        });
        setState({ type: 'fetchSponsors', sponsors });
    } catch (error) {
        sendErrorAnalytics(FETCH_SPONSORS_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_SPONSORS_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchSponsorAPI = async (teamId: string, sponsorId: string, setState: Function, snackbar: Function) => {
    try {
        const doc = await fetchSponsorFirestoreAPI(teamId, sponsorId);
        if (doc.exists) {
            setState({ type: 'fetchSponsor',
                name: doc.data()?.name,
                url: doc.data()?.url,
                logo: doc.data()?.logo,
                notes: doc.data()?.notes });
        } else if (optimXTesting) {
            snackbar(SPONSOR_DOES_NOT_EXIST, { variant: 'error' });
        } else {
            sendErrorAnalytics(SPONSOR_DOES_NOT_EXIST);
        }
    } catch (error) {
        sendErrorAnalytics(FETCH_SPONSOR_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_SPONSOR_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};
