/* eslint-disable no-unused-vars */
export enum SportsType {
    NONE,
    WOMENSICEHOCKEY,
    MENSICEHOCKEY,
    WOMENSRUGBY,
    MENSRUGBY,
    WOMENSLACROSSE,
    MENSLACROSSE
}

export interface SportsString {
    type: SportsType,
    string: string,
}

export const getSportsTypeName = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY) {
        return "Women's Ice Hockey Team";
    } if (sportsType === SportsType.MENSICEHOCKEY) {
        return "Men's Ice Hockey Team";
    } if (sportsType === SportsType.WOMENSRUGBY) {
        return "Women's Rugby Team";
    } if (sportsType === SportsType.MENSRUGBY) {
        return "Men's Rugby Team";
    } if (sportsType === SportsType.WOMENSLACROSSE) {
        return "Women's Lacrosse Team";
    } if (sportsType === SportsType.MENSLACROSSE) {
        return "Men's Lacrosse Team";
    }
    return '';
};

export const getSportsTypeName2 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY) {
        return 'Hockey';
    } if (sportsType === SportsType.MENSICEHOCKEY) {
        return 'Hockey';
    } if (sportsType === SportsType.WOMENSRUGBY) {
        return 'Rugby';
    } if (sportsType === SportsType.MENSRUGBY) {
        return 'Rugby';
    } if (sportsType === SportsType.WOMENSLACROSSE) {
        return 'Lacrosse';
    } if (sportsType === SportsType.MENSLACROSSE) {
        return 'Lacrosse';
    }
    return '';
};

export const getSportsTypePronoun = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.WOMENSLACROSSE) {
        return 'She';
    } if (sportsType === SportsType.MENSICEHOCKEY || sportsType === SportsType.MENSRUGBY || sportsType === SportsType.MENSLACROSSE) {
        return 'He';
    }
    return '';
};

export const getAllSportsTypeNames = (): SportsString[] => {
    return [
        { type: SportsType.WOMENSICEHOCKEY, string: getSportsTypeName(SportsType.WOMENSICEHOCKEY) },
        { type: SportsType.MENSICEHOCKEY, string: getSportsTypeName(SportsType.MENSICEHOCKEY) },
        { type: SportsType.WOMENSRUGBY, string: getSportsTypeName(SportsType.WOMENSRUGBY) },
        { type: SportsType.MENSRUGBY, string: getSportsTypeName(SportsType.MENSRUGBY) },
        { type: SportsType.WOMENSLACROSSE, string: getSportsTypeName(SportsType.WOMENSLACROSSE) },
        { type: SportsType.MENSLACROSSE, string: getSportsTypeName(SportsType.MENSLACROSSE) },
    ];
};
