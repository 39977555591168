/* eslint-disable no-console */
import { sendErrorAnalytics } from '../../catalog/Analytics';
import { getCurrentDateTime, getLocalTimeFromDatabaseTime } from '../../catalog/DateTime';
import { FETCH_PRODUCTS_ERROR, FETCH_PRODUCT_ERROR, PRODUCT_DOES_NOT_EXIST } from '../../catalog/NotificationsComments';
import { getLinkedProductURL, getProductURL } from '../../catalog/Social';
import { removeProductFromCartUsingProductId } from '../../catalog/Store';
import { optimXTesting } from '../../catalog/TeamInfo';
import { isValidObject, isValidString } from '../../catalog/Validity';
import { fetchLinkedProductsFirestoreAPI, fetchProductFirestoreAPI, fetchProductsFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsProduct';
import { fetchTeamFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';

export const checkLinkedProductsExistAPI = async (teamId: string, setState: Function, snackbar: Function) => {
    const products: any = [];
    try {
        const docs = await fetchLinkedProductsFirestoreAPI(teamId);
        docs.forEach((doc) => {
            if (doc.exists) {
                const { scheduled, scheduleStartTime, scheduleEndTime } = doc.data();

                let allowed = true;
                if (isValidObject(scheduled)) {
                    if (scheduled && getLocalTimeFromDatabaseTime(scheduleStartTime) > getCurrentDateTime()) {
                        allowed = false;
                    }
                    if (scheduled && getLocalTimeFromDatabaseTime(scheduleEndTime) < getCurrentDateTime()) {
                        allowed = false;
                    }
                }
                if (allowed) {
                    products.push({ ...doc.data() });
                }
            }
        });

        setState({ type: 'setLinkedProductsExist', exist: products.length > 0 });
    } catch (error) {
        console.log(error);
        sendErrorAnalytics(FETCH_PRODUCTS_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_PRODUCTS_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchLinkedProductsAPI = async (teamId: string, setState: Function, snackbar: Function) => {
    const products: any = [];
    try {
        const docs = await fetchLinkedProductsFirestoreAPI(teamId);
        docs.forEach((doc) => {
            if (doc.exists) {
                const { scheduled, scheduleStartTime, scheduleEndTime } = doc.data();

                let allowed = true;
                if (isValidObject(scheduled)) {
                    if (scheduled && getLocalTimeFromDatabaseTime(scheduleStartTime) > getCurrentDateTime()) {
                        allowed = false;
                    }
                    if (scheduled && getLocalTimeFromDatabaseTime(scheduleEndTime) < getCurrentDateTime()) {
                        allowed = false;
                    }
                }
                if (allowed) {
                    products.push({ ...doc.data() });
                }
            }
        });

        await Promise.all((products.map(async (product: any, index: number) => {
            const teamDoc = await fetchTeamFirestoreAPI(product.teamId);
            const team = teamDoc.data();

            products.splice(index, 1, { ...product, url: getLinkedProductURL(team?.url, product.id), linked: true });
        })));

        setState({ type: 'fetchLinkedProducts', linkedProducts: products });
    } catch (error) {
        console.log(error);
        sendErrorAnalytics(FETCH_PRODUCTS_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_PRODUCTS_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchProductsAPI = async (teamId: string, globalState: object, setState: Function, snackbar: Function) => {
    const products: any = [];
    try {
        const docs = await fetchProductsFirestoreAPI(teamId);
        docs.forEach((doc) => {
            if (doc.exists) {
                const { scheduled, scheduleStartTime, scheduleEndTime } = doc.data();

                let allowed = true;
                if (isValidObject(scheduled)) {
                    if (scheduled && getLocalTimeFromDatabaseTime(scheduleStartTime) > getCurrentDateTime()) {
                        allowed = false;
                    }
                    if (scheduled && getLocalTimeFromDatabaseTime(scheduleEndTime) < getCurrentDateTime()) {
                        allowed = false;
                    }
                }
                if (allowed) {
                    products.push({ ...doc.data(), url: getProductURL(globalState, doc.data().id), linked: false });
                }
            }
        });
        setState({ type: 'fetchProducts', products });
    } catch (error) {
        sendErrorAnalytics(FETCH_PRODUCTS_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_PRODUCTS_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchProductAPI = async (teamId: string, productId: string, setState: Function, setGlobalState: Function, snackbar: Function) => {
    try {
        const doc = await fetchProductFirestoreAPI(productId);
        if (doc.exists && doc.data()?.teamId === teamId && !doc.data()?.deleted && !doc.data()?.archived) {
            setState({ type: 'fetchProduct',
                id: doc.data()?.id,
                title: doc.data()?.title,
                price: doc.data()?.price,
                description: doc.data()?.shortDescription,
                ribbons: doc.data()?.ribbons,
                images: doc.data()?.images,
                inputs: doc.data()?.inputs,
                options: doc.data()?.options,
                shippable: doc.data()?.shippable,
                shippingThroughOptimX: isValidString(doc.data()?.shippingThroughOptimX) ? doc.data()?.shippingThroughOptimX : 'none',
                shippingPrice: isValidObject(doc.data()?.shippingPrice) ? doc.data()?.shippingPrice : 0,
                shippingWeight: doc.data()?.shippingWeight,
                shippingPolicy: isValidObject(doc.data()?.shippingPolicy) ? doc.data()?.shippingPolicy : '-1',
                returnPolicy: isValidObject(doc.data()?.returnPolicy) ? doc.data()?.returnPolicy : '-1',
                total: doc.data()?.productsTotal,
                sold: doc.data()?.productsSold,
                hold: doc.data()?.productsHold,
                stripePriceId: doc.data()?.stripePriceId,
                inStock: doc.data()?.inStock,
                deleted: doc.data()?.deleted,
                archived: doc.data()?.archived,
                published: doc.data()?.published,
            });
        } else {
            sendErrorAnalytics(PRODUCT_DOES_NOT_EXIST);
            if (optimXTesting) {
                snackbar(PRODUCT_DOES_NOT_EXIST, { variant: 'error' });
            }
            removeProductFromCartUsingProductId(productId, setGlobalState);
        }
    } catch (error) {
        sendErrorAnalytics(FETCH_PRODUCT_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_PRODUCT_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchProductTitleAPI = async (teamId: string, productId: string, setState: Function, snackbar: Function) => {
    try {
        const doc = await fetchProductFirestoreAPI(productId);
        if (doc.exists && doc.data()?.teamId === teamId && !doc.data()?.deleted && !doc.data()?.archived) {
            setState({ type: 'fetchProductTitle',
                title: doc.data()?.title,
            });
        } else if (optimXTesting) {
            snackbar(PRODUCT_DOES_NOT_EXIST, { variant: 'error' });
        } else {
            sendErrorAnalytics(PRODUCT_DOES_NOT_EXIST);
        }
    } catch (error) {
        sendErrorAnalytics(FETCH_PRODUCT_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_PRODUCT_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};
