import { fetchCoachesFirestoreAPI, fetchCoachFirestoreAPI } from '../firebase/firebaseAPICalls/FirebaseAPICallsCoach';
import { fetchLeagueFirestoreAPI, fetchLeaguesFirestoreAPI } from '../firebase/firebaseAPICalls/FirebaseAPICallsLeague';
import { fetchOpponentFirestoreAPI, fetchOpponentsFirestoreAPI } from '../firebase/firebaseAPICalls/FirebaseAPICallsOpponent';
import { fetchPlayerFirestoreAPI, fetchPlayersFirestoreAPI } from '../firebase/firebaseAPICalls/FirebaseAPICallsPlayer';
import { fetchPreDevelopedConferenceFirestoreAPI, fetchPreDevelopedLeagueFirestoreAPI } from '../firebase/firebaseAPICalls/FirebaseAPICallsPreDevelopedLeague';
import { TagType } from './enums/TagType';
import { ERROR_FETCHING_TAGS } from './NotificationsComments';
import { getPlayerInfo } from './Website';

export interface LocalTag {
    id: string,
    reference: any,
    tagType: TagType,
    value: string,
    label: string,
    url: string,
    deleted: boolean,
}

export const createPlayerTagLabel = (player: any, deleted: boolean): string => {
    if (deleted) {
        return '-|--|-';
    }

    return `${getPlayerInfo(player.details, 'Team Number')}-|-${player.name}-|-${getPlayerInfo(player.details, 'Position')}`;
};
export const createCoachTagLabel = (coach: any, deleted: boolean): string => {
    if (deleted) {
        return '-|--|-';
    }

    const getCoachInfo = (infoTitle: string) => {
        let finalDetail = '';
        coach.details.forEach((detail: any) => {
            if (detail.title === infoTitle) {
                finalDetail = detail.value;
            }
        });
        return finalDetail;
    };

    return `${coach.name}-|-${getCoachInfo('Title')}`;
};
export const createLeagueTagLabel = (leagueName: string, conferenceName: string, deleted: boolean): string => {
    if (deleted) {
        return `League - ${leagueName} | Conference - ${conferenceName}(Deleted)`;
    }
    return `League - ${leagueName} | Conference - ${conferenceName}`;
};
export const createOpponentTagLabel = (name: string, deleted: boolean): string => {
    if (deleted) {
        return `Opponent - ${name}(Deleted)`;
    }
    return `Opponent - ${name}`;
};
export const createUnkownTagLabel = (): string => {
    return 'Unkown';
};
export const createUnkownLeagueTagLabel = (): string => {
    return 'League - Unkown | Conference - Unknown';
};

export const createPlayerTagURL = (id: string, deleted: boolean, alumni: boolean): string => {
    if (deleted) {
        return '';
    }
    if (alumni) {
        return `/roster/${id}`;
    }
    return `/roster/${id}`;
};
export const createCoachTagURL = (id: string, deleted: boolean): string => {
    if (deleted) {
        return '';
    }
    return `/coaches/${id}`;
};
export const createLeagueTagURL = (id: string, deleted: boolean): string => {
    if (deleted) {
        return '';
    }
    return `/leagues/${id}`;
};
export const createOpponentTagURL = (id: string, deleted: boolean): string => {
    if (deleted) {
        return '';
    }
    return `/opponents/${id}`;
};

export const fetchTagOptionsAPI = async (teamId: string, setState: Function, snackbar: Function) => {
    const playerTags = Array<LocalTag>(0);
    const coachTags = Array<LocalTag>(0);
    const opponentTags = Array<LocalTag>(0);
    const leagueTags = Array<LocalTag>(0);

    try {
        const players = await fetchPlayersFirestoreAPI(teamId);
        if (players !== null && players !== undefined) {
            players.forEach((player) => {
                if (player.exists) {
                    playerTags.push({
                        id: player.data().id,
                        reference: player.data().reference,
                        tagType: TagType.PLAYER,
                        value: player.data().id,
                        label: createPlayerTagLabel(player.data(), player.data().deleted),
                        url: createPlayerTagURL(player.data().id, player.data().deleted, player.data().alumni),
                        deleted: player.data().deleted,
                    });
                }
            });
        }

        const coaches = await fetchCoachesFirestoreAPI(teamId);
        if (coaches !== null && coaches !== undefined) {
            coaches.forEach((coach) => {
                if (coach.exists) {
                    coachTags.push({
                        id: coach.data().id,
                        reference: coach.data().reference,
                        tagType: TagType.COACH,
                        value: coach.data().id,
                        label: createCoachTagLabel(coach.data(), coach.data().deleted),
                        url: createCoachTagURL(coach.data().id, coach.data().deleted),
                        deleted: coach.data().deleted,
                    });
                }
            });
        }

        const opponents = await fetchOpponentsFirestoreAPI(teamId);
        if (opponents !== null && opponents !== undefined) {
            opponents.forEach((opponent) => {
                if (opponent.exists) {
                    opponentTags.push({
                        id: opponent.data().id,
                        reference: opponent.data().reference,
                        tagType: TagType.OPPONENT,
                        value: opponent.data().id,
                        label: createOpponentTagLabel(opponent.data().name, opponent.data().deleted),
                        url: createOpponentTagURL(opponent.data().id, opponent.data().deleted),
                        deleted: opponent.data().deleted,
                    });
                }
            });
        }

        const leaguesTemp: any = [];
        const leagues = await fetchLeaguesFirestoreAPI(teamId);
        if (leagues !== null && leagues !== undefined) {
            leagues.forEach((league) => {
                if (league.exists) {
                    leaguesTemp.push({ ...league.data() });
                }
            });
            await Promise.all(leaguesTemp.map(async (league: any) => {
                const preDevelopedLeagueDoc = await fetchPreDevelopedLeagueFirestoreAPI(league.preDevelopedLeagueId);
                const preDevelopedConferenceDoc = await fetchPreDevelopedConferenceFirestoreAPI(league.preDevelopedLeagueId, league.preDevelopedConferenceId);
                if (preDevelopedLeagueDoc.exists && preDevelopedConferenceDoc.exists) {
                    leagueTags.push({
                        id: league.id,
                        reference: league.reference,
                        tagType: TagType.LEAGUE,
                        value: league.id,
                        label: createLeagueTagLabel(preDevelopedLeagueDoc.data()?.name, preDevelopedConferenceDoc.data()?.name, league.deleted),
                        url: createLeagueTagURL(league.id, league.deleted),
                        deleted: league.deleted,
                    });
                }
            }));
        }

        setState({ type: 'fetchTagOptions', playerTagOptions: playerTags, coachTagOptions: coachTags, opponentTagOptions: opponentTags, leagueTagOptions: leagueTags });
    } catch (error) {
        snackbar(ERROR_FETCHING_TAGS, { variant: 'error' });
    }
};

export const fetchTagOptionsTogetherAPI = async (teamId: string, setState: Function, snackbar: Function) => {
    const tags = Array<LocalTag>(0);

    try {
        const players = await fetchPlayersFirestoreAPI(teamId);
        if (players !== null) {
            players.forEach((player) => {
                if (player.exists) {
                    tags.push({
                        id: player.data().id,
                        reference: player.data().reference,
                        tagType: TagType.PLAYER,
                        value: player.data().id,
                        label: createPlayerTagLabel(player.data(), player.data().deleted),
                        url: createPlayerTagURL(player.data().id, player.data().deleted, player.data().alumni),
                        deleted: player.data().deleted,
                    });
                }
            });
        }

        const coaches = await fetchCoachesFirestoreAPI(teamId);
        if (coaches !== null) {
            coaches.forEach((coach) => {
                if (coach.exists) {
                    tags.push({
                        id: coach.data().id,
                        reference: coach.data().reference,
                        tagType: TagType.COACH,
                        value: coach.data().id,
                        label: createCoachTagLabel(coach.data(), coach.data().deleted),
                        url: createCoachTagURL(coach.data().id, coach.data().deleted),
                        deleted: coach.data().deleted,
                    });
                }
            });
        }

        const opponents = await fetchOpponentsFirestoreAPI(teamId);
        if (opponents !== null) {
            opponents.forEach((opponent) => {
                if (opponent.exists) {
                    tags.push({
                        id: opponent.data().id,
                        reference: opponent.data().reference,
                        tagType: TagType.OPPONENT,
                        value: opponent.data().id,
                        label: createOpponentTagLabel(opponent.data().name, opponent.data().deleted),
                        url: createOpponentTagURL(opponent.data().id, opponent.data().deleted),
                        deleted: opponent.data().deleted,
                    });
                }
            });
        }

        const leaguesTemp: any = [];
        const leagues = await fetchLeaguesFirestoreAPI(teamId);
        if (leagues !== null) {
            leagues.forEach((league) => {
                if (league.exists) {
                    leaguesTemp.push({ ...league.data() });
                }
            });
            await Promise.all(leaguesTemp.map(async (league: any) => {
                const preDevelopedLeagueDoc = await fetchPreDevelopedLeagueFirestoreAPI(league.preDevelopedLeagueId);
                const preDevelopedConferenceDoc = await fetchPreDevelopedConferenceFirestoreAPI(league.preDevelopedLeagueId, league.preDevelopedConferenceId);
                if (preDevelopedLeagueDoc.exists && preDevelopedConferenceDoc.exists) {
                    tags.push({
                        id: league.id,
                        reference: league.reference,
                        tagType: TagType.LEAGUE,
                        value: league.id,
                        label: createLeagueTagLabel(preDevelopedLeagueDoc.data()?.name, preDevelopedConferenceDoc.data()?.name, league.deleted),
                        url: createLeagueTagURL(league.id, league.deleted),
                        deleted: league.deleted,
                    });
                }
            }));
        }

        setState({ type: 'fetchTagOptions', tagOptions: tags });
    } catch (error) {
        snackbar(ERROR_FETCHING_TAGS, { variant: 'error' });
    }
};

/**
 * ##############################################################################################
 * For conerting tags from database to web
 * ##############################################################################################
 */

/**
 * This function is used to convert a tag on database to a tag that can be used by the web app
 *
 * @param tagId
 * @param tagReference
 * @param tagType
 * @param teamId
 */
export const databaseTagToLocalTag = async (tagId: string, tagReference: string, tagType: TagType, teamId: string): Promise<LocalTag> => {
    const tempTagOption = { id: tagId, reference: tagReference, tagType, value: tagId };
    if (tagType === TagType.PLAYER) {
        const doc = await fetchPlayerFirestoreAPI(tagId);
        if (doc.exists && doc.data()?.teamId === teamId) {
            return { ...tempTagOption,
                label: createPlayerTagLabel(doc.data(), doc.data()?.deleted),
                url: createPlayerTagURL(doc.data()?.id, doc.data()?.deleted, doc.data()?.alumni),
                deleted: doc.data()?.deleted,
            };
        }
        return {
            ...tempTagOption,
            label: createUnkownTagLabel(),
            url: '',
            deleted: true,
        };
    } if (tagType === TagType.COACH) {
        const doc = await fetchCoachFirestoreAPI(tagId);
        if (doc.exists && doc.data()?.teamId === teamId) {
            return {
                ...tempTagOption,
                label: createCoachTagLabel(doc.data(), doc.data()?.deleted),
                url: createCoachTagURL(doc.data()?.id, doc.data()?.deleted),
                deleted: doc.data()?.deleted,
            };
        }
        return {
            ...tempTagOption,
            label: createUnkownTagLabel(),
            url: '',
            deleted: true,
        };
    } if (tagType === TagType.OPPONENT) {
        const doc = await fetchOpponentFirestoreAPI(teamId, tagId);
        if (doc.exists && doc.data()?.teamId === teamId) {
            return {
                ...tempTagOption,
                label: createOpponentTagLabel(doc.data()?.name, doc.data()?.deleted),
                url: createOpponentTagURL(doc.data()?.id, doc.data()?.deleted),
                deleted: doc.data()?.deleted,
            };
        }
        return {
            ...tempTagOption,
            label: createUnkownTagLabel(),
            url: '',
            deleted: true,
        };
    } if (tagType === TagType.LEAGUE) {
        const doc = await fetchLeagueFirestoreAPI(teamId, tagId);
        if (doc.exists && doc.data()?.teamId === teamId) {
            const preDevelopedLeagueDoc = await fetchPreDevelopedLeagueFirestoreAPI(doc.data()?.preDevelopedLeagueId);
            const preDevelopedConferenceDoc = await fetchPreDevelopedConferenceFirestoreAPI(doc.data()?.preDevelopedLeagueId, doc.data()?.preDevelopedConferenceId);
            return {
                ...tempTagOption,
                label: createLeagueTagLabel(preDevelopedLeagueDoc.data()?.name, preDevelopedConferenceDoc.data()?.name, doc.data()?.deleted),
                url: createLeagueTagURL(doc.data()?.id, doc.data()?.deleted),
                deleted: doc.data()?.deleted,
            };
        }
        return {
            ...tempTagOption,
            label: createUnkownLeagueTagLabel(),
            url: '',
            deleted: true,
        };
    }
    return {
        id: tagId,
        reference: tagReference,
        tagType: TagType.NONE,
        value: tagId,
        label: createUnkownTagLabel(),
        url: '',
        deleted: true,
    };
};
/**
 * The function is used to convert array of database tags to local tags
 *
 * @param tagIds
 * @param tagReferences
 * @param tagTypes
 * @param teamId
 */
export const databaseTagsToLocalTags = async (tagIds: string[], tagReferences: string[], tagTypes: TagType[], teamId: string): Promise<LocalTag[]> => {
    const finalTags = Array<LocalTag>(0);
    await (Promise.all(tagIds.map(async (id, index) => {
        const localTag = await databaseTagToLocalTag(id, tagReferences[index], tagTypes[index], teamId);
        if (localTag.tagType !== TagType.NONE) {
            finalTags.push(localTag);
        }
    })));
    return finalTags;
};
/**
 * From an array of local tags, get only one type of tag like player tag etc.
 *
 * @param tags
 * @param tagType
 */
export const getOnlyOneTypeOfLocalTags = (tags: LocalTag[], tagType: TagType) => {
    return tags.filter((tag) => {
        return (tag.tagType === tagType);
    });
};
