/**
 * This function creates random alpha numeric string for keys in maps and ids of items
 */
import uniqid from 'uniqid';

export const demoWebsiteId = 'website';
export const demoSocialsId = 'socials';
export const optimXAdminInfoId = 'optimx-admin-info';
export const subscriptionInfoId = 'subscription-info';
export const storeInfoId = 'store-info';
export const demoMyLibraryGalleryId = (teamId: string) => {
    return `${teamId}-mylibrary`;
};

export const randomNumber = (): string => {
    // return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    return uniqid();
};

export const isObjectVisibleAfterSearch = (titleOfObject: string, searchField: string): boolean => {
    if (titleOfObject === null || titleOfObject === undefined || titleOfObject.length === 0) { return false; }
    if (searchField.length <= 0) { return true; }
    if (titleOfObject.toLowerCase().startsWith(searchField.toLowerCase())) { return true; }
    let titleParts = titleOfObject.split(' ');
    for (let i = 0; i < titleParts.length; i += 1) {
        if (titleParts[i] && titleParts[i].toLowerCase().startsWith(searchField.toLowerCase())) {
            return true;
        }
    }
    titleParts = titleOfObject.replace(/[-()!:]/g, ' ').split(' ');
    for (let i = 0; i < titleParts.length; i += 1) {
        if (titleParts[i].length > 0 && titleParts[i] && titleParts[i].toLowerCase().startsWith(searchField.toLowerCase())) {
            return true;
        }
    }
    return false;
};
