export const websiteVersion = '4.11.0';

export const websiteVersionChanges = [
    '0.0.0 - Website is ready',
    '1.0.0 - Multiple sports are now supported',
    '2.0.0 - New structure of TeamObject is now supported',
    '3.0.0 - Store supported added',
    '4.0.0 - New designs added',
    '4.8.1 - Fees calculation now includes tiers',
    '4.8.2 - Cart and all totals now show subtotal and platform fee',
    '4.8.3 - Shipping fee added under products',
    '4.8.4 - Twitter logo changed in footer',
    '4.8.5 - Shipping fee added under cart',
    '4.8.6 - Shipping fee added in browser local storage and global state',
    '4.8.7 - Shipping fee sent to checkout cloud function',
    '4.8.8 - Bug fix where in cart, shipping price was not added under stripePrices',
    '4.8.9 - Subtotal, platform fee and shipping fee removed from each cart item in cart because the calculations were not the same',
    '4.8.10 - Parameter added for showing platform fee and by default is is not shown. Shipping is still shown',
    '4.8.11 - Product inputs had key as random numbers which was causing issues. Fixed',
    '4.8.12 - Parameter added for shipping platform fee and by default is is not shown',
    '4.8.13 - Product thumbnails also show shipping price now along with unit price',
    '4.8.14 - On homepagse, if the team does not have roster then roster is not shown',
    '4.9.0 - Support for additional recaptchas added',
    '4.10.0 - Banner text update is now supported',
    '4.11.0 - Tiktok buttons are now supported',
];
