/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { context } from '../../../globalState/GlobalState';
import { accountAPICalls } from '../../apiCalls/AccountAPICalls';
import { optimXStoreWebsite, optimXTesting, optimXTestWebsite, setWebsiteTeamId, websiteTeamId } from '../../../catalog/TeamInfo';
import { isValidObject, isValidString } from '../../../catalog/Validity';
import { fetchOptimXAdminInfoAPI, fetchShowAdsAPI } from '../../apiCalls/OptimXAdminAPICalls';
import { fetchSubscriptionInfoAPI, fetchSubscriptionStatusAPI } from '../../apiCalls/SubscriptionAPICalls';
import { checkWebsiteActivation } from '../../../catalog/Activation';
import Inactive1 from '../../designs/inactive1/Inactive1';
import { fetchStoreAdminInfoAPI } from '../../apiCalls/StoreAdminAPICalls';
import { checkLinkedProductsExistAPI } from '../../apiCalls/ProductAPICalls';

const initialState = {
    adminInfo: {},
    subscriptionInfo: {},
    websiteSubscriptionActive: false,
    domainSubscriptionActive: false,
    emailSubscriptionActive: false,
    error: false,
    loadingComplete: false,
};

function reducer(state, action) {
    switch (action.type) {
        case 'setAdminInfo':
            return { ...state, adminInfo: action.info };
        case 'setSubscriptionInfo':
            return { ...state, subscriptionInfo: action.info };
        case 'setSubscriptionActive':
            return { ...state,
                websiteSubscriptionActive: action.websiteSubscriptionActive,
                domainSubscriptionActive: action.domainSubscriptionActive,
                emailSubscriptionActive: action.emailSubscriptionActive,
            };
        case 'setError':
            return { ...state, error: true };
        case 'setLoadingComplete':
            return { ...state, loadingComplete: true };
        default:
            return initialState;
    }
}

const ActivationAndDesignCheck = (props) => {
    const { children } = props;
    const [state, setState] = useReducer(reducer, initialState);
    const history = useHistory();
    const { globalState, setGlobalState } = useContext(context);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (isValidObject(globalState.teamId)) {
            setTimeout(async () => {
                await fetchOptimXAdminInfoAPI(globalState.teamId, setState, enqueueSnackbar);
                await fetchSubscriptionInfoAPI(globalState.teamId, setState, enqueueSnackbar);
                await fetchSubscriptionStatusAPI(globalState.teamId, setState, enqueueSnackbar);
                setState({ type: 'setLoadingComplete' });
                // eslint-disable-next-line no-console
                console.log('Activation check complete');
            }, 3000);
            fetchStoreAdminInfoAPI(globalState.teamId, setGlobalState, enqueueSnackbar);
        }
    }, [globalState.teamId]);

    const checkWebsiteActivationLocal = () => {
        if (state.loadingComplete && isValidObject(globalState.licensingAgreement) && !optimXTestWebsite && !optimXTesting && !optimXStoreWebsite) {
            return checkWebsiteActivation(
                state.adminInfo,
                state.subscriptionInfo,
                state.websiteSubscriptionActive,
                state.domainSubscriptionActive,
                state.emailSubscriptionActive,
                state.error,
                state.loadingComplete,
                globalState.licensingAgreement,
                enqueueSnackbar,
            );
        }
        return true;
    };

    useEffect(() => {
        if (optimXTestWebsite) {
            const params = new URLSearchParams(history.location.search);
            const teamId = params.get('team');
            setWebsiteTeamId(teamId);
            accountAPICalls(teamId, history, setGlobalState, enqueueSnackbar);
            checkLinkedProductsExistAPI(teamId, setGlobalState, enqueueSnackbar);
            fetchShowAdsAPI(teamId, setGlobalState, enqueueSnackbar);
        } else {
            accountAPICalls(websiteTeamId, history, setGlobalState, enqueueSnackbar);
            checkLinkedProductsExistAPI(websiteTeamId, setGlobalState, enqueueSnackbar);
            fetchShowAdsAPI(websiteTeamId, setGlobalState, enqueueSnackbar);
        }
        // eslint-disable-next-line no-console
        console.log('Website Fetched');
    }, [history]);

    useEffect(() => {
        if (optimXTestWebsite) {
            const urlParams = new URLSearchParams(history.location.search);
            if (!isValidString(urlParams.get('team')) && isValidString(websiteTeamId)) {
                history.replace(`${history.location.pathname}?team=${websiteTeamId}`);
            }
        }

        return history.listen(() => {
            if (optimXTestWebsite) {
                const urlParams = new URLSearchParams(history.location.search);
                if (!isValidString(urlParams.get('team')) && isValidString(websiteTeamId)) {
                    history.replace(`${history.location.pathname}?team=${websiteTeamId}`);
                }
            }
        });
    }, [history]);

    const getInactivePageDesign = () => {
        if (!isValidObject(globalState) || !isValidObject(globalState.websiteStyles)) {
            return null;
        }

        return (
            <Inactive1 open={!checkWebsiteActivationLocal()} />
        );
    };

    return (
        <>
            {children}
            {getInactivePageDesign()}
        </>
    );
};

ActivationAndDesignCheck.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(ActivationAndDesignCheck);
