import { getSportsTypeName } from '../../../../catalog/enums/SportsType';
import { isValidObject, isValidString } from '../../../../catalog/Validity';

export const createHomePageTitleTag = (globalState: any) => {
    let tag = '';
    if (isValidObject(globalState)) {
        if (isValidObject(globalState.seoInfo) && isValidString(globalState.seoInfo.title)) {
            tag += globalState.seoInfo.title;
        } else {
            if (isValidString(getSportsTypeName(globalState.sportsType))) {
                tag += getSportsTypeName(globalState.sportsType);
            }
            if (isValidString(globalState.teamAlias)) {
                if (tag.length > 0) {
                    tag += ` - ${globalState.teamAlias}`;
                } else {
                    tag += globalState.teamAlias;
                }
            } else if (tag.length > 0) {
                tag += ` - ${globalState.teamName}`;
            } else {
                tag += globalState.teamName;
            }
        }
    }
    return tag;
};

export const createHomePageDescriptionTag = (globalState: any) => {
    let tag = '';

    if (isValidObject(globalState)) {
        if (isValidObject(globalState.seoInfo) && isValidString(globalState.seoInfo.description)) {
            tag += globalState.seoInfo.description;
        } else {
            if (isValidString(getSportsTypeName(globalState.sportsType))) {
                tag += getSportsTypeName(globalState.sportsType);
            }
            if (isValidString(globalState.teamAlias)) {
                if (tag.length > 0) {
                    tag += ` - ${globalState.teamAlias}`;
                } else {
                    tag += globalState.teamAlias;
                }
            } else if (tag.length > 0) {
                tag += ` - ${globalState.teamName}`;
            } else {
                tag += globalState.teamName;
            }
        }
    }

    return tag;
};

export const createHomePageKeywordsTag = (globalState: any) => {
    let tag = '';
    if (isValidObject(globalState)) {
        if (isValidString(getSportsTypeName(globalState.sportsType))) {
            tag += getSportsTypeName(globalState.sportsType);
        }
        if (isValidString(globalState.teamAlias)) {
            if (tag.length > 0) {
                tag += `, ${globalState.teamAlias}`;
            } else {
                tag += globalState.teamAlias;
            }
        }
        if (isValidString(globalState.teamName)) {
            if (tag.length > 0) {
                tag += `, ${globalState.teamName}`;
            } else {
                tag += globalState.teamName;
            }
        }
        if (isValidString(globalState.organizationName)) {
            if (tag.length > 0) {
                tag += `, ${globalState.organizationName}`;
            } else {
                tag += globalState.organizationName;
            }
        }
        if (isValidObject(globalState.seoInfo) && isValidString(globalState.seoInfo.keywords)) {
            if (tag.length > 0) {
                tag += `, ${globalState.seoInfo.keywords}`;
            } else {
                tag += globalState.seoInfo.keywords;
            }
        }
    }
    return tag;
};

export const createCustomTitleTag = (title: string, globalState: any) => {
    let tag = '';
    if (isValidString(title)) {
        tag += title;
    }
    if (isValidObject(globalState)) {
        if (isValidObject(globalState.seoInfo) && isValidString(globalState.seoInfo.title)) {
            if (tag.length > 0) {
                tag += ` - ${globalState.seoInfo.title}`;
            } else {
                tag += globalState.seoInfo.title;
            }
        } else {
            if (isValidString(getSportsTypeName(globalState.sportsType))) {
                if (tag.length > 0) {
                    tag += ` - ${getSportsTypeName(globalState.sportsType)}`;
                } else {
                    tag += getSportsTypeName(globalState.sportsType);
                }
            }
            if (isValidString(globalState.teamAlias)) {
                if (tag.length > 0) {
                    tag += ` - ${globalState.teamAlias}`;
                } else {
                    tag += globalState.teamAlias;
                }
            } else if (tag.length > 0) {
                tag += ` - ${globalState.teamName}`;
            } else {
                tag += globalState.teamName;
            }
        }
    }

    return tag;
};

export const createCustomDescriptionTag = (title: string, globalState: any) => {
    let tag = '';
    if (isValidString(title)) {
        tag += title;
    }
    if (isValidObject(globalState)) {
        if (isValidObject(globalState.seoInfo) && isValidString(globalState.seoInfo.description)) {
            if (tag.length > 0) {
                tag += ` - ${globalState.seoInfo.description}`;
            } else {
                tag += globalState.seoInfo.description;
            }
        } else {
            if (isValidString(getSportsTypeName(globalState.sportsType))) {
                if (tag.length > 0) {
                    tag += ` - ${getSportsTypeName(globalState.sportsType)}`;
                } else {
                    tag += getSportsTypeName(globalState.sportsType);
                }
            }
            if (isValidString(globalState.teamAlias)) {
                if (tag.length > 0) {
                    tag += ` - ${globalState.teamAlias}`;
                } else {
                    tag += globalState.teamAlias;
                }
            } else if (tag.length > 0) {
                tag += ` - ${globalState.teamName}`;
            } else {
                tag += globalState.teamName;
            }
        }
    }

    return tag;
};

export const createCustomKeywordsTag = (title: string, globalState: any) => {
    let tag = '';
    if (isValidString(title)) {
        tag += title;
    }
    if (isValidObject(globalState)) {
        if (isValidString(getSportsTypeName(globalState.sportsType))) {
            if (tag.length > 0) {
                tag += `, ${getSportsTypeName(globalState.sportsType)}`;
            } else {
                tag += getSportsTypeName(globalState.sportsType);
            }
        }
        if (isValidString(globalState.teamAlias)) {
            if (tag.length > 0) {
                tag += `, ${globalState.teamAlias}`;
            } else {
                tag += globalState.teamAlias;
            }
        }
        if (isValidString(globalState.teamName)) {
            if (tag.length > 0) {
                tag += `, ${globalState.teamName}`;
            } else {
                tag += globalState.teamName;
            }
        }
        if (isValidString(globalState.organizationName)) {
            if (tag.length > 0) {
                tag += `, ${globalState.organizationName}`;
            } else {
                tag += globalState.organizationName;
            }
        }
        if (isValidObject(globalState.seoInfo) && isValidString(globalState.seoInfo.keywords)) {
            if (tag.length > 0) {
                tag += `, ${globalState.seoInfo.keywords}`;
            } else {
                tag += globalState.seoInfo.keywords;
            }
        }
    }
    return tag;
};
