import React, { memo, useContext, useRef, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { context } from '../../../../globalState/GlobalState';
import { isValidObject, isValidString } from '../../../../catalog/Validity';
import NavItemsDesktopWithDropDownWithDropDown from './NavItemsDesktopWithDropDownWithDropDown';
import { getNavLinkTargetValue } from '../../../../catalog/Website';

const NavItemsDesktopWithDropDown = (props) => {
    const { options, menuOpen, setMenuOpen, anchorRef } = props;
    const { globalState } = useContext(context);

    /**
     * This function is called when close is called on dishes and categories
     */
    function handleClose(event) {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setMenuOpen(false);
    }

    const styles = StyleSheet.create({
        menulist: {
            borderBottomStyle: 'solid',
            borderBottomWidth: '4px',
            borderBottomColor: globalState.websiteStyles.colors.primaryColor,
        },
        menuitem: {
            ':hover': {
                backgroundColor: globalState.websiteStyles.colors.primaryColor,
                color: isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white',
                transitionDuration: '0.2s',
            },
        },
    });

    const state = options.map((item) => {
        if (isValidObject(item)) {
            return useState(false);
        }
        return null;
    });
    const anchorRefs = options.map((item) => {
        if (isValidObject(item)) {
            return useRef(null);
        }
        return null;
    });

    const getNavItem = (option, index) => {
        if (option.value === '/options') {
            return (
                <div
                    className="text-no-underline"
                    onMouseOver={() => { state[index][1](() => { return true; }); }}
                    onFocus={() => { state[index][1](() => { return true; }); }}
                    onMouseLeave={() => { state[index][1](() => { return false; }); }}
                    type="button"
                    ref={anchorRefs[index]}
                >
                    <MenuItem className={`nav-item-with-drop-down p-t-10px p-b-10px p-l-25px p-r-25px ${css(styles.menuitem)}`} onClick={handleClose}>
                        {option.title}
                        <ArrowRight style={{ fontSize: '20px' }} />
                    </MenuItem>
                    <NavItemsDesktopWithDropDownWithDropDown options={option.options} menuOpen={state[index][0]} setMenuOpen={state[index][1]} anchorRef={anchorRefs[index]} />
                </div>
            );
        } if (option.value === '/custom') {
            return (
                <a
                    className="text-no-underline"
                    href={option.link}
                    target={getNavLinkTargetValue(option.openNewTab, false)}
                    rel="noreferrer noopener"
                >
                    <MenuItem className={`nav-item-with-drop-down p-t-10px p-b-10px p-l-25px p-r-25px ${css(styles.menuitem)}`} onClick={handleClose}>{option.title}</MenuItem>
                </a>
            );
        } if (option.value === '/custompage' || option.value === '/custompages') {
            return (
                <NavLink
                    style={{ textDecoration: 'none', color: 'black' }}
                    to={`/pages/${option.link}`}
                    exact
                    target={getNavLinkTargetValue(option.openNewTab, true)}
                >
                    <MenuItem className={`nav-item-with-drop-down p-t-10px p-b-10px p-l-25px p-r-25px ${css(styles.menuitem)}`} onClick={handleClose}>{option.title}</MenuItem>
                </NavLink>
            );
        } if (option.value === '/forms') {
            return (
                <NavLink
                    style={{ textDecoration: 'none', color: 'black' }}
                    to={`/forms/${option.link}`}
                    exact
                    target={getNavLinkTargetValue(option.openNewTab, true)}
                >
                    <MenuItem className={`nav-item-with-drop-down p-t-10px p-b-10px p-l-25px p-r-25px ${css(styles.menuitem)}`} onClick={handleClose}>{option.title}</MenuItem>
                </NavLink>
            );
        }
        return (
            <NavLink
                style={{ textDecoration: 'none', color: 'black' }}
                to={option.link}
                exact
                target={getNavLinkTargetValue(option.openNewTab, true)}
            >
                <MenuItem className={`nav-item-with-drop-down p-t-10px p-b-10px p-l-25px p-r-25px ${css(styles.menuitem)}`} onClick={handleClose}>{option.title}</MenuItem>
            </NavLink>
        );
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Popper open={menuOpen} anchorEl={anchorRef.current} transition disablePortal placement="bottom-start">
                {({ TransitionProps, placement }) => {
                    return (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper className="curve-border-0">
                                <MenuList className={`p-t-0 p-b-0 ${css(styles.menulist)}`}>
                                    {options.map((option, index) => {
                                        return (
                                            <div key={option.title}>
                                                {getNavItem(option, index)}
                                            </div>
                                        );
                                    })}
                                </MenuList>
                            </Paper>
                        </Grow>
                    );
                }}
            </Popper>
        </ClickAwayListener>
    );
};

NavItemsDesktopWithDropDown.propTypes = {
    options: PropTypes.array.isRequired,
    menuOpen: PropTypes.bool.isRequired,
    setMenuOpen: PropTypes.func.isRequired,
    anchorRef: PropTypes.object.isRequired,
};

export default (memo(NavItemsDesktopWithDropDown));
