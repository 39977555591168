/* eslint-disable no-console */
import { createAnalyticsId } from '../../catalog/Analytics';
import { getCurrentDateTime } from '../../catalog/DateTime';
import { FormType } from '../../catalog/enums/FormType';
import { SportsType } from '../../catalog/enums/SportsType';
import { AnalyticsObject } from '../../catalog/interface/AnalyticsInterface';
import { isValidObject } from '../../catalog/Validity';
import { db } from '../FirebaseLibrary';
import { createOrganizationReference } from './FirebaseAPICallsOrganization';
import { createTeamReference } from './FirebaseAPICallsTeam';

export const createAnalyticsReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/team-analytics`);
};

export const createAnalyticReference = (teamId: string) => {
    return createAnalyticsReference(teamId).doc(createAnalyticsId(teamId));
};

export const insertAnalyticFirestoreAPI = (teamId: string, analyticsObject: AnalyticsObject) => {
    return createAnalyticReference(teamId).set(analyticsObject);
};

// NOTE: This function is not used anymore. The functionality of this function has been combined with other functions below and with variable in Analtics.ts
// NOTE: The issue that this function was causing is that documents should not be updated twice in one second. However, this function was always
// called with another function below which used to cause the issue. Now only one update to document is sent
export const updateAnalyticsWebsiteFirestoreAPI = (organizationId: string, teamId: string, sportsType: SportsType) => {
    const analyticsId = createAnalyticsId(teamId);
    const analyticsReference = createAnalyticReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);

    console.log('Important Notice: OptimX Analytics website is being called. Make sure this function is not called anymore');
    return db.runTransaction(async (transaction) => {
        const analyticsDoc = await transaction.get(analyticsReference);

        if (analyticsDoc.exists) {
            const analytics: any = analyticsDoc.data();
            return transaction.update(analyticsReference, { totalViews: analytics.totalViews + 1, timeModified: getCurrentDateTime() });
        }
        const newAnalytics: AnalyticsObject = {
            id: analyticsId,
            reference: analyticsReference,
            teamId,
            teamReference,
            organizationId,
            organizationReference,
            totalViews: 1,
            homePageViews: 0,
            webPageViews: {},
            playerViews: {},
            coachViews: {},
            articleViews: {},
            galleryViews: {},
            eventViews: {},
            gameViews: {},
            productViews: {},
            formsSubmitted: {},
            sportsType,
            timeOfAnalytics: getCurrentDateTime(),
            timeModified: getCurrentDateTime(),
            timeCreated: getCurrentDateTime(),
        };
        return transaction.set(analyticsReference, newAnalytics);
    });
};

export const updateAnalyticsHomePageFirestoreAPI = (organizationId: string, teamId: string, sportsType: SportsType, isSendingWebsiteAnalytics: boolean) => {
    const analyticsId = createAnalyticsId(teamId);
    const analyticsReference = createAnalyticReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);

    return db.runTransaction(async (transaction) => {
        const analyticsDoc = await transaction.get(analyticsReference);

        if (analyticsDoc.exists) {
            const analytics: any = analyticsDoc.data();
            if (isSendingWebsiteAnalytics) {
                console.log('OptimX Analytics updated for website and homepage');
                return transaction.update(analyticsReference, { totalViews: analytics.totalViews + 1, homePageViews: analytics.homePageViews + 1, timeModified: getCurrentDateTime() });
            }
            console.log('OptimX Analytics updated for homepage');
            return transaction.update(analyticsReference, { homePageViews: analytics.homePageViews + 1, timeModified: getCurrentDateTime() });
        }

        const newAnalytics: AnalyticsObject = {
            id: analyticsId,
            reference: analyticsReference,
            teamId,
            teamReference,
            organizationId,
            organizationReference,
            totalViews: 0,
            homePageViews: 1,
            webPageViews: {},
            playerViews: {},
            coachViews: {},
            articleViews: {},
            galleryViews: {},
            eventViews: {},
            gameViews: {},
            productViews: {},
            formsSubmitted: {},
            sportsType,
            timeOfAnalytics: getCurrentDateTime(),
            timeModified: getCurrentDateTime(),
            timeCreated: getCurrentDateTime(),
        };

        if (isSendingWebsiteAnalytics) {
            console.log('OptimX Analytics created for website and homepage');
            return transaction.set(analyticsReference, { ...newAnalytics, totalViews: 1 });
        }

        console.log('OptimX Analytics created for homepage');
        return transaction.set(analyticsReference, newAnalytics);
    });
};

export const updateAnalyticsWebPageFirestoreAPI = (organizationId: string, teamId: string, webPageTitle: string, sportsType: SportsType, isSendingWebsiteAnalytics: boolean) => {
    const analyticsId = createAnalyticsId(teamId);
    const analyticsReference = createAnalyticReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);

    return db.runTransaction(async (transaction) => {
        const analyticsDoc = await transaction.get(analyticsReference);

        if (analyticsDoc.exists) {
            const analytics: any = analyticsDoc.data();
            const { webPageViews } = analytics;
            const webPageView = webPageViews[webPageTitle];
            let newWebPageViews = { ...webPageViews };

            if (isValidObject(webPageView)) {
                newWebPageViews = { ...webPageViews, [webPageTitle]: webPageView + 1 };
            } else {
                newWebPageViews = { ...webPageViews, [webPageTitle]: 1 };
            }

            if (isSendingWebsiteAnalytics) {
                console.log(`OptimX Analytics updated for website and webpage: ${webPageTitle}`);
                return transaction.update(analyticsReference, { totalViews: analytics.totalViews + 1, webPageViews: newWebPageViews, timeModified: getCurrentDateTime() });
            }
            console.log(`OptimX Analytics updated for webpage: ${webPageTitle}`);
            return transaction.update(analyticsReference, { webPageViews: newWebPageViews, timeModified: getCurrentDateTime() });
        }

        const newAnalytics: AnalyticsObject = {
            id: analyticsId,
            reference: analyticsReference,
            teamId,
            teamReference,
            organizationId,
            organizationReference,
            totalViews: 0,
            homePageViews: 0,
            webPageViews: { [webPageTitle]: 1 },
            playerViews: {},
            coachViews: {},
            articleViews: {},
            galleryViews: {},
            eventViews: {},
            gameViews: {},
            productViews: {},
            formsSubmitted: {},
            sportsType,
            timeOfAnalytics: getCurrentDateTime(),
            timeModified: getCurrentDateTime(),
            timeCreated: getCurrentDateTime(),
        };

        if (isSendingWebsiteAnalytics) {
            console.log(`OptimX Analytics created for website and webpage: ${webPageTitle}`);
            return transaction.set(analyticsReference, { ...newAnalytics, totalViews: 1 });
        }

        console.log(`OptimX Analytics created for webpage: ${webPageTitle}`);
        return transaction.set(analyticsReference, newAnalytics);
    });
};

export const updateAnalyticsPlayerFirestoreAPI = (organizationId: string, teamId: string, playerId: string, sportsType: SportsType, isSendingWebsiteAnalytics: boolean) => {
    const analyticsId = createAnalyticsId(teamId);
    const analyticsReference = createAnalyticReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);
    const webPageTitle = 'Player Profile';

    return db.runTransaction(async (transaction) => {
        const analyticsDoc = await transaction.get(analyticsReference);

        if (analyticsDoc.exists) {
            const analytics: any = analyticsDoc.data();
            const { webPageViews, playerViews } = analytics;
            const webPageView = webPageViews[webPageTitle];
            let newWebPageViews = { ...webPageViews };
            const playerView = playerViews[playerId];
            let newPlayerViews = { ...playerViews };

            if (isValidObject(webPageView)) {
                newWebPageViews = { ...webPageViews, [webPageTitle]: webPageView + 1 };
            } else {
                newWebPageViews = { ...webPageViews, [webPageTitle]: 1 };
            }

            if (isValidObject(playerView)) {
                newPlayerViews = { ...playerViews, [playerId]: playerView + 1 };
            } else {
                newPlayerViews = { ...playerViews, [playerId]: 1 };
            }

            if (isSendingWebsiteAnalytics) {
                console.log('OptimX Analytics updated for website, webpage and player profile');
                return transaction.update(analyticsReference, { totalViews: analytics.totalViews + 1, webPageViews: newWebPageViews, playerViews: newPlayerViews, timeModified: getCurrentDateTime() });
            }
            console.log('OptimX Analytics updated for webpage and player profile');
            return transaction.update(analyticsReference, { webPageViews: newWebPageViews, playerViews: newPlayerViews, timeModified: getCurrentDateTime() });
        }

        const newAnalytics: AnalyticsObject = {
            id: analyticsId,
            reference: analyticsReference,
            teamId,
            teamReference,
            organizationId,
            organizationReference,
            totalViews: 0,
            homePageViews: 0,
            webPageViews: { [webPageTitle]: 1 },
            playerViews: { [playerId]: 1 },
            coachViews: {},
            articleViews: {},
            galleryViews: {},
            eventViews: {},
            gameViews: {},
            productViews: {},
            formsSubmitted: {},
            sportsType,
            timeOfAnalytics: getCurrentDateTime(),
            timeModified: getCurrentDateTime(),
            timeCreated: getCurrentDateTime(),
        };

        if (isSendingWebsiteAnalytics) {
            console.log('OptimX Analytics created for website, webpage and player profile');
            return transaction.set(analyticsReference, { ...newAnalytics, totalViews: 1 });
        }

        console.log('OptimX Analytics created for webpage and player profile');
        return transaction.set(analyticsReference, newAnalytics);
    });
};

export const updateAnalyticsCoachFirestoreAPI = (organizationId: string, teamId: string, coachId: string, sportsType: SportsType, isSendingWebsiteAnalytics: boolean) => {
    const analyticsId = createAnalyticsId(teamId);
    const analyticsReference = createAnalyticReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);
    const webPageTitle = 'Coach Profile';

    return db.runTransaction(async (transaction) => {
        const analyticsDoc = await transaction.get(analyticsReference);

        if (analyticsDoc.exists) {
            const analytics: any = analyticsDoc.data();
            const { webPageViews, coachViews } = analytics;
            const webPageView = webPageViews[webPageTitle];
            let newWebPageViews = { ...webPageViews };
            const coachView = coachViews[coachId];
            let newCoachViews = { ...coachViews };

            if (isValidObject(webPageView)) {
                newWebPageViews = { ...webPageViews, [webPageTitle]: webPageView + 1 };
            } else {
                newWebPageViews = { ...webPageViews, [webPageTitle]: 1 };
            }

            if (isValidObject(coachView)) {
                newCoachViews = { ...coachViews, [coachId]: coachView + 1 };
            } else {
                newCoachViews = { ...coachViews, [coachId]: 1 };
            }

            if (isSendingWebsiteAnalytics) {
                console.log('OptimX Analytics updated for website, webpage and coach profile');
                return transaction.update(analyticsReference, { totalViews: analytics.totalViews + 1, webPageViews: newWebPageViews, coachViews: newCoachViews, timeModified: getCurrentDateTime() });
            }
            console.log('OptimX Analytics updated for webpage and coach profile');
            return transaction.update(analyticsReference, { webPageViews: newWebPageViews, coachViews: newCoachViews, timeModified: getCurrentDateTime() });
        }
        const newAnalytics: AnalyticsObject = {
            id: analyticsId,
            reference: analyticsReference,
            teamId,
            teamReference,
            organizationId,
            organizationReference,
            totalViews: 0,
            homePageViews: 0,
            webPageViews: { [webPageTitle]: 1 },
            playerViews: {},
            coachViews: { [coachId]: 1 },
            articleViews: {},
            galleryViews: {},
            eventViews: {},
            gameViews: {},
            productViews: {},
            formsSubmitted: {},
            sportsType,
            timeOfAnalytics: getCurrentDateTime(),
            timeModified: getCurrentDateTime(),
            timeCreated: getCurrentDateTime(),
        };

        if (isSendingWebsiteAnalytics) {
            console.log('OptimX Analytics created for website, webpage and coach profile');
            return transaction.set(analyticsReference, { ...newAnalytics, totalViews: 1 });
        }
        console.log('OptimX Analytics created for webpage and coach profile');
        return transaction.set(analyticsReference, newAnalytics);
    });
};

export const updateAnalyticsArticleFirestoreAPI = (organizationId: string, teamId: string, articleId: string, sportsType: SportsType, isSendingWebsiteAnalytics: boolean) => {
    const analyticsId = createAnalyticsId(teamId);
    const analyticsReference = createAnalyticReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);
    const webPageTitle = 'Article';

    return db.runTransaction(async (transaction) => {
        const analyticsDoc = await transaction.get(analyticsReference);

        if (analyticsDoc.exists) {
            const analytics: any = analyticsDoc.data();
            const { webPageViews, articleViews } = analytics;
            const webPageView = webPageViews[webPageTitle];
            let newWebPageViews = { ...webPageViews };
            const articleView = articleViews[articleId];
            let newArticleViews = { ...articleViews };

            if (isValidObject(webPageView)) {
                newWebPageViews = { ...webPageViews, [webPageTitle]: webPageView + 1 };
            } else {
                newWebPageViews = { ...webPageViews, [webPageTitle]: 1 };
            }

            if (isValidObject(articleView)) {
                newArticleViews = { ...articleViews, [articleId]: articleView + 1 };
            } else {
                newArticleViews = { ...articleViews, [articleId]: 1 };
            }

            if (isSendingWebsiteAnalytics) {
                console.log('OptimX Analytics updated for website, webpage and article');
                return transaction.update(analyticsReference, { totalViews: analytics.totalViews + 1, webPageViews: newWebPageViews, articleViews: newArticleViews, timeModified: getCurrentDateTime() });
            }
            console.log('OptimX Analytics updated for webpage and article');
            return transaction.update(analyticsReference, { webPageViews: newWebPageViews, articleViews: newArticleViews, timeModified: getCurrentDateTime() });
        }
        const newAnalytics: AnalyticsObject = {
            id: analyticsId,
            reference: analyticsReference,
            teamId,
            teamReference,
            organizationId,
            organizationReference,
            totalViews: 0,
            homePageViews: 0,
            webPageViews: { [webPageTitle]: 1 },
            playerViews: {},
            coachViews: {},
            articleViews: { [articleId]: 1 },
            galleryViews: {},
            eventViews: {},
            gameViews: {},
            productViews: {},
            formsSubmitted: {},
            sportsType,
            timeOfAnalytics: getCurrentDateTime(),
            timeModified: getCurrentDateTime(),
            timeCreated: getCurrentDateTime(),
        };

        if (isSendingWebsiteAnalytics) {
            console.log('OptimX Analytics created for website, webpage and article');
            return transaction.set(analyticsReference, { ...newAnalytics, totalViews: 1 });
        }
        console.log('OptimX Analytics created for webpage and article');
        return transaction.set(analyticsReference, newAnalytics);
    });
};

export const updateAnalyticsGalleryFirestoreAPI = (organizationId: string, teamId: string, galleryId: string, sportsType: SportsType, isSendingWebsiteAnalytics: boolean) => {
    const analyticsId = createAnalyticsId(teamId);
    const analyticsReference = createAnalyticReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);
    const webPageTitle = 'Gallery';

    return db.runTransaction(async (transaction) => {
        const analyticsDoc = await transaction.get(analyticsReference);

        if (analyticsDoc.exists) {
            const analytics: any = analyticsDoc.data();
            const { webPageViews, galleryViews } = analytics;
            const webPageView = webPageViews[webPageTitle];
            let newWebPageViews = { ...webPageViews };
            const galleryView = galleryViews[galleryId];
            let newGalleryViews = { ...galleryViews };

            if (isValidObject(webPageView)) {
                newWebPageViews = { ...webPageViews, [webPageTitle]: webPageView + 1 };
            } else {
                newWebPageViews = { ...webPageViews, [webPageTitle]: 1 };
            }

            if (isValidObject(galleryView)) {
                newGalleryViews = { ...galleryViews, [galleryId]: galleryView + 1 };
            } else {
                newGalleryViews = { ...galleryViews, [galleryId]: 1 };
            }

            if (isSendingWebsiteAnalytics) {
                console.log('OptimX Analytics updated for website, webpage and gallery');
                return transaction.update(analyticsReference, { totalViews: analytics.totalViews + 1, webPageViews: newWebPageViews, galleryViews: newGalleryViews, timeModified: getCurrentDateTime() });
            }
            console.log('OptimX Analytics updated for webpage and gallery');
            return transaction.update(analyticsReference, { webPageViews: newWebPageViews, galleryViews: newGalleryViews, timeModified: getCurrentDateTime() });
        }
        const newAnalytics: AnalyticsObject = {
            id: analyticsId,
            reference: analyticsReference,
            teamId,
            teamReference,
            organizationId,
            organizationReference,
            totalViews: 0,
            homePageViews: 0,
            webPageViews: { [webPageTitle]: 1 },
            playerViews: {},
            coachViews: {},
            articleViews: {},
            galleryViews: { [galleryId]: 1 },
            eventViews: {},
            gameViews: {},
            productViews: {},
            formsSubmitted: {},
            sportsType,
            timeOfAnalytics: getCurrentDateTime(),
            timeModified: getCurrentDateTime(),
            timeCreated: getCurrentDateTime(),
        };

        if (isSendingWebsiteAnalytics) {
            console.log('OptimX Analytics created for website, webpage and gallery');
            return transaction.set(analyticsReference, { ...newAnalytics, totalViews: 1 });
        }
        console.log('OptimX Analytics created for webpage and gallery');
        return transaction.set(analyticsReference, newAnalytics);
    });
};

export const updateAnalyticsEventFirestoreAPI = (organizationId: string, teamId: string, eventId: string, sportsType: SportsType, isSendingWebsiteAnalytics: boolean) => {
    const analyticsId = createAnalyticsId(teamId);
    const analyticsReference = createAnalyticReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);
    const webPageTitle = 'Event Description';

    return db.runTransaction(async (transaction) => {
        const analyticsDoc = await transaction.get(analyticsReference);

        if (analyticsDoc.exists) {
            const analytics: any = analyticsDoc.data();
            const { webPageViews, eventViews } = analytics;
            const webPageView = webPageViews[webPageTitle];
            let newWebPageViews = { ...webPageViews };
            const eventView = eventViews[eventId];
            let newEventViews = { ...eventViews };

            if (isValidObject(webPageView)) {
                newWebPageViews = { ...webPageViews, [webPageTitle]: webPageView + 1 };
            } else {
                newWebPageViews = { ...webPageViews, [webPageTitle]: 1 };
            }

            if (isValidObject(eventView)) {
                newEventViews = { ...eventViews, [eventId]: eventView + 1 };
            } else {
                newEventViews = { ...eventViews, [eventId]: 1 };
            }

            if (isSendingWebsiteAnalytics) {
                console.log('OptimX Analytics updated for website, webpage and event description');
                return transaction.update(analyticsReference, { totalViews: analytics.totalViews + 1, webPageViews: newWebPageViews, eventViews: newEventViews, timeModified: getCurrentDateTime() });
            }
            console.log('OptimX Analytics updated for webpage and event description');
            return transaction.update(analyticsReference, { webPageViews: newWebPageViews, eventViews: newEventViews, timeModified: getCurrentDateTime() });
        }
        const newAnalytics: AnalyticsObject = {
            id: analyticsId,
            reference: analyticsReference,
            teamId,
            teamReference,
            organizationId,
            organizationReference,
            totalViews: 0,
            homePageViews: 0,
            webPageViews: { [webPageTitle]: 1 },
            playerViews: {},
            coachViews: {},
            articleViews: {},
            galleryViews: {},
            eventViews: { [eventId]: 1 },
            gameViews: {},
            productViews: {},
            formsSubmitted: {},
            sportsType,
            timeOfAnalytics: getCurrentDateTime(),
            timeModified: getCurrentDateTime(),
            timeCreated: getCurrentDateTime(),
        };

        if (isSendingWebsiteAnalytics) {
            return transaction.set(analyticsReference, { ...newAnalytics, totalViews: 1 });
        }

        return transaction.set(analyticsReference, newAnalytics);
    });
};

export const updateAnalyticsGameFirestoreAPI = (organizationId: string, teamId: string, gameId: string, sportsType: SportsType, isSendingWebsiteAnalytics: boolean) => {
    const analyticsId = createAnalyticsId(teamId);
    const analyticsReference = createAnalyticReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);
    const webPageTitle = 'Game';

    return db.runTransaction(async (transaction) => {
        const analyticsDoc = await transaction.get(analyticsReference);

        if (analyticsDoc.exists) {
            const analytics: any = analyticsDoc.data();
            const { webPageViews, gameViews } = analytics;
            const webPageView = webPageViews[webPageTitle];
            let newWebPageViews = { ...webPageViews };
            const gameView = gameViews[gameId];
            let newGameViews = { ...gameViews };

            if (isValidObject(webPageView)) {
                newWebPageViews = { ...webPageViews, [webPageTitle]: webPageView + 1 };
            } else {
                newWebPageViews = { ...webPageViews, [webPageTitle]: 1 };
            }

            if (isValidObject(gameView)) {
                newGameViews = { ...gameViews, [gameId]: gameView + 1 };
            } else {
                newGameViews = { ...gameViews, [gameId]: 1 };
            }

            if (isSendingWebsiteAnalytics) {
                console.log('OptimX Analytics updated for website, webpage and game');
                return transaction.update(analyticsReference, { totalViews: analytics.totalViews + 1, webPageViews: newWebPageViews, gameViews: newGameViews, timeModified: getCurrentDateTime() });
            }
            console.log('OptimX Analytics updated for webpage and game');
            return transaction.update(analyticsReference, { webPageViews: newWebPageViews, gameViews: newGameViews, timeModified: getCurrentDateTime() });
        }
        const newAnalytics: AnalyticsObject = {
            id: analyticsId,
            reference: analyticsReference,
            teamId,
            teamReference,
            organizationId,
            organizationReference,
            totalViews: 0,
            homePageViews: 0,
            webPageViews: { [webPageTitle]: 1 },
            playerViews: {},
            coachViews: {},
            articleViews: {},
            galleryViews: {},
            eventViews: {},
            gameViews: { [gameId]: 1 },
            productViews: {},
            formsSubmitted: {},
            sportsType,
            timeOfAnalytics: getCurrentDateTime(),
            timeModified: getCurrentDateTime(),
            timeCreated: getCurrentDateTime(),
        };

        if (isSendingWebsiteAnalytics) {
            console.log('OptimX Analytics created for website, webpage and game');
            return transaction.set(analyticsReference, { ...newAnalytics, totalViews: 1 });
        }
        console.log('OptimX Analytics created for webpage and game');
        return transaction.set(analyticsReference, newAnalytics);
    });
};

export const updateAnalyticsFormFirestoreAPI = (organizationId: string, teamId: string, formSubmittedType: FormType, sportsType: SportsType) => {
    const analyticsId = createAnalyticsId(teamId);
    const analyticsReference = createAnalyticReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);

    return db.runTransaction(async (transaction) => {
        const analyticsDoc = await transaction.get(analyticsReference);

        if (analyticsDoc.exists) {
            const analytics: any = analyticsDoc.data();
            const { formsSubmitted } = analytics;
            const formSubmitted = formsSubmitted[formSubmittedType];
            let newFormsSubmitted = { ...formsSubmitted };
            if (isValidObject(formSubmitted)) {
                newFormsSubmitted = { ...formsSubmitted, [formSubmittedType]: formSubmitted + 1 };
            } else {
                newFormsSubmitted = { ...formsSubmitted, [formSubmittedType]: 1 };
            }
            console.log('OptimX Analytics updated for form submission');
            return transaction.update(analyticsReference, { formsSubmitted: newFormsSubmitted, timeModified: getCurrentDateTime() });
        }
        const newAnalytics: AnalyticsObject = {
            id: analyticsId,
            reference: analyticsReference,
            teamId,
            teamReference,
            organizationId,
            organizationReference,
            totalViews: 0,
            homePageViews: 0,
            webPageViews: {},
            playerViews: {},
            coachViews: {},
            articleViews: {},
            galleryViews: {},
            eventViews: {},
            gameViews: {},
            productViews: {},
            formsSubmitted: { [formSubmittedType]: 1 },
            sportsType,
            timeOfAnalytics: getCurrentDateTime(),
            timeModified: getCurrentDateTime(),
            timeCreated: getCurrentDateTime(),
        };
        console.log('OptimX Analytics created for form submission');
        return transaction.set(analyticsReference, newAnalytics);
    });
};

export const updateAnalyticsProductFirestoreAPI = (organizationId: string, teamId: string, productId: string, sportsType: SportsType, isSendingWebsiteAnalytics: boolean) => {
    const analyticsId = createAnalyticsId(teamId);
    const analyticsReference = createAnalyticReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);
    const webPageTitle = 'Product';

    return db.runTransaction(async (transaction) => {
        const analyticsDoc = await transaction.get(analyticsReference);

        if (analyticsDoc.exists) {
            const analytics: any = analyticsDoc.data();
            const { webPageViews, productViews } = analytics;
            const webPageView = webPageViews[webPageTitle];
            let newWebPageViews = { ...webPageViews };
            const productView = productViews[productId];
            let newProductViews = { ...productViews };

            if (isValidObject(webPageView)) {
                newWebPageViews = { ...webPageViews, [webPageTitle]: webPageView + 1 };
            } else {
                newWebPageViews = { ...webPageViews, [webPageTitle]: 1 };
            }

            if (isValidObject(productView)) {
                newProductViews = { ...productViews, [productId]: productView + 1 };
            } else {
                newProductViews = { ...productViews, [productId]: 1 };
            }

            if (isSendingWebsiteAnalytics) {
                console.log('OptimX Analytics updated for website, webpage and article');
                return transaction.update(analyticsReference, { totalViews: analytics.totalViews + 1, webPageViews: newWebPageViews, productViews: newProductViews, timeModified: getCurrentDateTime() });
            }
            console.log('OptimX Analytics updated for webpage and article');
            return transaction.update(analyticsReference, { webPageViews: newWebPageViews, productViews: newProductViews, timeModified: getCurrentDateTime() });
        }
        const newAnalytics: AnalyticsObject = {
            id: analyticsId,
            reference: analyticsReference,
            teamId,
            teamReference,
            organizationId,
            organizationReference,
            totalViews: 0,
            homePageViews: 0,
            webPageViews: { [webPageTitle]: 1 },
            playerViews: {},
            coachViews: {},
            articleViews: {},
            galleryViews: {},
            eventViews: {},
            gameViews: {},
            productViews: { [productId]: 1 },
            formsSubmitted: {},
            sportsType,
            timeOfAnalytics: getCurrentDateTime(),
            timeModified: getCurrentDateTime(),
            timeCreated: getCurrentDateTime(),
        };

        if (isSendingWebsiteAnalytics) {
            console.log('OptimX Analytics created for website, webpage and product');
            return transaction.set(analyticsReference, { ...newAnalytics, totalViews: 1 });
        }
        console.log('OptimX Analytics created for webpage and product');
        return transaction.set(analyticsReference, newAnalytics);
    });
};
