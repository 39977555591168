import { subscriptionInfoId } from '../../catalog/Others';
import { db } from '../FirebaseLibrary';

export const fetchSubscriptionStatusFirestoreAPI = (teamId: string) => {
    return db.collection('stripe-customers').doc(teamId).collection('subscriptions')
        .where('status', 'in', ['trialing', 'active'])
        .get();
};

/**
 * Following is for Private Subscription Info that cannot be updated by Client
 */

export const createSubscriptionInfoReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/read-only`).doc(subscriptionInfoId);
};

export const fetchSubscriptionInfoFirestoreAPI = (teamId: string) => {
    return createSubscriptionInfoReference(teamId).get();
};
