import React, { memo, useContext } from 'react';
import { SocialIcon } from 'react-social-icons';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { NavLink } from 'react-router-dom';
import { isValidObject, isValidString } from '../../../../catalog/Validity';
import { context } from '../../../../globalState/GlobalState';
import Footer1 from '../../../designs/uiElements/footer1/Footer1';
import { WebsiteDesignType } from '../../../../catalog/enums/WebsiteDesignType';
import { optimXStoreWebsite } from '../../../../catalog/TeamInfo';
import OptimXFooter from './OptimXFooter';
import AdFooter from './AdFooter';
import { getXURLFomTwitterURL } from '../../../../catalog/Social';

const Footer = (props) => {
    const { showLinks } = props;
    const { globalState } = useContext(context);

    const styles = StyleSheet.create({
        footerLink: {
            color: isValidObject(globalState) && isValidObject(globalState.websiteStyles) && isValidObject(globalState.websiteStyles.colors) && isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : '#ffffff',
        },
    });

    const getFooterDesign = (children) => {
        if (!isValidObject(globalState) || !isValidObject(globalState.websiteStyles)) {
            return null;
        }

        if (globalState.websiteStyles.design.homepage === WebsiteDesignType.DESIGN_1) {
            return (
                <Footer1 showLinks={showLinks}>
                    {children}
                </Footer1>
            );
        }

        return (
            <Footer1 showLinks={showLinks}>
                {children}
            </Footer1>
        );
    };

    return (
        <>
            {getFooterDesign(
                <>
                    <div className="team-footer full-width">
                        {showLinks && !optimXStoreWebsite ? (
                            <div className="team-footer-links m-l-100px">
                                <NavLink className="text-no-underline" to="/" exact>
                                    <p className={`team-footer-link p-t-10px p-b-10px p-r-25px m-t-0 m-b-0 ${css(styles.footerLink)}`}>Home</p>
                                </NavLink>
                                <NavLink className="text-no-underline" to="/schedule/upcoming" exact>
                                    <p className={`team-footer-link p-t-10px p-b-10px p-r-25px m-t-0 m-b-0 ${css(styles.footerLink)}`}>Schedule</p>
                                </NavLink>
                                <NavLink className="text-no-underline" to="/roster" exact>
                                    <p className={`team-footer-link text-white p-t-10px p-b-10px p-r-25px m-t-0 m-b-0 ${css(styles.footerLink)}`}>Roster</p>
                                </NavLink>
                            </div>
                        ) : <div className="team-footer-links m-l-100px"></div>}
                        <div className="team-footer-content x-center">
                            {isValidObject(globalState.silhouetteLogo) && isValidString(globalState.silhouetteLogo.logo2) ? (
                                <img src={globalState.silhouetteLogo.logo2} className="icon x-center" alt={isValidString(globalState.name) ? globalState.name : 'Team Logo'} />
                            ) : (
                                <p className="name-icon text-no-underline text-white x-center font-size-2xl m-t-5px m-b-20px">{globalState.teamAlias}</p>
                            )}
                        </div>
                        {showLinks ? (
                            <div className="team-footer-social m-r-100px">
                                {isValidObject(globalState) && isValidObject(globalState.socials) && isValidString(globalState.socials.twitterURL) && !optimXStoreWebsite ? (
                                    <SocialIcon url={getXURLFomTwitterURL(globalState.socials.twitterURL)} fgColor={isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white'} bgColor="transparent" target="_blank" />
                                ) : null}
                                {isValidObject(globalState) && isValidObject(globalState.socials) && isValidString(globalState.socials.facebookURL) && !optimXStoreWebsite ? (
                                    <SocialIcon url={globalState.socials.facebookURL} fgColor={isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white'} bgColor="transparent" target="_blank" />
                                ) : null}
                                {isValidObject(globalState) && isValidObject(globalState.socials) && isValidString(globalState.socials.instagramURL) && !optimXStoreWebsite ? (
                                    <SocialIcon url={globalState.socials.instagramURL} fgColor={isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white'} bgColor="transparent" target="_blank" />
                                ) : null}
                                {isValidObject(globalState) && isValidObject(globalState.socials) && isValidString(globalState.socials.youtubeURL) && !optimXStoreWebsite ? (
                                    <SocialIcon url={globalState.socials.youtubeURL} fgColor={isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white'} bgColor="transparent" target="_blank" />
                                ) : null}
                                {isValidObject(globalState) && isValidObject(globalState.socials) && isValidString(globalState.socials.tiktokURL) && !optimXStoreWebsite ? (
                                    <SocialIcon url={globalState.socials.tiktokURL} fgColor={isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white'} bgColor="transparent" target="_blank" />
                                ) : null}
                            </div>
                        ) : <div className="team-footer-social m-r-100px"></div>}
                    </div>
                    <AdFooter />
                    <OptimXFooter />
                </>,
            )}
        </>
    );
};

Footer.propTypes = {
    showLinks: PropTypes.bool,
};

Footer.defaultProps = {
    showLinks: true,
};

export default memo(Footer);
