/* eslint-disable no-console */
import { sendErrorAnalytics } from '../../catalog/Analytics';
import { ARTICLE_DOES_NOT_EXIST, FETCH_ARTICLES_ERROR, FETCH_ARTICLE_ERROR } from '../../catalog/NotificationsComments';
import { databaseTagsToLocalTags } from '../../catalog/Tags';
import { optimXTesting } from '../../catalog/TeamInfo';
import { isValidObject } from '../../catalog/Validity';
import { fetchArticleFirestoreAPI, fetchArticlesByTagFirestoreAPI, fetchArticlesFirestoreAPI, fetchLimitedArticlesByTagFirestoreAPI, fetchLimitedArticlesFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsArticle';

export const fetchArticlesAPI = async (teamId: string, setState: Function, snackbar: Function) => {
    const articles: any = [];
    try {
        const docs = await fetchArticlesFirestoreAPI(teamId);
        docs.forEach((doc) => {
            if (doc.exists) {
                // if (doc.data().content.length > 0 && doc.data().content !== '<p></p>\n') {
                articles.push({ ...doc.data() });
                // }
            }
        });
        setState({ type: 'fetchArticles', articles });
    } catch (error) {
        sendErrorAnalytics(FETCH_ARTICLES_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_ARTICLES_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchLimitedArticlesAPI = async (teamId: string, count: number, setState: Function, snackbar: Function) => {
    const articles: any = [];
    try {
        const docs = await fetchLimitedArticlesFirestoreAPI(teamId, count);
        docs.forEach((doc) => {
            if (doc.exists) {
                // if (doc.data().content.length > 0 && doc.data().content !== '<p></p>\n') {
                articles.push({ ...doc.data() });
                // }
            }
        });
        setState({ type: 'fetchArticles', articles });
    } catch (error) {
        sendErrorAnalytics(FETCH_ARTICLES_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_ARTICLES_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchArticlesByTagAPI = async (teamId: string, tagId: string, setState: Function, snackbar: Function) => {
    const articles: any = [];
    try {
        const docs = await fetchArticlesByTagFirestoreAPI(teamId, tagId);
        docs.forEach((doc) => {
            if (doc.exists) {
                // if (doc.data().content.length > 0 && doc.data().content !== '<p></p>\n') {
                articles.push({ ...doc.data() });
                // }
            }
        });
        setState({ type: 'fetchArticles', articles });
    } catch (error) {
        sendErrorAnalytics(FETCH_ARTICLES_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_ARTICLES_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchLimitedArticlesByTagAPI = async (teamId: string, tagId: string, count: number, setState: Function, snackbar: Function) => {
    const articles: any = [];
    try {
        const docs = await fetchLimitedArticlesByTagFirestoreAPI(teamId, tagId, count);
        docs.forEach((doc) => {
            if (doc.exists) {
                // if (doc.data().content.length > 0 && doc.data().content !== '<p></p>\n') {
                articles.push({ ...doc.data() });
                // }
            }
        });
        setState({ type: 'fetchArticles', articles });
    } catch (error) {
        sendErrorAnalytics(FETCH_ARTICLES_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_ARTICLES_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchArticleTitleAPI = async (articleId: string, setState: Function, snackbar: Function) => {
    try {
        const doc = await fetchArticleFirestoreAPI(articleId);
        if (doc.exists && doc.data() !== null && doc.data() !== undefined) {
            const article = doc.data();
            setState({ type: 'fetchArticleTitle', title: article?.title });
        } else {
            sendErrorAnalytics(ARTICLE_DOES_NOT_EXIST);
            setState({ type: 'setError' });
            if (optimXTesting) {
                snackbar(ARTICLE_DOES_NOT_EXIST, { variant: 'error' });
            }
        }
    } catch (error) {
        sendErrorAnalytics(FETCH_ARTICLE_ERROR);
        setState({ type: 'setError' });
        if (optimXTesting) {
            snackbar(FETCH_ARTICLE_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchArticleAPI = async (teamId: string, articleId: string, setState: Function, snackbar: Function) => {
    try {
        const doc = await fetchArticleFirestoreAPI(articleId);
        if (doc.exists && doc.data() !== null && doc.data() !== undefined) {
            const article = doc.data();
            const tags = await databaseTagsToLocalTags(article?.tagIds, article?.tagReferences, article?.tagTypes, teamId);
            setState({ type: 'fetchArticle',
                title: article?.title,
                url: article?.url,
                author: article?.author,
                content: article?.content,
                mobileContent: article?.mobileContent,
                notes: article?.notes,
                tags,
                published: article?.published,
                seasonId: article?.seasonId,
                gameId: article?.gameId,
                galleryId: article?.galleryId,
                featureImage: article?.featureImage,
                timeOfArticle: isValidObject(article?.timeOfArticle) ? article?.timeOfArticle : article?.timeCreated });
        } else {
            sendErrorAnalytics(ARTICLE_DOES_NOT_EXIST);
            setState({ type: 'setError' });
            if (optimXTesting) {
                snackbar(ARTICLE_DOES_NOT_EXIST, { variant: 'error' });
            }
        }
    } catch (error) {
        sendErrorAnalytics(FETCH_ARTICLE_ERROR);
        setState({ type: 'setError' });
        if (optimXTesting) {
            snackbar(FETCH_ARTICLE_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};
