import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { getOptimXBackgroundStyle } from '../../../../catalog/Website';
import { context } from '../../../../globalState/GlobalState';
import TextField1 from '../../textFields/textField1/TextField1';
import './css/TicketsComponent1.css';
import { checkEmailValidity, isValidString } from '../../../../catalog/Validity';
import Button1 from '../../buttons/Button1';
import { checkTicketsValidity } from '../../../../catalog/Store';

const TicketCustomerInfoPopup = (props) => {
    const { open, onCancel, name, email, onNameUpdate, onEmailUpdate, checkout } = props;
    const { globalState } = useContext(context);
    const { enqueueSnackbar } = useSnackbar();

    const handleKeypress = (e) => {
        if (!checkTicketsValidity(globalState)) {
            enqueueSnackbar('This ticket is not available for purchase at the moment', { variant: 'error' });
            return;
        }

        if (e.charCode === 13 && isValidString(name) && checkEmailValidity(email, () => {}, () => {})) {
            checkout();
        }
    };

    return (
        <div>
            <Dialog className="pop-up" onClose={onCancel} open={open} fullWidth maxWidth="sm">
                <div className="pop-up-title p-l-20px p-r-20px font-size-2xl text-white" style={getOptimXBackgroundStyle(globalState)}>
                    <div className="pop-up-title-title">
                        <p>Customer Info</p>
                    </div>
                    <IconButton onClick={onCancel}>
                        <CloseRounded className="pop-up-title-close" />
                    </IconButton>
                </div>
                <DialogContent className="pop-up-content pop-up-content-ticket-customer">
                    <TextField1
                        title="Name"
                        required
                        value={name}
                        onChange={(event) => { onNameUpdate(event.target.value); }}
                    />
                    <TextField1
                        title="Email"
                        type="email"
                        helperText="Your tickets will be sent to this email. Please make sure you enter the correct email"
                        required
                        value={email}
                        onChange={(event) => { onEmailUpdate(event.target.value); }}
                        handleKeypress={handleKeypress}
                    />
                </DialogContent>
                <DialogActions className="pop-up-actions h-50px">
                    <Button1
                        onClick={checkout}
                        title="Complete Checkout"
                        transparent
                        disabled={!isValidString(name) || !checkEmailValidity(email, () => {}, () => {})}
                        backgroundColor={globalState.websiteStyles.colors.primaryColor}
                        fontColor={isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white'}
                        fontSize="1rem"
                        fontFamily="HelveticaNeue-CondensedBold, Roboto Condensed, Helvetica Neue, Helvetica,Arial, sans-serif"
                        borderRadius="2px"
                        width="300px"
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

TicketCustomerInfoPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    onNameUpdate: PropTypes.func.isRequired,
    onEmailUpdate: PropTypes.func.isRequired,
    checkout: PropTypes.func.isRequired,
};

export default memo(TicketCustomerInfoPopup);
