/**
 * Notification Comments used through out the project. This is different from normal comments because
 * these normally appear due to some trigger.
 */

export const INVALID_EMAIL = 'Invalid email. Please enter a valid email';

export const ERROR_FETCHING_TAGS = 'Error fetching tags';

export const ERROR_FETCHING_STATS = 'Error fetching stats';
export const ERROR_FETCHING_ROSTER_STATS = 'Error fetching roster stats';
export const ERROR_FETCHING_ALUMNI_STATS = 'Error fetching alumni stats';
export const ERROR_FETCHING_GAME_STATS = 'Error fetching game stats';

export const FETCH_WEBSITE_ERROR = 'Error while fetching website information';
export const WEBSITE_DOES_NOT_EXIST = 'Website information does not exist';
export const UPDATE_WEBSITE_SUCCESS = 'Website information updated successfully';

export const FETCH_PLAYERS_ERROR = 'Error while fetching players';
export const FETCH_PLAYER_ERROR = 'Error while fetching player';
export const PLAYER_DOES_NOT_EXIST = 'Player does not exist';

export const FETCH_ALUMNI_ERROR = 'Error while fetching alumni';
export const FETCH_ALMUNI_ERROR = 'Error while fetching alumni';
export const ALUMNI_DOES_NOT_EXIST = 'Alumni does not exist';

export const FETCH_COACHES_ERROR = 'Error while fetching coaches';
export const FETCH_COACH_ERROR = 'Error while fetching coach';
export const COACH_DOES_NOT_EXIST = 'Coach does not exist';

export const FETCH_STAFFS_ERROR = 'Error while fetching staffs';
export const FETCH_STAFF_ERROR = 'Error while fetching staff';
export const STAFF_DOES_NOT_EXIST = 'Staff does not exist';

export const FETCH_OPPONENTS_ERROR = 'Error while fetching opponents';
export const FETCH_OPPONENT_ERROR = 'Error while fetching opponent';
export const OPPONENT_DOES_NOT_EXIST = 'Opponent does not exist';

export const FETCH_SPONSORS_ERROR = 'Error while fetching sponsors';
export const FETCH_SPONSOR_ERROR = 'Error while fetching sponsor';
export const SPONSOR_DOES_NOT_EXIST = 'Sponsor does not exist';

export const FETCH_SEASONS_ERROR = 'Error while fetching seasons';
export const FETCH_SEASON_ERROR = 'Error while fetching season';
export const SEASON_DOES_NOT_EXIST = 'Season does not exist';

export const FETCH_GAMES_ERROR = 'Error while fetching games';
export const FETCH_GAME_ERROR = 'Error while fetching game';
export const GAME_DOES_NOT_EXIST = 'Game does not exist';

export const FETCH_ARTICLES_ERROR = 'Error while fetching articles';
export const FETCH_ARTICLE_ERROR = 'Error while fetching article';
export const ARTICLE_DOES_NOT_EXIST = 'Article does not exist';

export const FETCH_TICKETS_ERROR = 'Error while fetching tickets';
export const FETCH_TICKET_ERROR = 'Error while fetching ticket';
export const TICKET_DOES_NOT_EXIST = 'Ticket does not exist';

export const FETCH_PRODUCTS_ERROR = 'Error while fetching products';
export const FETCH_PRODUCT_ERROR = 'Error while fetching product';
export const PRODUCT_DOES_NOT_EXIST = 'Product does not exist';

export const FETCH_CUSTOMPAGES_ERROR = 'Error while fetching web pages';
export const FETCH_CUSTOMPAGE_ERROR = 'Error while fetching web page';
export const CUSTOMPAGE_DOES_NOT_EXIST = 'Web page does not exist';

export const FETCH_GALLERIES_ERROR = 'Error while fetching galleries';
export const FETCH_GALLERY_ERROR = 'Error while fetching gallery';
export const GALLERY_DOES_NOT_EXIST = 'Gallery does not exist';

export const FETCH_FORM_ERROR = 'Error while fetching form';
export const FORM_DOES_NOT_EXIST = 'Form does not exist';

export const FETCH_EVENTS_AND_PRACTICE_ERROR = 'Error while fetching events and practice';
export const FETCH_EVENTS_ERROR = 'Error while fetching events';
export const FETCH_PRACTICE_ERROR = 'Error while fetching practice';
export const FETCH_EVENT_ERROR = 'Error while fetching';
export const EVENT_DOES_NOT_EXIST = 'Does not exist';

export const FETCH_PHOTOS_ERROR = 'Error while fetching imges';
export const FETCH_PHOTO_ERROR = 'Error while fetching image';
export const PHOTO_DOES_NOT_EXIST = 'Image does not exist';

export const INSERT_FORM_SUCCESS = 'Form submitted successfully';
export const INSERT_FORM_ERROR = 'Error while submitting form';
export const RECAPTCHA_NOT_VERIFIED = 'Please let us know if you are a robot';
export const FORM_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const ERROR_FETCHING_ACCOUNT_SUBSCRIPTION_STATUS = 'Error while fetching account subscription status. Contact OptimX Sports if you are seeing this error';
export const ERROR_FETCHING_TEAM_SUBSCRIPTION_INFO = 'Error while fetching team subscription info';
export const ERROR_FETCHING_TEAM_STORE_INFO = 'Error while fetching team store info';
export const ERROR_FETCHING_OPTIMX_ADMIN_INFO = 'Error while fetching team admin info';
