import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { StyleSheet, css } from 'aphrodite';
import { context } from '../../../globalState/GlobalState';

const Button1 = (props) => {
    const { title, type, disabled, showArrow, showLogo, showSilhouetteLogo, darkBackground, transparent, fontSize, fontFamily, height, width, logoHeight, backgroundColor, fontColor, borderColor, borderRadius, loadingSpinner, onClick } = props;
    const { globalState } = useContext(context);

    const styles = StyleSheet.create({
        disabled: {
            height,
            width,
            fontSize,
            fontFamily,
            borderRadius,
        },
        button: {
            backgroundColor,
            height,
            width,
            fontSize,
            fontFamily,
            color: fontColor,
            borderRadius,
        },
        transparentButton: {
            borderColor,
            height,
            width,
            fontSize,
            fontFamily,
            color: borderColor,
            borderRadius,
        },
        lighBackground: {
            ':hover': {
                backgroundColor,
                borderColor: backgroundColor,
                color: fontColor,
                transitionDuration: '0.2s',
            },
        },
        darkBackground: {
            ':hover': {
                backgroundColor: 'white',
                color: '#171d28',
                transitionDuration: '0.2s',
            },
        },
        logo: {
            height: logoHeight,
            maxWidth: logoHeight,
            objectFit: 'contain',
        },
    });

    const getClassName = () => {
        if (disabled) {
            return `button ${css(styles.disabled)}`;
        }
        let className = '';
        if (transparent) {
            className += `transparent-button ${css(styles.transparentButton)}`;
        } else {
            className += `button ${css(styles.button)}`;
        }
        if (darkBackground) {
            className += ` ${css(styles.darkBackground)}`;
        } else {
            className += ` ${css(styles.lighBackground)}`;
        }

        return className;
    };

    const getLogo = () => {
        if (showLogo) {
            return <img src={globalState.logo.logo2} className={css(styles.logo)} alt="Team Logo" />;
        } if (showSilhouetteLogo) {
            return <img src={globalState.silhouetteLogo.logo2} className={css(styles.logo)} alt="Team Logo" />;
        }
        return null;
    };

    return (
        <Button
            className={getClassName()}
            onClick={onClick}
            type={type}
            disabled={disabled}
            startIcon={<>{getLogo()}</>}
            endIcon={<>{showArrow ? (<KeyboardArrowRightIcon style={{ marginBottom: '2px' }} />) : null}</>}
        >
            {loadingSpinner ? (
                <CircularProgress
                    className=""
                    variant="indeterminate"
                    size={23}
                    thickness={5}
                    style={{
                        color: fontColor,
                    }}
                />
            ) : title}
        </Button>
    );
};

Button1.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    showArrow: PropTypes.bool,
    showLogo: PropTypes.bool,
    showSilhouetteLogo: PropTypes.bool,
    darkBackground: PropTypes.bool,
    transparent: PropTypes.bool,
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    logoHeight: PropTypes.string,
    backgroundColor: PropTypes.string,
    fontColor: PropTypes.string,
    borderColor: PropTypes.string,
    borderRadius: PropTypes.string,
    loadingSpinner: PropTypes.bool,
    onClick: PropTypes.func,
};

Button1.defaultProps = {
    type: 'button',
    disabled: false,
    showArrow: false,
    showLogo: false,
    showSilhouetteLogo: false,
    darkBackground: false,
    transparent: false,
    fontSize: '1rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    height: 'auto',
    width: '150px',
    logoHeight: '20px',
    backgroundColor: '#171d28',
    fontColor: 'black',
    borderColor: '#171d28',
    borderRadius: '5px',
    loadingSpinner: false,
    onClick: () => {},
};

export default memo(Button1);
