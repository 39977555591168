import React, { memo, useContext, useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import './css/ArticlesComponent1.css';
import { context } from '../../../../globalState/GlobalState';
import { fetchLimitedArticlesAPI } from '../../../apiCalls/ArticleAPICalls';
import Spinner1 from '../../spinners/spinner1/Spinner1';
import ArticleThumbnail3 from '../../articleThumbnails/articleThumbnail3/ArticleThumbnail3';
import Title2 from '../../title/title2/Title2';

const initialState = {
    articles: [],
    loadingSpinner: true,
};

function reducer(state, action) {
    switch (action.type) {
        case 'fetchArticles':
            return { ...state, articles: action.articles };
        case 'setLoadingSpinner':
            return { ...state, loadingSpinner: action.loadingSpinner };
        default:
            return initialState;
    }
}

const ArticlesComponent1 = () => {
    const [state, setState] = useReducer(reducer, initialState);
    const { globalState } = useContext(context);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        async function fetchArticles() {
            setState({ type: 'setLoadingSpinner', loadingSpinner: true });
            await fetchLimitedArticlesAPI(globalState.accountId, 4, setState, enqueueSnackbar);
            setState({ type: 'setLoadingSpinner', loadingSpinner: false });
        }
        fetchArticles();
    }, []);

    return (
        <div className="articles-component1-container full-width">
            <Title2 link="/articles" title="Articles" />
            <div className="articles-component1 m-t-20px m-b-70px m-l-50px m-r-50px">
                {state.loadingSpinner ? (<Spinner1 color={globalState.websiteStyles.colors.primaryColor} />) : (
                    <>
                        {state.articles.map((article) => {
                            return (
                                <div key={article.id} className="articles-component1-article m-l-5px m-r-5px m-t-5px m-b-5px">
                                    <ArticleThumbnail3 article={article} />
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
};

export default memo(ArticlesComponent1);
