/**
 * Product Id is same as Price Id because every product can only have one price
 */
import React, { memo, useReducer, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import Spinner1 from '../../../uiElements/spinners/spinner1/Spinner1';
import { context } from '../../../../globalState/GlobalState';
import { fetchProductAPI } from '../../../apiCalls/ProductAPICalls';
import CounterComponent from '../../../uiElements/storeComponents/counterComponent/CounterComponent';
import { changeCartQuantity, removeProductFromCartUsingCartItemId } from '../../../../catalog/Store';
import { showPriceFromCents } from '../../../../catalog/Price';
import { isValidString } from '../../../../catalog/Validity';

const initialState = {
    productTitle: '',
    productImages: [],
    productPrice: 0,
    productShippingPrice: 0,
    productsTotal: 0,
    productsSold: 0,
    productsHold: 0,
    loadingSpinner: true,
};

function reducer(state, action) {
    switch (action.type) {
        case 'fetchProduct':
            return { ...state,
                productTitle: action.title,
                productImages: action.images,
                productPrice: action.price,
                productShippingPrice: action.shippingPrice,
                productsTotal: action.total,
                productsSold: action.sold,
                productsHold: action.hold,
            };
        case 'setLoadingSpinner':
            return { ...state, loadingSpinner: action.loadingSpinner };
        default:
            return initialState;
    }
}

const NavItemCartProductItem = (props) => {
    const { cartItemId, productId, productOptions, quantity, setPrice, setShippingPrice } = props;
    const [state, setState] = useReducer(reducer, initialState);
    const { globalState, setGlobalState } = useContext(context);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        async function fetchProduct() {
            setState({ type: 'setLoadingSpinner', loadingSpinner: true });
            await fetchProductAPI(globalState.accountId, productId, setState, setGlobalState, enqueueSnackbar);
            setState({ type: 'setLoadingSpinner', loadingSpinner: false });
        }

        fetchProduct();
    }, [productId]);

    useEffect(() => {
        setPrice(state.productPrice * quantity);
        setShippingPrice(state.productShippingPrice * quantity);
    }, [state.productPrice, quantity]);

    const getImage = () => {
        if (isValidString(state.productImages[0])) {
            return state.productImages[0];
        } if (isValidString(state.productImages[1])) {
            return state.productImages[1];
        } if (isValidString(state.productImages[2])) {
            return state.productImages[2];
        }
        return '';
    };

    return (
        <React.Fragment key={cartItemId}>
            {state.loadingSpinner ? (<Spinner1 color={globalState.websiteStyles.colors.primaryColor} />) : (
                <div key={cartItemId} className="nav-item-cart-item p-t-50px">
                    <p className="nav-item-cart-item-label text-uppercase m-t-0 m-b-0">Product</p>
                    {isValidString(getImage()) ? <img className="nav-item-cart-item-image" src={getImage()} alt={state.productTitle} /> : null}
                    <p className="nav-item-cart-item-title m-t-0 m-b-0">{state.productTitle}</p>
                    <div className="m-t-5px m-b-10px">
                        {productOptions.map((productOption) => {
                            return (<p key={productOption.id} className="nav-item-cart-item-option font-size-s font-weight-400 m-t-0 m-b-0 text-lightgray">{`${productOption.title} - ${productOption.value}`}</p>);
                        })}
                    </div>
                    <div className="nav-item-cart-item-quantity">
                        <CounterComponent
                            count={quantity}
                            increment={() => { changeCartQuantity(cartItemId, quantity + 1, setGlobalState); }}
                            decrement={() => { changeCartQuantity(cartItemId, quantity - 1, setGlobalState); }}
                        />
                        <IconButton
                            aria-label="delete"
                            size="medium"
                            onClick={() => {
                                removeProductFromCartUsingCartItemId(cartItemId, setGlobalState);
                                setPrice(0);
                                setShippingPrice(0);
                            }}
                        >
                            <DeleteIcon style={{ color: 'red' }} />
                        </IconButton>
                    </div>
                    <p className="nav-item-cart-item-price m-t-0 m-b-0">{`Total: ${showPriceFromCents(quantity * state.productPrice + quantity * state.productShippingPrice)}`}</p>
                </div>
            )}
        </React.Fragment>
    );
};

NavItemCartProductItem.propTypes = {
    cartItemId: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    productOptions: PropTypes.any.isRequired,
    quantity: PropTypes.number.isRequired,
    setPrice: PropTypes.func.isRequired,
    setShippingPrice: PropTypes.func.isRequired,
};

export default (memo(NavItemCartProductItem));
