import { useSnackbar } from 'notistack';
import React, { memo, useContext, useEffect, useReducer } from 'react';
import { isValidString } from '../../../../catalog/Validity';
import { context } from '../../../../globalState/GlobalState';
import { fetchSponsorsAPI } from '../../../apiCalls/SponsorAPICalls';
import Spinner1 from '../../spinners/spinner1/Spinner1';
import Title3 from '../../title/title3/Title3';
import './css/SponsorsComponent4.css';

const initialState = {
    sponsors: [],
    loadingSpinner: true,
};

function reducer(state, action) {
    switch (action.type) {
        case 'fetchSponsors':
            return { ...state, sponsors: action.sponsors };
        case 'setLoadingSpinner':
            return { ...state, loadingSpinner: action.loadingSpinner };
        default:
            return initialState;
    }
}

const SponsorsComponent4 = () => {
    const [state, setState] = useReducer(reducer, initialState);
    const { globalState } = useContext(context);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        async function fetchSponsors() {
            setState({ type: 'setLoadingSpinner', loadingSpinner: true });
            await fetchSponsorsAPI(globalState.accountId, setState, enqueueSnackbar);
            setState({ type: 'setLoadingSpinner', loadingSpinner: false });
        }
        fetchSponsors();
    }, []);

    const createUrl = (url) => {
        if (url.includes('http')) {
            return url;
        }
        return `http://${url}`;
    };

    return (
        <>
            {state.sponsors.length > 0 ? (
                <div className="sponsors-component4-container box-shadow-black x-center p-t-20px p-b-20px p-l-50px p-r-50px m-t-50px">
                    <Title3 title="Sponsors" />
                    <div className="sponsors-component4 full-width m-t-50px">
                        {state.loadingSpinner ? (<Spinner1 color={globalState.websiteStyles.colors.primaryColor} />) : (
                            <>
                                {state.sponsors.map((sponsor) => {
                                    return (
                                        <React.Fragment key={sponsor.id}>
                                            {isValidString(sponsor.logo) ? (
                                                <>
                                                    {isValidString(sponsor.url) ? (
                                                        <a className="sponsors-component4-sponsor m-t-20px m-b-20px text-no-underline" href={createUrl(sponsor.url)} target="_blank" rel="noreferrer">
                                                            <img className="sponsors-component4-sponsor-logo" src={sponsor.logo} alt={isValidString(sponsor.name) ? sponsor.name : 'Sponsor Image'} />
                                                            {/* <p className="sponsors-component4-sponsor-title font-size-xl text-gray m-t-0 m-b-0">{sponsor.name}</p> */}
                                                        </a>
                                                    ) : (
                                                        <div className="sponsors-component4-sponsor m-t-20px m-b-20px text-no-underline" href={createUrl(sponsor.url)}>
                                                            <img className="sponsors-component4-sponsor-logo" src={sponsor.logo} alt={isValidString(sponsor.name) ? sponsor.name : 'Sponsor Image'} />
                                                            {/* <p className="sponsors-component4-sponsor-title font-size-xl text-gray m-t-0 m-b-0">{sponsor.name}</p> */}
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {isValidString(sponsor.url) ? (
                                                        <a className="sponsors-component4-sponsor m-t-20px m-b-20px text-no-underline" href={createUrl(sponsor.url)} target="_blank" rel="noreferrer">
                                                            <p className="sponsors-component4-sponsor-title font-size-4xl text-gray m-t-0 m-b-0">{sponsor.name}</p>
                                                        </a>
                                                    ) : (
                                                        <div className="sponsors-component4-sponsor m-t-20px m-b-20px text-no-underline" href={createUrl(sponsor.url)}>
                                                            <p className="sponsors-component4-sponsor-title font-size-4xl text-gray m-t-0 m-b-0">{sponsor.name}</p>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </>
                        )}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default memo(SponsorsComponent4);
