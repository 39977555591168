import React, { memo, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem } from '@material-ui/core';
import { slide as Menu } from 'react-burger-menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
// import MenuIcon from '../../../../assets/menuIcons/menu.png';
import { context } from '../../../../globalState/GlobalState';
import { isValidObject, isValidString } from '../../../../catalog/Validity';
import { randomNumber } from '../../../../catalog/Others';
import { getNavLinkTargetValue } from '../../../../catalog/Website';
import NavItemCart from './NavItemCart';
import { optimXStoreWebsite } from '../../../../catalog/TeamInfo';

const NavItemsMobile = (props) => {
    const { navItems } = props;
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);
    const { globalState } = useContext(context);

    /**
     * This function is used for opening and closing dishes and categories when menu is pressed
     */
    const handleToggle = () => {
        setMenuOpen((prevOpen) => { return !prevOpen; });
        window.scrollTo(0, 0);
    };

    /**
     * This function is called when popper needs to be closed
     */
    const handleClose = (event) => {
        if (anchorRef?.current?.contains(event.target)) {
            return;
        }
        setMenuOpen(false);
    };

    const getOptions = (item) => {
        if (item.options.length > 0) {
            return (
                <Accordion className="nav-items-accordinan" TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="nav-items-accordinan-summary">
                        <p className="nav-item m-t-0 m-b-0">{item.title}</p>
                    </AccordionSummary>
                    <AccordionDetails className="nav-items-accordinan-details">
                        {item.options.map((option) => {
                            return (
                                <React.Fragment key={randomNumber()}>
                                    {getSubOptions(option)}
                                </React.Fragment>
                            );
                        })}
                    </AccordionDetails>
                </Accordion>
            );
        } if (item.value === '/custom') {
            return (
                <a
                    className="normal-nav-item nav-item text-no-underline full-width MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button"
                    href={item.link}
                    target={getNavLinkTargetValue(item.openNewTab, false)}
                    rel="noreferrer noopener"
                >
                    {item.title}
                </a>
            );
        } if (item.value === '/custompage' || item.value === '/custompages') {
            return (
                <NavLink to={`/pages/${item.link}`} exact style={{ textDecoration: 'none', color: 'black' }} target={getNavLinkTargetValue(item.openNewTab, true)}>
                    <MenuItem className="normal-nav-item nav-item text-no-underline" onClick={handleClose}>{item.title}</MenuItem>
                </NavLink>
            );
        } if (item.value === '/forms') {
            return (
                <NavLink to={`/forms/${item.link}`} exact style={{ textDecoration: 'none', color: 'black' }} target={getNavLinkTargetValue(item.openNewTab, true)}>
                    <MenuItem className="normal-nav-item nav-item text-no-underline" onClick={handleClose}>{item.title}</MenuItem>
                </NavLink>
            );
        }
        return (
            <NavLink to={item.link} exact style={{ textDecoration: 'none', color: 'black' }} target={getNavLinkTargetValue(item.openNewTab, true)}>
                <MenuItem className="normal-nav-item nav-item text-no-underline" onClick={handleClose}>{item.title}</MenuItem>
            </NavLink>
        );
    };

    const getSubOptions = (option) => {
        const getSubOptionLink = (suboption) => {
            if (suboption.value === '/custom') {
                return (
                    <a
                        className="accordian-nav-item nav-item text-no-underline full-width MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button"
                        href={suboption.link}
                        target={getNavLinkTargetValue(suboption.openNewTab, false)}
                        rel="noreferrer noopener"
                    >
                        {suboption.title}
                    </a>
                );
            } if (suboption.value === '/custompage' || suboption.value === '/custompages') {
                return (
                    <NavLink key={randomNumber()} to={`/pages/${suboption.link}`} exact style={{ textDecoration: 'none', color: 'black', width: '100%' }} target={getNavLinkTargetValue(suboption.openNewTab, true)}>
                        <MenuItem className="accordian-nav-item nav-item text-no-underline full-width" onClick={handleClose}>{suboption.title}</MenuItem>
                    </NavLink>
                );
            } if (suboption.value === '/forms') {
                return (
                    <NavLink key={randomNumber()} to={`/forms/${suboption.link}`} exact style={{ textDecoration: 'none', color: 'black', width: '100%' }} target={getNavLinkTargetValue(suboption.openNewTab, true)}>
                        <MenuItem className="accordian-nav-item nav-item text-no-underline full-width" onClick={handleClose}>{suboption.title}</MenuItem>
                    </NavLink>
                );
            }
            return (
                <NavLink key={randomNumber()} to={suboption.link} exact style={{ textDecoration: 'none', color: 'black', width: '100%' }} target={getNavLinkTargetValue(suboption.openNewTab, true)}>
                    <MenuItem className="accordian-nav-item nav-item text-no-underline full-width" onClick={handleClose}>{suboption.title}</MenuItem>
                </NavLink>
            );
        };

        if (isValidObject(option.options) && option.options.length > 0) {
            return (
                <Accordion className="accordian-nav-item-accordinan" TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordian-nav-item-accordinan-summary">
                        <p className="nav-item m-t-0 m-b-0">{option.title}</p>
                    </AccordionSummary>
                    <AccordionDetails className="accordian-nav-item-accordinan-details">
                        {option.options.map((suboption) => {
                            return (
                                <React.Fragment key={randomNumber()}>
                                    {getSubOptionLink(suboption)}
                                </React.Fragment>
                            );
                        })}
                    </AccordionDetails>
                </Accordion>
            );
        } if (option.value === '/custom') {
            return (
                <a
                    className="accordian-nav-item nav-item text-no-underline full-width MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button"
                    href={option.link}
                    target={getNavLinkTargetValue(option.openNewTab, false)}
                    rel="noreferrer noopener"
                >
                    {option.title}
                </a>
            );
        } if (option.value === '/custompage' || option.value === '/custompages') {
            return (
                <NavLink key={randomNumber()} to={`/pages/${option.link}`} exact style={{ textDecoration: 'none', color: 'black', width: '100%' }} target={getNavLinkTargetValue(option.openNewTab, true)}>
                    <MenuItem className="accordian-nav-item nav-item text-no-underline full-width" onClick={handleClose}>{option.title}</MenuItem>
                </NavLink>
            );
        } if (option.value === '/forms') {
            return (
                <NavLink key={randomNumber()} to={`/forms/${option.link}`} exact style={{ textDecoration: 'none', color: 'black', width: '100%' }} target={getNavLinkTargetValue(option.openNewTab, true)}>
                    <MenuItem className="accordian-nav-item nav-item text-no-underline full-width" onClick={handleClose}>{option.title}</MenuItem>
                </NavLink>
            );
        }
        return (
            <NavLink key={randomNumber()} to={option.link} exact style={{ textDecoration: 'none', color: 'black', width: '100%' }} target={getNavLinkTargetValue(option.openNewTab, true)}>
                <MenuItem className="accordian-nav-item nav-item text-no-underline full-width" onClick={handleClose}>{option.title}</MenuItem>
            </NavLink>
        );
    };

    return (
        <div className="nav-items-drop-down2">
            {isValidString(globalState.stripeAccountId) ? <NavItemCart desktop={false} /> : null}
            {!optimXStoreWebsite ? (
                <Menu
                    className="nav-items-drop-down2"
                    right
                    width="100%"
                    isOpen={menuOpen}
                    onOpen={handleToggle}
                    customBurgerIcon={<MenuIcon style={{ color: isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white' }} />}
                    customCrossIcon={<img alt="Close" src={null} />}
                >
                    {/* <MenuIcon className="nav-items-drop-down2-logo1" style={{ color: isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white' }} /> */}
                    {/* <img className="nav-items-drop-down2-logo1" src={MenuIcon} alt="Team Logo" /> */}
                    <div className="p-t-20px">
                        {navItems.map((item) => {
                            return (
                                <React.Fragment key={randomNumber()}>
                                    {getOptions(item)}
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <img className="nav-items-drop-down2-logo2" src={globalState.logo.logo2} alt={isValidString(globalState.teamName) ? globalState.teamName : 'Team Logo'} />
                </Menu>
            ) : null}
        </div>
    );
};

NavItemsMobile.propTypes = {
    navItems: PropTypes.array.isRequired,
};

export default (memo(NavItemsMobile));
