import React, { memo } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { IconButton } from '@material-ui/core';
import './css/CounterComponent.css';

const CounterComponent = (props) => {
    const { count, increment, decrement, disableIncrement, disabled } = props;

    return (
        <div className={`counter-component ${disabled ? 'counter-component-disabled' : ''}`}>
            <IconButton size="small" aria-label="decrement" onClick={decrement} disabled={disabled}>
                <RemoveIcon />
            </IconButton>
            <p className="counter-component-count m-t-0 m-b-0">{count}</p>
            <IconButton size="small" aria-label="increment" onClick={increment} disabled={disabled || disableIncrement}>
                <AddIcon />
            </IconButton>
        </div>
    );
};

CounterComponent.propTypes = {
    count: PropTypes.number.isRequired,
    increment: PropTypes.func.isRequired,
    decrement: PropTypes.func.isRequired,
    disableIncrement: PropTypes.bool,
    disabled: PropTypes.bool,
};

CounterComponent.defaultProps = {
    disableIncrement: false,
    disabled: false,
};

export default memo(CounterComponent);
