/* eslint-disable no-console */
import { sendErrorAnalytics } from '../../catalog/Analytics';
import { SportsType } from '../../catalog/enums/SportsType';
import { TagType } from '../../catalog/enums/TagType';
import { FETCH_SEASONS_ERROR, FETCH_SEASON_ERROR, SEASON_DOES_NOT_EXIST } from '../../catalog/NotificationsComments';
import { optimXTesting } from '../../catalog/TeamInfo';
import { isValidObject } from '../../catalog/Validity';
import { fetchCoachFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsCoach';
import { fetchPlayerFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsPlayer';
import { fetchPreDevelopedSeasonFirestoreAPI, fetchPreDevelopedSeasonsFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsPreDevelopedSeason';
import { fetchSeasonFirestoreAPI, fetchSeasonsFirestoreAPI, fetchSeasonsOfCoachFirestoreAPI, fetchSeasonsOfPlayerFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsSeason';

export const fetchSeasonsAPI = async (teamId: string, setState: Function, snackbar: Function) => {
    const seasonsTemp: any = [];
    let seasons;

    try {
        const docs = await fetchSeasonsFirestoreAPI(teamId);
        docs.forEach((doc) => {
            if (doc.exists) {
                seasonsTemp.push({ ...doc.data() });
            }
        });

        seasons = new Array<any>(seasonsTemp.length);
        await Promise.all(seasonsTemp.map(async (season: any, index: number) => {
            const preDevelopedSeasonDoc = await fetchPreDevelopedSeasonFirestoreAPI(season.preDevelopedSeasonId);
            if (preDevelopedSeasonDoc.exists && preDevelopedSeasonDoc.exists) {
                seasons[index] = { ...season,
                    name: preDevelopedSeasonDoc?.data()?.name,
                    logo: preDevelopedSeasonDoc?.data()?.logo };
            }
        }));
        setState({ type: 'fetchSeasons', seasons });
    } catch (error) {
        sendErrorAnalytics(FETCH_SEASONS_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_SEASONS_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchSeasonPlayersAPI = async (teamId: string, seasonId: string, setState: Function, snackbar: Function) => {
    try {
        const seasonDoc = await fetchSeasonFirestoreAPI(teamId, seasonId);
        if (seasonDoc.exists) {
            const season = seasonDoc.data();
            const tagIds = season?.tagIds;
            const tagTypes = season?.tagTypes;
            const players = Array<any>(0);

            await Promise.all(tagIds.map(async (tagId: any, index: number) => {
                if (tagTypes[index] === TagType.PLAYER) {
                    const playerDoc = await fetchPlayerFirestoreAPI(tagId);
                    if (playerDoc.exists && isValidObject(playerDoc.data())) {
                        const player = playerDoc.data();
                        if (!player?.deleted) {
                            players.push(player);
                        }
                    }
                }
            }));

            setState({ type: 'fetchPlayers', players });
        } else if (optimXTesting) {
            snackbar(SEASON_DOES_NOT_EXIST, { variant: 'error' });
        } else {
            sendErrorAnalytics(SEASON_DOES_NOT_EXIST);
        }
    } catch (error) {
        sendErrorAnalytics(FETCH_SEASON_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_SEASON_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchSeasonCoachAPI = async (teamId: string, seasonId: string, setState: Function, snackbar: Function) => {
    try {
        const seasonDoc = await fetchSeasonFirestoreAPI(teamId, seasonId);
        if (seasonDoc.exists) {
            const season = seasonDoc.data();
            const tagIds = season?.tagIds;
            const tagTypes = season?.tagTypes;
            const coaches = Array<any>(0);

            await Promise.all(tagIds.map(async (tagId: any, index: number) => {
                if (tagTypes[index] === TagType.COACH) {
                    const playerDoc = await fetchCoachFirestoreAPI(tagId);
                    if (playerDoc.exists && isValidObject(playerDoc.data())) {
                        const player = playerDoc.data();
                        if (!player?.deleted) {
                            coaches.push(player);
                        }
                    }
                }
            }));

            setState({ type: 'fetchCoaches', coaches });
        } else if (optimXTesting) {
            snackbar(SEASON_DOES_NOT_EXIST, { variant: 'error' });
        } else {
            sendErrorAnalytics(SEASON_DOES_NOT_EXIST);
        }
    } catch (error) {
        sendErrorAnalytics(FETCH_SEASON_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_SEASON_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchSeasonAPI = async (teamId: string, seasonId: string, setState: Function, snackbar: Function) => {
    try {
        const doc = await fetchSeasonFirestoreAPI(teamId, seasonId);
        if (doc.exists) {
            const season = doc.data();
            const preDevelopedSeasonDoc: any = await fetchPreDevelopedSeasonFirestoreAPI(season?.preDevelopedSeasonId);
            setState({ type: 'fetchSeason',
                preDevelopedSeasonId: season?.preDevelopedSeasonId,
                name: preDevelopedSeasonDoc.data().name,
                logo: preDevelopedSeasonDoc.data().logo });
        } else {
            sendErrorAnalytics(SEASON_DOES_NOT_EXIST);
            setState({ type: 'setError' });
            if (optimXTesting) {
                snackbar(SEASON_DOES_NOT_EXIST, { variant: 'error' });
            }
        }
    } catch (error) {
        sendErrorAnalytics(FETCH_SEASON_ERROR);
        setState({ type: 'setError' });
        if (optimXTesting) {
            snackbar(FETCH_SEASON_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchPreDevelopedSeasonsAPI = async (sportsType: SportsType, setState: Function, snackbar: Function) => {
    const preDevelopedSeasons: any = [];
    try {
        const docs = await fetchPreDevelopedSeasonsFirestoreAPI(sportsType);
        docs.forEach((doc) => {
            if (doc.exists) {
                preDevelopedSeasons.push({ ...doc.data() });
            }
        });

        setState({ type: 'fetchPreDevelopedSeasons', preDevelopedSeasons });
    } catch (error) {
        sendErrorAnalytics(FETCH_SEASONS_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_SEASONS_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchSeasonsofPlayersAPI = async (teamId: string, playerId: string, setState: Function, snackbar: Function) => {
    const seasonsTemp: any = [];
    let seasons;
    try {
        const docs = await fetchSeasonsOfPlayerFirestoreAPI(teamId, playerId);
        docs.forEach((doc) => {
            if (doc.exists) {
                seasonsTemp.push({ ...doc.data() });
            }
        });

        seasons = new Array<any>(seasonsTemp.length);
        await Promise.all(seasonsTemp.map(async (season: any, index: number) => {
            const preDevelopedSeasonDoc = await fetchPreDevelopedSeasonFirestoreAPI(season.preDevelopedSeasonId);
            if (preDevelopedSeasonDoc.exists && preDevelopedSeasonDoc.exists) {
                seasons[index] = { ...season,
                    name: preDevelopedSeasonDoc?.data()?.name,
                    logo: preDevelopedSeasonDoc?.data()?.logo };
            }
        }));
        setState({ type: 'fetchSeasons', seasons });
    } catch (error) {
        sendErrorAnalytics(FETCH_SEASONS_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_SEASONS_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};

export const fetchSeasonsofCoachesAPI = async (teamId: string, coachId: string, setState: Function, snackbar: Function) => {
    const seasonsTemp: any = [];
    let seasons;
    try {
        const docs = await fetchSeasonsOfCoachFirestoreAPI(teamId, coachId);
        docs.forEach((doc) => {
            if (doc.exists) {
                seasonsTemp.push({ ...doc.data() });
            }
        });

        seasons = new Array<any>(seasonsTemp.length);
        await Promise.all(seasonsTemp.map(async (season: any, index: number) => {
            const preDevelopedSeasonDoc = await fetchPreDevelopedSeasonFirestoreAPI(season.preDevelopedSeasonId);
            if (preDevelopedSeasonDoc.exists && preDevelopedSeasonDoc.exists) {
                seasons[index] = { ...season,
                    name: preDevelopedSeasonDoc?.data()?.name,
                    logo: preDevelopedSeasonDoc?.data()?.logo };
            }
        }));
        setState({ type: 'fetchSeasons', seasons });
    } catch (error) {
        sendErrorAnalytics(FETCH_SEASONS_ERROR);
        if (optimXTesting) {
            snackbar(FETCH_SEASONS_ERROR, { variant: 'error' });
            console.log(error);
        }
    }
};
