import { isValidObject } from './Validity';

/**
 * This function gets the current date and time
 */
export const getCurrentDateTime = (): Date => {
    const myDate = new Date();
    myDate.setSeconds(0);
    myDate.setMilliseconds(0);
    return myDate;
};

export const getDateTimeForLastWeek = (): Date => {
    const myDate = new Date();
    myDate.setSeconds(0);
    myDate.setMilliseconds(0);
    myDate.setDate(myDate.getDate() - 7);
    return myDate;
};

export const increaseHoursInDate = (myDate: Date, increaseBy: number) => {
    const newDate = new Date(myDate);
    newDate.setHours(newDate.getHours() + increaseBy);
    return newDate;
};

export const getCurrentYearInString = (): string => {
    const myDate = new Date();
    return `${myDate.getFullYear()}`;
};

export const getMinutesFromSeconds = (seconds: number): number => {
    return Math.floor(seconds / 60);
};

export const getSecondsFromSeconds = (seconds: number): number => {
    return seconds - (Math.floor(seconds / 60) * 60);
};

export const getMinutesAndSecondsStringFromSeconds = (seconds: number): string => {
    try {
        if (seconds <= 0) {
            return '0:00';
        }
        const minutes = getMinutesFromSeconds(seconds);
        const newSeconds = getSecondsFromSeconds(seconds) < 10 ? `0${getSecondsFromSeconds(seconds)}` : getSecondsFromSeconds(seconds);

        return `${minutes}:${newSeconds}`;
    } catch (error) {
        return '0:00';
    }
};

export const getTimeInAMPMFromDate = (date: Date) => {
    let hour = date.getHours();
    const minute = date.getMinutes();
    const PM = hour > 11;
    hour -= hour > 12 ? 12 : 0;
    return `${hour < 10 ? '0' : ''}${hour.toString()}:${minute < 10 ? '0' : ''}${minute.toString()}${PM ? 'PM' : 'AM'} ${getTimeZone(date)}`;
};

export const getDateStringFromDate = (date: Date): string => {
    if (!isValidObject(date)) {
        return '';
    }
    let finalDate;
    if (date.getDate() < 10) {
        finalDate = `0${date.getDate()}`;
    } else {
        finalDate = date.getDate();
    }
    return `${finalDate}`;
};

export const getMonthStringFromDate = (date: Date): string => {
    if (!isValidObject(date)) {
        return '';
    }
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
    return monthNames[date.getMonth()];
};

export const getYearStringFromDate = (date: Date): string => {
    if (!isValidObject(date)) {
        return '';
    }
    return `${date.getFullYear()}`;
};

export const getFullDateStringFromDate = (date: Date): string => {
    if (!isValidObject(date)) {
        return '';
    }
    return `${getMonthStringFromDate(date)} ${getDateStringFromDate(date)}, ${getYearStringFromDate(date)}`;
};

export const getFullDateForAnalyticsStringFromDate = (date: Date): string => {
    if (!isValidObject(date)) {
        return '';
    }
    return `${getMonthStringFromDate(date)}-${getDateStringFromDate(date)}-${getYearStringFromDate(date)}`;
};

export const getMonthYearStringFromDate = (date: Date): string => {
    if (!isValidObject(date)) {
        return '';
    }
    return `${getMonthStringFromDate(date)} ${getYearStringFromDate(date)}`;
};

export const getDateTimeStringFromDate = (date: Date): string => {
    if (!isValidObject(date)) {
        return '';
    }
    return `${getFullDateStringFromDate(date)} ${getTimeInAMPMFromDate(date)}`;
};

export const getLocalTimeFromDatabaseTime = (time: any): Date => {
    if (time instanceof Date) {
        return time;
    }
    return time.toDate();
};

export const getTimeZone = (date: Date) => {
    const timezone = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
    if (timezone.includes('GMT')) {
        return '';
    }
    return timezone;
};
