import React, { memo, useReducer, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import Spinner1 from '../../../uiElements/spinners/spinner1/Spinner1';
import { context } from '../../../../globalState/GlobalState';
import { fetchTicketForCartAPI } from '../../../apiCalls/TicketAPICalls';
import CounterComponent from '../../../uiElements/storeComponents/counterComponent/CounterComponent';
import { changeCartQuantity, removeTicketFromCartUsingCartItemId } from '../../../../catalog/Store';
import { showPriceFromCents } from '../../../../catalog/Price';
import { isValidString } from '../../../../catalog/Validity';

const initialState = {
    ticketTitle: '',
    ticketFeatureImage: '',
    ticketPrices: [],
    ticketPrice: {},
    ticketsTotal: 0,
    ticketsSold: 0,
    ticketsHold: 0,
    eventTitle: '',
    eventFeatureImage: '',
    loadingSpinner: true,
};

function reducer(state, action) {
    switch (action.type) {
        case 'fetchTicket':
            return { ...state,
                ticketTitle: action.title,
                ticketFeatureImage: action.featureImage,
                ticketPrices: action.prices,
                ticketsTotal: action.ticketsTotal,
                ticketsSold: action.ticketsSold,
                ticketsHold: action.ticketsHold,
                eventTitle: action.eventTitle,
                eventFeatureImage: action.eventFeatureImage,
            };
        case 'setPrice':
            return { ...state, ticketPrice: action.ticketPrice };
        case 'setLoadingSpinner':
            return { ...state, loadingSpinner: action.loadingSpinner };
        default:
            return initialState;
    }
}

const NavItemCartTicketItem = (props) => {
    const { cartItemId, ticketId, quantity, setPrice } = props;
    const [state, setState] = useReducer(reducer, initialState);
    const { globalState, setGlobalState } = useContext(context);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        async function fetchTicket() {
            setState({ type: 'setLoadingSpinner', loadingSpinner: true });
            await fetchTicketForCartAPI(globalState.accountId, ticketId, setState, setGlobalState, enqueueSnackbar);
            setState({ type: 'setLoadingSpinner', loadingSpinner: false });
        }

        fetchTicket();
    }, [ticketId]);

    useEffect(() => {
        setPrice(state.ticketPrice.price * quantity);
    }, [state.ticketPrice, quantity]);

    useEffect(() => {
        if (isValidString(state.ticketTitle)) {
            let priceFound = false;
            state.ticketPrices.forEach((price) => {
                if (price.id === cartItemId) {
                    priceFound = true;
                    setState({ type: 'setPrice', ticketPrice: price });
                }
            });
            if (!priceFound) {
                removeTicketFromCartUsingCartItemId(cartItemId, setGlobalState);
            }
        }
    }, [ticketId, cartItemId, state.ticketPrices]);

    return (
        <React.Fragment key={cartItemId}>
            {state.loadingSpinner ? (<Spinner1 color={globalState.websiteStyles.colors.primaryColor} />) : (
                <div key={cartItemId} className="nav-item-cart-item p-t-50px">
                    <p className="nav-item-cart-item-label text-uppercase m-t-0 m-b-0">Ticket</p>
                    {isValidString(state.eventFeatureImage) ? <img className="nav-item-cart-item-image" src={state.eventFeatureImage} alt={state.eventTitle} /> : null}
                    <p className="nav-item-cart-item-title m-t-0 m-b-0">{`${state.eventTitle} | ${state.ticketTitle} | ${state.ticketPrice.title}`}</p>
                    <div className="nav-item-cart-item-quantity">
                        <CounterComponent
                            count={quantity}
                            increment={() => { changeCartQuantity(cartItemId, quantity + 1, setGlobalState); }}
                            decrement={() => { changeCartQuantity(cartItemId, quantity - 1, setGlobalState); }}
                        />
                        <IconButton
                            aria-label="delete"
                            size="medium"
                            onClick={() => {
                                removeTicketFromCartUsingCartItemId(cartItemId, setGlobalState);
                                setPrice(0);
                            }}
                        >
                            <DeleteIcon style={{ color: 'red' }} />
                        </IconButton>
                    </div>
                    <p className="nav-item-cart-item-price m-t-0 m-b-0">{`Total: ${showPriceFromCents(quantity * state.ticketPrice.price)}`}</p>
                </div>
            )}
        </React.Fragment>
    );
};

NavItemCartTicketItem.propTypes = {
    cartItemId: PropTypes.string.isRequired,
    ticketId: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    setPrice: PropTypes.func.isRequired,
};

export default (memo(NavItemCartTicketItem));
