import React, { Suspense, lazy, useReducer, createRef } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Button } from '@material-ui/core';
import SimpleReactLightbox from 'simple-react-lightbox';
import { defaultState, reducer, Provider } from '../globalState/GlobalState';
import Spinner1 from '../components/uiElements/spinners/spinner1/Spinner1';
import ActivationAndDesignCheck from '../components/uiElements/activationAndDesignCheck/ActivationAndDesignCheck';
// import NavBar1 from '../components/website/uiElements/navBar/NavBar1';
import NavBar2 from '../components/website/uiElements/navBar/NavBar2';
import Footer from '../components/website/uiElements/footer/Footer';
import FullMetaTags from '../components/website/uiElements/helmet/FullMetaTags';
import ScrollToTop from '../components/uiElements/scrollToTop/ScrollToTop';
import { isValidObject, isValidString } from '../catalog/Validity';
import FabComponent from '../components/uiElements/storeComponents/fabComponents/FabComponent';
import { optimXStoreWebsite } from '../catalog/TeamInfo';

const HomePage = lazy(() => { return import('../components/website/homePage/HomePage'); });
const NotFound = lazy(() => { return import('../components/website/notFound/NotFound'); });
const Version = lazy(() => { return import('../components/website/version/Version'); });
const Game = lazy(() => { return import('../components/website/game/Game'); });
const Coaches = lazy(() => { return import('../components/website/coaches/Coaches'); });
const Staffs = lazy(() => { return import('../components/website/staffs/Staffs'); });
const SeasonPlayersAndCoaches = lazy(() => { return import('../components/website/seaonPlayersAndCoaches/SeasonPlayersAndCoaches'); });
const Coach = lazy(() => { return import('../components/website/coach/Coach'); });
const Roster = lazy(() => { return import('../components/website/roster/Roster'); });
const CoachesAndStaffs = lazy(() => { return import('../components/website/coachesAndStaffs/CoachesAndStaffs'); });
const RosterStats = lazy(() => { return import('../components/website/rosterStats/RosterStats'); });
const TeamStats = lazy(() => { return import('../components/website/teamStats/TeamStats'); });
const Player = lazy(() => { return import('../components/website/player/Player'); });
const Sponsors = lazy(() => { return import('../components/website/sponsors/Sponsors'); });
const About = lazy(() => { return import('../components/website/about/About'); });
const Articles = lazy(() => { return import('../components/website/articles/Articles'); });
const Article = lazy(() => { return import('../components/website/article/Article'); });
const Form = lazy(() => { return import('../components/website/form/Form'); });
const Galleries = lazy(() => { return import('../components/website/galleries/Galleries'); });
const Gallery = lazy(() => { return import('../components/website/gallery/Gallery'); });
const Schedule = lazy(() => { return import('../components/website/schedule/Schedule'); });
const Season = lazy(() => { return import('../components/website/season/Season'); });
const Events = lazy(() => { return import('../components/website/events/Events'); });
const Event = lazy(() => { return import('../components/website/event/Event'); });
const Practices = lazy(() => { return import('../components/website/practices/Practices'); });
const Practice = lazy(() => { return import('../components/website/practice/Practice'); });
const EventsAndPractice = lazy(() => { return import('../components/website/eventsAndPractice/EventsAndPractice'); });
const PreviousRosters = lazy(() => { return import('../components/website/previousRosters/PreviousRosters'); });
const PreviousSeasons = lazy(() => { return import('../components/website/previousSeasons/PreviousSeasons'); });
const CustomPage = lazy(() => { return import('../components/website/customPage/CustomPage'); });
const Products = lazy(() => { return import('../components/website/products/Products'); });
const Product = lazy(() => { return import('../components/website/product/Product'); });
const Tickets = lazy(() => { return import('../components/website/tickets/Tickets'); });
const Ticket = lazy(() => { return import('../components/website/ticket/Ticket'); });
const SuccessPage = lazy(() => { return import('../components/uiElements/storeComponents/successPage/SuccessPage'); });

function App() {
    const [globalState, setGlobalState] = useReducer(reducer, defaultState);

    const notistackRef = createRef();
    const onClickDismiss = (key) => {
        return () => {
            notistackRef.current.closeSnackbar(key);
        };
    };

    return (
        <>
            <BrowserRouter>
                <SnackbarProvider
                    dense
                    hideIconVariant
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    maxSnack={10}
                    ref={notistackRef}
                    action={(key) => {
                        return (
                            <Button style={{ color: 'white', fontWeight: 800 }} onClick={onClickDismiss(key)}>
                                X
                            </Button>
                        );
                    }}
                >
                    <SimpleReactLightbox>
                        <Provider value={{ globalState, setGlobalState }}>
                            <ActivationAndDesignCheck>
                                <FullMetaTags />
                                <NavBar2 />
                                <main>
                                    <Suspense fallback={<Spinner1 color={isValidObject(globalState) && isValidObject(globalState.websiteStyles) && isValidObject(globalState.websiteStyles.colors) && isValidString(globalState.websiteStyles.colors.primaryColor) ? globalState.websiteStyles.colors.primaryColor : 'black'} />}>
                                        <ScrollToTop>
                                            <Switch>
                                                {!optimXStoreWebsite ? <Route path="/about" exact render={() => { return <About />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/sponsors" exact render={() => { return <Sponsors />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/articles" exact render={() => { return <Articles />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/articles/:articleid" exact render={() => { return <Article />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/galleries" exact render={() => { return <Galleries />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/galleries/:galleryid" exact render={() => { return <Gallery />; }} /> : null}
                                                {!optimXStoreWebsite ? <Redirect from="/schedule" exact to="/schedule/upcoming" /> : null}
                                                {!optimXStoreWebsite ? <Route path="/schedule/upcoming" exact render={() => { return <Schedule />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/schedule/results" exact render={() => { return <Schedule />; }} /> : null}
                                                {!optimXStoreWebsite ? <Redirect from="/seasonschedule/:seasonid" exact to="/seasonschedule/:seasonid/upcoming" /> : null}
                                                {!optimXStoreWebsite ? <Route path="/seasonschedule/:seasonid/upcoming" render={() => { return <Season />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/seasonschedule/:seasonid/results" render={() => { return <Season />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/staff" exact render={() => { return <CoachesAndStaffs />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/coaches" exact render={() => { return <Coaches />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/coaches/:coachid" exact render={() => { return <Coach />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/executiveboard" exact render={() => { return <Staffs />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/roster" exact render={() => { return <Roster />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/alumni" exact render={() => { return <Roster />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/rosterstats" exact render={() => { return <RosterStats />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/alumnistats" exact render={() => { return <RosterStats />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/teamstats" exact render={() => { return <TeamStats />; }} /> : null}
                                                {!optimXStoreWebsite ? <Redirect from="/alumnis" exact to="/alumni" /> : null}
                                                {!optimXStoreWebsite ? <Route path="/roster/:playerid" exact render={() => { return <Player />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/season/:seasonid" exact render={() => { return <SeasonPlayersAndCoaches />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/previousrosters" render={() => { return <PreviousRosters />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/previousseasons" render={() => { return <PreviousSeasons />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/pages/:custompageid" render={() => { return <CustomPage />; }} /> : null}
                                                {!optimXStoreWebsite ? <Redirect from="/pages" exact to="/" /> : null}
                                                {!optimXStoreWebsite ? <Redirect from="/games/:gameid" exact to="/games/:gameid/info" /> : null}
                                                {!optimXStoreWebsite ? <Route path="/games/:gameid" render={() => { return <Game />; }} /> : null}
                                                {!optimXStoreWebsite ? <Redirect from="/games" exact to="/" /> : null}
                                                {!optimXStoreWebsite ? <Redirect from="/eventsandpractice" exact to="/eventsandpractice/upcoming" /> : null}
                                                {!optimXStoreWebsite ? <Route path="/eventsandpractice/upcoming" exact render={() => { return <EventsAndPractice />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/eventsandpractice/past" exact render={() => { return <EventsAndPractice />; }} /> : null}
                                                {!optimXStoreWebsite ? <Redirect from="/practice" exact to="/practice/upcoming" /> : null}
                                                {!optimXStoreWebsite ? <Route path="/practice/upcoming" exact render={() => { return <Practices />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/practice/past" exact render={() => { return <Practices />; }} /> : null}
                                                {!optimXStoreWebsite ? <Route path="/practice/:practiceid" exact render={() => { return <Practice />; }} /> : null}

                                                <Redirect from="/events" exact to="/events/upcoming" />
                                                <Route path="/events/upcoming" exact render={() => { return <Events />; }} />
                                                <Route path="/events/past" exact render={() => { return <Events />; }} />
                                                <Route path="/events/:eventid" exact render={() => { return <Event />; }} />
                                                <Route path="/store" exact render={() => { return <Products />; }} />
                                                <Redirect from="/shop" exact to="/store" />
                                                <Redirect from="/products" exact to="/store" />
                                                <Route path="/products/:productid" exact render={() => { return <Product />; }} />
                                                <Route path="/storesuccess" exact render={() => { return <SuccessPage />; }} />
                                                <Route path="/tickets" exact render={() => { return <Tickets />; }} />
                                                <Route path="/tickets/:ticketid" exact render={() => { return <Ticket />; }} />
                                                <Route path="/forms/:formid" exact render={() => { return <Form />; }} />
                                                <Redirect from="/forms" exact to="/" />

                                                <Route path="/version" exact render={() => { return <Version />; }} />
                                                <Route path="/notfound" component={NotFound} status={404} />
                                                {optimXStoreWebsite ? <Route path="/" exact render={() => { return <Products />; }} /> : <Route path="/" exact render={() => { return <HomePage />; }} />}
                                                <Route path="*" component={NotFound} status={404} />
                                                <Route render={() => { return <Redirect to={{ pathname: '/' }} />; }} />
                                            </Switch>
                                        </ScrollToTop>
                                    </Suspense>
                                    <FabComponent />
                                </main>
                                <Footer />
                            </ActivationAndDesignCheck>
                        </Provider>
                    </SimpleReactLightbox>
                </SnackbarProvider>
            </BrowserRouter>
        </>
    );
}

export default App;
