import { getCurrentDateTime } from '../../catalog/DateTime';
import { db } from '../FirebaseLibrary';

export const createTicketsReference = () => {
    return db.collection('tickets');
};

export const createTicketReference = (ticketId: string) => {
    return createTicketsReference().doc(ticketId);
};

export const fetchTicketFirestoreAPI = (ticketId: string) => {
    return createTicketReference(ticketId).get();
};

export const fetchTicketsFirestoreAPI = (teamId: string) => {
    return createTicketsReference()
        .where('teamId', '==', teamId)
        .where('endTimeOfEvent', '>', getCurrentDateTime())
        .where('published', '==', true)
        .where('deleted', '==', false)
        .orderBy('endTimeOfEvent')
        .orderBy('timeOfEvent')
        .get();
};

export const fetchTicketsByEventIdFirestoreAPI = (teamId: string, eventId: string) => {
    return createTicketsReference()
        .where('teamId', '==', teamId)
        .where('endTimeOfEvent', '>', getCurrentDateTime())
        .where('eventId', '==', eventId)
        .where('published', '==', true)
        .where('deleted', '==', false)
        .orderBy('endTimeOfEvent', 'desc')
        .orderBy('timeCreated')
        .get();
};
