/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import AdComponent1Header from './AdComponent1Header';
import { optimXStoreWebsite, optimXTestWebsite } from '../../../../catalog/TeamInfo';
import { context } from '../../../../globalState/GlobalState';

const AdComponent1 = (props) => {
    const { globalState } = useContext(context);
    const { showDesktop, showMobile, desktopContainerId, mobileContainerId, desktopSizes, mobileSizes, placementId } = props;

    const getMobileSizes = () => {
        if (isTablet) {
            return desktopSizes;
        }
        return mobileSizes;
    };

    return (
        <></>
        // <div className="x-center">
        //     {globalState.showAds && showDesktop && !optimXTestWebsite && !optimXStoreWebsite ? (
        //         <>
        //             <BrowserView>
        //                 <div id={desktopContainerId}>
        //                 </div>
        //                 <AdComponent1Header show={showDesktop} containerId={desktopContainerId} sizes={desktopSizes} placementId={placementId} />
        //             </BrowserView>
        //         </>
        //     ) : null}
        //     {globalState.showAds && showMobile && !optimXTestWebsite && !optimXStoreWebsite ? (
        //         <>
        //             <MobileView>
        //                 <div id={mobileContainerId}>
        //                 </div>
        //                 <AdComponent1Header show={showMobile} containerId={mobileContainerId} sizes={getMobileSizes()} placementId={placementId} />
        //             </MobileView>
        //         </>
        //     ) : null}
        // </div>
    );
};

AdComponent1.propTypes = {
    showDesktop: PropTypes.bool,
    showMobile: PropTypes.bool,
    desktopContainerId: PropTypes.string,
    mobileContainerId: PropTypes.string,
    desktopSizes: PropTypes.array,
    mobileSizes: PropTypes.array,
    placementId: PropTypes.string,
};
AdComponent1.defaultProps = {
    showDesktop: false,
    showMobile: false,
    desktopContainerId: 'ads-container',
    mobileContainerId: 'ads-container',
    desktopSizes: ['leaderBoard'],
    mobileSizes: ['smartphone1', 'smartphone2', 'halfBanner'],
    // desktopSizes: ['leaderBoard', 'billboard', 'popUnder'],
    // mobileSizes: ['smartphone1', 'smartphone2', 'halfBanner', 'medRec', 'square', 'largeRec'],
    placementId: '',
};

export default AdComponent1;
