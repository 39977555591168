import React, { memo, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';

import { context } from '../../../../globalState/GlobalState';
import { isValidString } from '../../../../catalog/Validity';
import { getNavLinkTargetValue } from '../../../../catalog/Website';

const NavItemsDesktopWithDropDownWithDropDown = (props) => {
    const { options, menuOpen, setMenuOpen, anchorRef } = props;
    const { globalState } = useContext(context);

    /**
     * This function is called when close is called on dishes and categories
     */
    function handleClose(event) {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setMenuOpen(false);
    }

    const styles = StyleSheet.create({
        menulist: {
            borderBottomStyle: 'solid',
            borderBottomWidth: '4px',
            borderBottomColor: globalState.websiteStyles.colors.primaryColor,
        },
        menuitem: {
            ':hover': {
                backgroundColor: globalState.websiteStyles.colors.primaryColor,
                color: isValidString(globalState.websiteStyles.colors.fontColor) ? `${globalState.websiteStyles.colors.fontColor}` : 'white',
                transitionDuration: '0.2s',
            },
        },
    });

    const getLink = (option) => {
        if (option.value === '/custom') {
            return (
                <a
                    className="text-no-underline"
                    href={option.link}
                    target={getNavLinkTargetValue(option.openNewTab, false)}
                    rel="noreferrer noopener"
                >
                    <MenuItem className={`nav-item-with-drop-down p-t-10px p-b-10px p-l-25px p-r-25px ${css(styles.menuitem)}`} onClick={handleClose}>{option.title}</MenuItem>
                </a>
            );
        } if (option.value === '/custompage' || option.value === '/custompages') {
            return (
                <NavLink
                    style={{ textDecoration: 'none', color: 'black' }}
                    to={`/pages/${option.link}`}
                    exact
                    target={getNavLinkTargetValue(option.openNewTab, true)}
                >
                    <MenuItem className={`nav-item-with-drop-down p-t-10px p-b-10px p-l-25px p-r-25px ${css(styles.menuitem)}`} onClick={handleClose}>{option.title}</MenuItem>
                </NavLink>
            );
        } if (option.value === '/forms') {
            return (
                <NavLink
                    style={{ textDecoration: 'none', color: 'black' }}
                    to={`/forms/${option.link}`}
                    exact
                    target={getNavLinkTargetValue(option.openNewTab, true)}
                >
                    <MenuItem className={`nav-item-with-drop-down p-t-10px p-b-10px p-l-25px p-r-25px ${css(styles.menuitem)}`} onClick={handleClose}>{option.title}</MenuItem>
                </NavLink>
            );
        }
        return (
            <NavLink
                style={{ textDecoration: 'none', color: 'black' }}
                to={option.link}
                exact
                target={getNavLinkTargetValue(option.openNewTab, true)}
            >
                <MenuItem className={`nav-item-with-drop-down p-t-10px p-b-10px p-l-25px p-r-25px ${css(styles.menuitem)}`} onClick={handleClose}>{option.title}</MenuItem>
            </NavLink>
        );
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Popper open={menuOpen} anchorEl={anchorRef.current} transition disablePortal placement="right-start">
                {({ TransitionProps, placement }) => {
                    return (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                        >
                            <Paper className="curve-border-0">
                                <MenuList className={`p-t-0 p-b-0 ${css(styles.menulist)}`}>
                                    {options.map((option) => {
                                        return (
                                            <div key={option.title}>
                                                {getLink(option)}
                                            </div>
                                        );
                                    })}
                                </MenuList>
                            </Paper>
                        </Grow>
                    );
                }}
            </Popper>
        </ClickAwayListener>
    );
};

NavItemsDesktopWithDropDownWithDropDown.propTypes = {
    options: PropTypes.array.isRequired,
    menuOpen: PropTypes.bool.isRequired,
    setMenuOpen: PropTypes.func.isRequired,
    anchorRef: PropTypes.object.isRequired,
};

export default (memo(NavItemsDesktopWithDropDownWithDropDown));
