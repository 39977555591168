import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const Spinner1 = (props) => {
    const { show, size, color, marginTop, marginBottom } = props;
    return (
        <CircularProgress
            className="x-center m-t-20px m-b-20px"
            variant="indeterminate"
            size={size}
            thickness={2}
            style={{
                display: (show ? 'block' : 'none'),
                color,
                marginTop: `${marginTop}px`,
                marginBottom: marginBottom === -1 ? 'auto' : `${marginBottom}px`,
            }}
        />
    );
};

Spinner1.propTypes = {
    show: PropTypes.bool,
    size: PropTypes.number,
    color: PropTypes.string,
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
};

Spinner1.defaultProps = {
    show: true,
    size: 100,
    color: 'black',
    marginTop: 250,
    marginBottom: -1,
};

export default Spinner1;
