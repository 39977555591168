import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import './css/SeasonThumbnail1.css';
import { NavLink } from 'react-router-dom';
import { isValidObject, isValidString } from '../../../../catalog/Validity';
import { context } from '../../../../globalState/GlobalState';

const SeasonThumbnail1 = (props) => {
    const { season } = props;
    const { globalState } = useContext(context);

    const getImage = () => {
        if (!isValidObject(globalState) || !isValidObject(globalState.websiteImages)) {
            return null;
        }
        let featureImage = null;
        globalState.websiteImages.forEach((image) => {
            if (image.id === 'featureImage1') {
                if (isValidString(image.image)) {
                    featureImage = image.image;
                } else {
                    featureImage = null;
                }
            }
        });

        if (!isValidObject(featureImage)) {
            return null;
        }
        return featureImage;
    };

    return (
        <NavLink to={`/seasonschedule/${season.id}/upcoming`}>
            <div className="season-thumbnail1-container text-left-align full-width curve-border-5px text-black text-no-underline">
                <img className="season-thumbnail1-image" src={getImage()} alt={isValidString(season.name) ? `Season ${season.name}` : 'Season Title'} />
                <div className="season-thumbnail1-text-container">
                    <p className="season-thumbnail1-text m-t-0 m-b-0 p-t-10px p-b-10px p-l-20px p-r-20px font-size-xl font-weight-800 text-white normal-font-family">{`Season ${season.name}`}</p>
                </div>
            </div>
        </NavLink>
    );
};

SeasonThumbnail1.propTypes = {
    season: PropTypes.object.isRequired,
};

export default memo(SeasonThumbnail1);
