import { getCurrentDateTime, getLocalTimeFromDatabaseTime, increaseHoursInDate } from './DateTime';
import { isValidObject, isValidString } from './Validity';

export const DEFAULT_RIBBONS = {
    ALL: 'All',
    ON_SALE: 'On Sale',
    SOLD_OUT: 'Sold Out',
    FUNDRAISER: 'Fundraiser',
    NEW_ARRIVAL: 'New Arrival',
    JUST_LAUNCHED: 'Just Launched',
    ALMOST_GONE: 'Almost Gone',
    ALUMNI_GAME: 'Alumni Game',
    RIVAL_GAME: 'Rival Game',
    ALUMNI_EVENT: 'Alumni Event',
    SPECIAL_EDITION: 'Special Edition',
    SPECIAL_EVENT: 'Special Event',
    SPECIAL_GAME: 'Special Game',
};

export const checkProductsValidity = (globalState: any, ignoreLinkedProducts: boolean = false) => {
    if (!ignoreLinkedProducts && globalState.linkedProductsExist) {
        return true;
    }
    if (!isValidString(globalState.stripeAccountId)) {
        return false;
    }
    if (!globalState.storeValidity.productsActivate) {
        return false;
    }
    if (!isValidObject(globalState.storeAdminValidity.client)) {
        return false;
    }
    if (!globalState.storeAdminValidity.client.storeActive) {
        return false;
    }
    if (!globalState.storeAdminValidity.client.productsActive) {
        return false;
    }

    return true;
};

export const checkTicketsValidity = (globalState: any) => {
    if (!isValidString(globalState.stripeAccountId)) {
        return false;
    }
    if (!globalState.storeValidity.ticketsActivate) {
        return false;
    }
    if (!isValidObject(globalState.storeAdminValidity.client)) {
        return false;
    }
    if (!globalState.storeAdminValidity.client.storeActive) {
        return false;
    }
    if (!globalState.storeAdminValidity.client.ticketsActive) {
        return false;
    }

    return true;
};

export const checkFormsValidity = (globalState: any) => {
    if (!isValidString(globalState.stripeAccountId)) {
        return false;
    }
    if (!globalState.storeValidity.formsActivate) {
        return false;
    }
    if (!isValidObject(globalState.storeAdminValidity.client)) {
        return false;
    }
    if (!globalState.storeAdminValidity.client.storeActive) {
        return false;
    }
    if (!globalState.storeAdminValidity.client.formsActive) {
        return false;
    }

    return true;
};

export const showProducts = (globalState: any, products: any[]) => {
    if (!checkProductsValidity(globalState)) {
        return false;
    }

    if (!isValidObject(products)) {
        return false;
    }

    if (products.length <= 0) {
        return false;
    }

    let showProductsLocal = false;
    products.forEach((product) => {
        if (product.published && !product.archived && !product.deleted) {
            showProductsLocal = true;
        }
    });
    return showProductsLocal;
};

export const showTickets = (globalState: any, tickets: any[]) => {
    if (!checkTicketsValidity(globalState)) {
        return false;
    }

    if (!isValidObject(tickets)) {
        return false;
    }

    if (tickets.length <= 0) {
        return false;
    }

    let showTicketsLocal = false;
    tickets.forEach((ticket) => {
        if (ticket.published && ticket.inStock && !ticket.deleted) {
            showTicketsLocal = true;
        }
    });
    return showTicketsLocal;
};

export const showGameTickets = (globalState: any, tickets: any[], timeOfGame: any, scheduled: boolean) => {
    if (!checkTicketsValidity(globalState)) {
        return false;
    }

    if (!isValidObject(tickets)) {
        return false;
    }

    if (tickets.length <= 0) {
        return false;
    }

    if (isValidObject(timeOfGame)) {
        if (increaseHoursInDate(getLocalTimeFromDatabaseTime(timeOfGame), 3) < getCurrentDateTime()) {
            return false;
        }
    }

    if (isValidObject(scheduled)) {
        if (!scheduled) {
            return false;
        }
    }

    let showTicketsLocal = false;
    tickets.forEach((ticket) => {
        if (ticket.published && ticket.inStock && !ticket.deleted) {
            showTicketsLocal = true;
        }
    });
    return showTicketsLocal;
};

export const showEventTickets = (globalState: any, tickets: any[], endTimeOfEvent: any) => {
    if (!checkTicketsValidity(globalState)) {
        return false;
    }

    if (!isValidObject(tickets)) {
        return false;
    }

    if (tickets.length <= 0) {
        return false;
    }

    if (increaseHoursInDate(getLocalTimeFromDatabaseTime(endTimeOfEvent), 24) < getCurrentDateTime()) {
        return false;
    }

    let showTicketsLocal = false;
    tickets.forEach((ticket) => {
        if (ticket.published && ticket.inStock && !ticket.deleted) {
            showTicketsLocal = true;
        }
    });
    return showTicketsLocal;
};

export const getStoreTitle = (globalState: any) => {
    if (isValidObject(globalState.storeInfo) && isValidString(globalState.storeInfo.title)) {
        return globalState.storeInfo.title;
    }
    return `${globalState.teamAlias} Store`;
};

export const getCart = () => {
    if (isValidObject(localStorage.getItem('cart'))) {
        return JSON.parse(localStorage.getItem('cart')!);
    }

    return [];
};

export const getCartQuantity = () => {
    if (isValidObject(localStorage.getItem('cart'))) {
        let quantity = 0;
        const cart = JSON.parse(localStorage.getItem('cart')!);
        cart.forEach((item: any) => {
            quantity += item.quantity;
        });
        return quantity;
    }

    return 0;
};

export const addProductToCart = (stripePrices: any[], setGlobalState: Function, snackbar: Function) => {
    const cart = getCart();

    // The 50 limit is added because the backend creates temp sold tickets documents and the sold ticket ids is saved
    // as metadata in payment intent. The metadata has a limit of 450. If this has no limit then the sold tickets
    // ids length could increase over 450
    if (cart.length === 30) {
        snackbar('Cannot add more than 30 cart items at any time', { variant: 'error' });
        return;
    }

    stripePrices.forEach((price: any) => {
        let inCart = false;
        cart.forEach((cartPrice: any, index: number) => {
            if (cartPrice.cartItemId === price.cartItemId) {
                inCart = true;
                cart.splice(index, 1, { ...cartPrice, quantity: cartPrice.quantity + price.quantity });
            }
        });
        if (!inCart) {
            cart.push(price);
        }
    });
    localStorage.setItem('cart', JSON.stringify(cart));
    setGlobalState({ type: 'setCart', cart });
    setGlobalState({ type: 'setCartQuantity', quantity: getCartQuantity() });
};

export const addTicketToCart = (stripePrices: any[], setGlobalState: Function, snackbar: Function) => {
    const cart = getCart();

    // The 30 limit is added because the backend creates temp sold tickets documents and the sold ticket ids is saved
    // as metadata in payment intent. The metadata has a limit of 450. If this has no limit then the sold tickets
    // ids length could increase over 450
    if (cart.length === 30) {
        snackbar('Cannot add more than 30 cart items at any time', { variant: 'error' });
        return;
    }

    stripePrices.forEach((price: any) => {
        let inCart = false;
        cart.forEach((cartPrice: any, index: number) => {
            if (cartPrice.cartItemId === price.cartItemId) {
                inCart = true;
                cart.splice(index, 1, { ...cartPrice, quantity: cartPrice.quantity + price.quantity });
            }
        });
        if (!inCart) {
            cart.push(price);
        }
    });
    localStorage.setItem('cart', JSON.stringify(cart));
    setGlobalState({ type: 'setCart', cart });
    setGlobalState({ type: 'setCartQuantity', quantity: getCartQuantity() });
};

export const changeCartQuantity = (cartItemId: string, quantity: number, setGlobalState: Function) => {
    const cart = getCart();

    cart.forEach((cartPrice: any, index: number) => {
        if (cartPrice.cartItemId === cartItemId) {
            if (quantity > 0) {
                cart.splice(index, 1, { ...cartPrice, quantity });
            }
        }
    });
    localStorage.setItem('cart', JSON.stringify(cart));
    setGlobalState({ type: 'setCart', cart });
    setGlobalState({ type: 'setCartQuantity', quantity: getCartQuantity() });
};

export const removeTicketFromCartUsingTicketId = (ticketId: string, setGlobalState: Function) => {
    const cart = getCart();
    const cartIndexes: number[] = [];

    cart.forEach((cartPrice: any, index: number) => {
        if (isValidString(cartPrice.ticketId) && cartPrice.ticketId === ticketId) {
            cartIndexes.unshift(index);
        }
    });
    cartIndexes.forEach((cartIndex: number) => {
        cart.splice(cartIndex, 1);
    });
    if (cartIndexes.length > 0) {
        localStorage.setItem('cart', JSON.stringify(cart));
        setGlobalState({ type: 'setCart', cart });
        setGlobalState({ type: 'setCartQuantity', quantity: getCartQuantity() });
    }
};

export const removeTicketFromCartUsingCartItemId = (cartItemId: string, setGlobalState: Function) => {
    const cart = getCart();

    cart.forEach((cartPrice: any, index: number) => {
        if (cartPrice.cartItemId === cartItemId) {
            cart.splice(index, 1);
        }
    });

    localStorage.setItem('cart', JSON.stringify(cart));
    setGlobalState({ type: 'setCart', cart });
    setGlobalState({ type: 'setCartQuantity', quantity: getCartQuantity() });
};

export const removeProductFromCartUsingProductId = (productId: string, setGlobalState: Function) => {
    const cart = getCart();
    const cartIndexes: number[] = [];

    cart.forEach((cartPrice: any, index: number) => {
        if (isValidString(cartPrice.productId) && cartPrice.productId === productId) {
            cartIndexes.unshift(index);
        }
    });
    cartIndexes.forEach((cartIndex: number) => {
        cart.splice(cartIndex, 1);
    });
    if (cartIndexes.length > 0) {
        localStorage.setItem('cart', JSON.stringify(cart));
        setGlobalState({ type: 'setCart', cart });
        setGlobalState({ type: 'setCartQuantity', quantity: getCartQuantity() });
    }
};

export const removeProductFromCartUsingCartItemId = (cartItemId: string, setGlobalState: Function) => {
    const cart = getCart();

    cart.forEach((cartPrice: any, index: number) => {
        if (cartPrice.cartItemId === cartItemId) {
            cart.splice(index, 1);
        }
    });

    localStorage.setItem('cart', JSON.stringify(cart));
    setGlobalState({ type: 'setCart', cart });
    setGlobalState({ type: 'setCartQuantity', quantity: getCartQuantity() });
};

export const emptyCart = (setGlobalState: Function) => {
    localStorage.setItem('cart', JSON.stringify([]));
    setGlobalState({ type: 'setCart', cart: [] });
    setGlobalState({ type: 'setCartQuantity', quantity: 0 });
};

export const getSuccessURL = (globalState: any) => {
    if (globalState !== null && globalState.accountObject !== null && globalState.accountObject.url !== null && globalState.accountObject.url.length !== 0) {
        if (globalState.accountObject.url.includes('?team=')) {
            const urlSegments = globalState.accountObject.url.split('/');
            let finalURL = '';
            urlSegments.forEach((url: string, index: number) => {
                if (index !== 3) {
                    finalURL += `${url}/`;
                }
            });
            finalURL += `storesuccess/${urlSegments[3]}`;
            return finalURL;
        }
        return `${globalState.accountObject.url}/storesuccess`;
    }
    return '';
};
