export const showPlatformFees = false;
export const showShippingFees = false;

export const showPriceFromCentsWithCurrency = (cents: number, currency: string): string => {
    return `${showPriceFromCents(cents)} ${currency.toUpperCase()}`;
};

export const showFeesFromCentsWithCurrency = (cents: number, currency: string): string => {
    return `${showPriceFromCents(feesFromCents(cents))} ${currency.toUpperCase()}`;
};

export const showProfitFromCentsWithCurrency = (cents: number, currency: string): string => {
    return `${showPriceFromCents(profitFromCents(cents))} ${currency.toUpperCase()}`;
};

export const showPriceFromCents = (cents: number): string => {
    return `$${centsToDollars(cents).toFixed(2)}`;
};

export const showFeesFromCents = (cents: number): string => {
    return showPriceFromCents(feesFromCents(cents));
};

export const showProfitFromCents = (cents: number): string => {
    return showPriceFromCents(profitFromCents(cents));
};

export const feesFromCents = (cents: number): number => {
    if (cents === 0) {
        return 0;
    }
    if (cents < 25000) {
        return (Math.ceil(cents * 0.1) + 50);
    }
    if (cents < 55000) {
        return (Math.ceil(cents * 0.075) + 50);
    }
    return (Math.ceil(cents * 0.04) + 50);
};

export const profitFromCents = (cents: number): number => {
    return (cents - feesFromCents(cents));
};

export const centsToDollars = (cents: number): number => {
    return parseFloat((cents / 100).toFixed(2));
};

export const dollarsToCents = (dollars: number): number => {
    return parseInt((dollars * 100).toFixed(0), 10);
};

export const getPriceDescriptionForTickets = (prices: any[]): string => {
    if (prices.length > 0) {
        let lowestPrice = Math.min();
        let highestPrice = Math.max();
        prices.forEach((price) => {
            lowestPrice = Math.min(price.price, lowestPrice);
            highestPrice = Math.max(price.price, highestPrice);
        });
        if (lowestPrice === highestPrice) {
            return `${showPriceFromCents(lowestPrice)}`;
        }
        return `${showPriceFromCents(lowestPrice)} - ${showPriceFromCents(highestPrice)}`;
    }
    return '';
};
