import { getSportsTypePronoun } from './enums/SportsType';
import { isValidBoolean, isValidObject, isValidString } from './Validity';
import DemoPlayer from '../assets/demoIceHockeyPlayer.png';

export const getOptimXBackgroundStyle = (globalState: any) => {
    if (!isValidObject(globalState) || !isValidObject(globalState.websiteStyles)) {
        return {
            backgroundColor: 'black',
            color: 'white',
        };
    }
    return {
        backgroundColor: globalState.websiteStyles.colors.primaryColor,
        color: isValidString(globalState.websiteStyles.colors.fontColor) ? globalState.websiteStyles.colors.fontColor : 'white',
    };
};

export const getOptimXBackgroundOpacityStyle = (globalState: any) => {
    if (!isValidObject(globalState) || !isValidObject(globalState.websiteStyles)) {
        return {
            backgroundColor: 'black',
            color: 'white',
        };
    }
    return {
        backgroundColor: `${globalState.websiteStyles.colors.primaryColor}11`,
    };
};

export const getOptimXForegroundStyle = (globalState: any) => {
    if (!isValidObject(globalState) || !isValidObject(globalState.websiteStyles)) {
        return {
            color: 'black',
            backgroundColor: 'transparent',
        };
    }
    return {
        color: globalState.websiteStyles.colors.primaryColor,
        backgroundColor: 'transparent',
    };
};

export const getInitials = (name: string) => {
    const initials = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g)?.slice(0, -1) ?? [];

    return `${initials.join(' ')} ${name.split(' ').slice(-1)[0]}` ?? '';
};

export const getAbbreviation = (text: string) => {
    if (text.split(' ').length > 2) {
        return text.match(/\b(\w)/g)?.join('');
    }
    return text;
};

export const getWebsiteImage = (globalState: any, imageId: string) => {
    if (!isValidObject(globalState) || !isValidObject(globalState.websiteImages)) {
        return null;
    }
    let image1 = null;
    globalState.websiteImages.forEach((image: any) => {
        if (image.id === imageId) {
            if (isValidString(image.image)) {
                image1 = image.image;
            } else {
                image1 = null;
            }
        }
    });

    if (!isValidObject(image1)) {
        globalState.websiteImages.forEach((image: any) => {
            if (image.id === 'featureImage1') {
                if (isValidString(image.image)) {
                    image1 = image.image;
                } else {
                    image1 = null;
                }
            }
        });
    }
    return image1;
};

export const getStoreImage = (globalState: any, includeFeatureImage: boolean) => {
    if (!isValidObject(globalState) || !isValidObject(globalState.websiteImages)) {
        return null;
    }
    let image1 = null;

    globalState.websiteImages.forEach((image: any) => {
        if (image.id === 'storeFeatureImage1') {
            if (isValidString(image.image)) {
                image1 = image.image;
            } else {
                image1 = null;
            }
        }
    });
    if (isValidObject(image1)) {
        return image1;
    }

    if (includeFeatureImage) {
        return getWebsiteImage(globalState, 'featureImage1');
    }

    return null;
};

export const getStoreMobileImage = (globalState: any, includeFeatureImage: boolean) => {
    if (!isValidObject(globalState) || !isValidObject(globalState.websiteImages)) {
        return null;
    }
    let image1 = null;

    globalState.websiteImages.forEach((image: any) => {
        if (image.id === 'mStoreFeatureImage1') {
            if (isValidString(image.image)) {
                image1 = image.image;
            } else {
                image1 = null;
            }
        }
    });
    if (isValidObject(image1)) {
        return image1;
    }

    if (includeFeatureImage) {
        return getWebsiteImage(globalState, 'mFeatureImage1');
    }

    return null;
};

export const getWebsiteImageLink = (image: any) => {
    if (isValidObject(image)) {
        if (isValidString(image.link)) {
            return image.link;
        }
    }
    return '';
};

export const getWebsiteImageLinkFromImageId = (globalState: any, imageId: string) => {
    if (!isValidObject(globalState) || !isValidObject(globalState.websiteImages)) {
        return null;
    }
    let image1 = null;
    globalState.websiteImages.forEach((image: any) => {
        if (image.id === imageId) {
            if (isValidString(image.image)) {
                image1 = image.link;
            } else {
                image1 = null;
            }
        }
    });

    if (!isValidObject(image1)) {
        return null;
    }
    return image1;
};

export const openWebsiteImageLink = (image: any) => {
    if (isValidObject(image)) {
        if (isValidString(image.link)) {
            window.open(image.link, '_blank');
        }
    }
};

export const openWebsiteImageLinkFromImageId = (globalState: any, imageId: string) => {
    if (isValidObject(globalState) && isValidObject(globalState.websiteImages)) {
        globalState.websiteImages.forEach((image: any) => {
            if (image.id === imageId) {
                if (isValidString(image.link)) {
                    window.open(image.link, '_blank');
                }
            }
        });
    }
};

export const getProfileImage = (globalState: any, profileImage: string) => {
    if (isValidString(profileImage)) {
        return profileImage;
    }

    if (isValidObject(globalState) && isValidObject(globalState.logo) && isValidObject(globalState.logo.logo2)) {
        return globalState.logo.logo2;
    }

    return DemoPlayer;
};

export const getProfileObjectFit = (globalState: any, profileImage: string) => {
    if (isValidString(profileImage)) {
        return 'cover';
    }

    if (isValidObject(globalState) && isValidObject(globalState.logo) && isValidObject(globalState.logo.logo2)) {
        return 'scale-down';
    }

    return 'cover';
};

export const getProductImage = (globalState: any, productImages: string[]) => {
    if (isValidString(productImages[0])) {
        return productImages[0];
    } if (isValidString(productImages[1])) {
        return productImages[1];
    } if (isValidString(productImages[2])) {
        return productImages[2];
    } if (isValidObject(globalState) && isValidObject(globalState.logo) && isValidObject(globalState.logo.logo2)) {
        return globalState.logo.logo2;
    }
    return '';
};

export const getProductObjectFit = (globalState: any, productImages: string[]) => {
    if (isValidString(productImages[0])) {
        return 'cover';
    } if (isValidString(productImages[1])) {
        return 'cover';
    } if (isValidString(productImages[2])) {
        return 'cover';
    } if (isValidObject(globalState) && isValidObject(globalState.logo) && isValidObject(globalState.logo.logo2)) {
        return 'scale-down';
    }
    return 'cover';
};

export const getHTMLTextFromNormalText = (text: string) => {
    let finalText = '';
    text.replace(/(?:\r\n|\r|\n)/g, ' <br/> ').split(' ').forEach((word) => {
        if (word.includes('http')) {
            finalText += `<a href=${word} target="_blank" rel="noreferrer">${word}</a> `;
        } else if (word.includes('www')) {
            finalText += `<a href=${word} target="_blank" rel="noreferrer">https://${word}</a> `;
        } else if (word.includes('@') && word.includes('.')) {
            finalText += `<a href=mailto:${word}>${word}</a> `;
        } else {
            finalText += `${word} `;
        }
    });
    return finalText;
};

export const getStatNumber = (stat: number, isPercentage: boolean = false): string => {
    const statNumber = Number(stat);
    if (statNumber < 0) return '-';
    if (statNumber === 0) return '-';
    if (statNumber === Number.POSITIVE_INFINITY) return '-';
    if (statNumber === Number.NEGATIVE_INFINITY) return '-';
    if (Number.isNaN(statNumber)) return '-';
    if (isPercentage) {
        return `${statNumber.toString()}%`;
    }
    return statNumber.toString();
};

export const getPlayerInfo = (details: any[], infoTitle: string): string => {
    let info = '';
    details.forEach((detail) => {
        if (detail.title === infoTitle) {
            info = detail.value;
        }
    });
    return info;
};

export const getPlayerSummary = (name: string, alumni: boolean, details: any[], globalState: any) => {
    const pronoun = getSportsTypePronoun(globalState.sportsType);
    const { teamAlias } = globalState;
    const position = getPlayerInfo(details, 'Position');
    const teamNumber = getPlayerInfo(details, 'Team Number');
    const previousTeam = getPlayerInfo(details, 'Previous Team');
    const hometown = getPlayerInfo(details, 'Hometown');
    const year = getPlayerInfo(details, 'Year');

    if (alumni) {
        let summary = `${name} was a ${position} for the ${teamAlias} team. `;

        if (isValidString(teamNumber)) {
            summary += `${name} represented the ${teamAlias} wearing jersey number ${teamNumber}. `;
        }

        if (isValidString(previousTeam)) {
            summary += `Before skating for the ${teamAlias} ${pronoun.toLowerCase()} previously played for ${previousTeam}. `;
        }

        return summary;
    }

    let summary = `${name} is a ${position} for the ${teamAlias} team. `;

    if (isValidString(hometown) && isValidString(year)) {
        summary += `${getSportsTypePronoun(globalState.sportsType)} is from ${hometown} and is part of the ${year} class. `;
    } else if (isValidString(hometown)) {
        summary += `${getSportsTypePronoun(globalState.sportsType)} is from ${hometown}. `;
    } else if (isValidString(year)) {
        summary += `${getSportsTypePronoun(globalState.sportsType)} is part of the ${year} class on the team. `;
    }

    if (isValidString(teamNumber)) {
        summary += `${name} will be representing the ${teamAlias} wearing jersey number ${teamNumber} this year. `;
    }

    if (isValidString(previousTeam)) {
        summary += `${getSportsTypePronoun(globalState.sportsType)} previously played for ${previousTeam}. `;
    }

    return summary;
};

export const getCoachSummary = (name: string, alumni: boolean, details: any[], globalState: any) => {
    const tense = alumni ? 'was' : 'is';
    const { teamAlias } = globalState;
    const hometown = getPlayerInfo(details, 'Hometown');
    const title = getPlayerInfo(details, 'Title');

    let summary = `${name} ${tense} the ${title} for the ${teamAlias}. `;

    if (isValidString(hometown)) {
        summary += `${name} is from ${hometown}. `;
    }

    return summary;
};

export const getNavLinkTargetValue = (openNewTab: boolean, internalLink: boolean): string => {
    if (isValidBoolean(openNewTab)) {
        if (openNewTab) {
            return '_blank';
        }
        return '_self';
    }
    if (internalLink) {
        return '_self';
    }
    return '_blank';
};
