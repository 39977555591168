import { db } from '../FirebaseLibrary';

export const createProductsReference = () => {
    return db.collection('products');
};

export const createProductReference = (productId: string) => {
    return createProductsReference().doc(productId);
};

export const fetchProductFirestoreAPI = (productId: string) => {
    return createProductReference(productId).get();
};

export const fetchProductsFirestoreAPI = (teamId: string) => {
    return createProductsReference()
        .where('teamId', '==', teamId)
        .where('published', '==', true)
        .where('archived', '==', false)
        .where('deleted', '==', false)
        .orderBy('order', 'desc')
        .orderBy('timeCreated', 'desc')
        .get();
};

export const fetchLinkedProductsFirestoreAPI = (teamId: string) => {
    return createProductsReference()
        .where('linkedStoreIds', 'array-contains', teamId)
        .where('published', '==', true)
        .where('archived', '==', false)
        .where('deleted', '==', false)
        .orderBy('order', 'desc')
        .orderBy('timeCreated', 'desc')
        .get();
};
