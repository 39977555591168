/* eslint-disable no-unused-vars */
export enum TagType {
    NONE,
    ORG,
    TEAM,
    PLAYER,
    COACH,
    OPPONENT,
    LEAGUE,
    PRE_DEVELOPED_LEAGUE,
    PRE_DEVELOPED_CONFERENCE
}

export const getTagTypeName = (tagType: TagType): string => {
    if (tagType === TagType.TEAM) {
        return 'Team';
    } if (tagType === TagType.ORG) {
        return 'Organization';
    } if (tagType === TagType.PLAYER) {
        return 'Player';
    } if (tagType === TagType.COACH) {
        return 'Coach';
    } if (tagType === TagType.OPPONENT) {
        return 'Opponent';
    } if (tagType === TagType.LEAGUE) {
        return 'League';
    } if (tagType === TagType.PRE_DEVELOPED_LEAGUE) {
        return 'Pre Developed League';
    } if (tagType === TagType.PRE_DEVELOPED_CONFERENCE) {
        return 'Pre Developed Conference';
    }
    return '';
};
